<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" v-if="!pageLoading" class="pr-1 pt-2 pb-15">
        <div style="margin-top: 0px">
          <h4 class="text-center white--text pb-3">
            {{
              $store.state.language == "English"
                ? `You have posted ${allPosts.length} ${
                    allPosts.length == 1 ? " Job" : " Jobs"
                  }`
                : `${allPosts.length} ${
                    allPosts.length == 1 ? " ስራ" : " ስራዎችን"
                  } ለጥፈዋል`
            }}
          </h4>
          <br />

          <v-layout
            row
            wrap
            class="white--text"
            v-for="(post, i) in allPosts"
            :key="i"
          >
            <v-flex xs8 class="pl-2 pr-1">
              <span
                ><strong>{{ post.title.replace("@@cont@@", ",") }}</strong>
              </span>
              <br />

              <span class="truncate-text grey--text">{{
                post.detail_description
              }}</span>

              <span style="font-size: 11px">
                <v-chip
                  @click="
                    showApplicantsDialog = true;
                    showApplicantsData = post;
                  "
                  :style="{
                    backgroundImage:
                      'linear-gradient(to right, #7632e0, #f97a68)',
                    color: 'white',
                    fontSize: '11px',
                    paddingBottom: '2px',
                    paddingTop: '1px',
                  }"
                  class="white--text"
                  x-small
                >
                  {{ post.getApplications.length }}
                  {{
                    $store.state.language == "English"
                      ? "Applied"
                      : `${
                          post.getApplications.length == 1 ? "አመልካች" : "አመልካቾች"
                        }`
                  }}
                </v-chip></span
              >
            </v-flex>

            <v-flex
              xs4
              class="pr-1 text-center"
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <p class="ma-0 pa-0 text-right">
                <v-menu transition="scale-transition" rounded="lg">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                      <v-icon class="white--text">more_vert</v-icon></v-btn
                    >
                  </template>
                  <v-card
                    style="background-color: #272a2f"
                    max-width="200px"
                    class="pt-2 pb-2 pr-5"
                  >
                    <v-btn
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() && post.isapproved == 0
                      "
                      @click="
                        unpaidPost = [];
                        unpaidPost.push(post);
                        unpaidDialog = true;
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="orange--text mr-1">attach_money</v-icon>
                      <span v-if="post.bank_slip == ''">
                        {{
                          $store.state.language == "English" ? "Payment" : "ክፍያ"
                        }}
                      </span>

                      <span v-else>
                        {{
                          $store.state.language == "English"
                            ? "Under Review"
                            : "በግምገማ ላይ"
                        }}
                      </span>
                    </v-btn>
                    <br
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() && post.isapproved == 0
                      "
                    />
                    <v-btn
                      @click="
                        detailDialog = true;
                        selectedDetail = post;
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="grey--text mr-1">visibility</v-icon
                      >{{
                        $store.state.language == "English" ? "More" : "ተጨማሪ"
                      }}
                    </v-btn>
                    <br />
                    <v-btn
                      @click="editPostClicked(post)"
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="blue--text mr-1">edit</v-icon
                      >{{
                        $store.state.language == "English" ? "Edit" : "አስተካክል"
                      }}
                    </v-btn>
                    <br />

                    <v-btn
                      v-if="post.message_id != ''"
                      @click="
                        showConfirm(
                          $store.state.language == 'English'
                            ? 'Are you sure you want to close this Job?'
                            : 'እርግጠኛ ነዎት ይህን ሥራ መዝጋት ይፈልጋሉ?'
                        );

                        postData = post;

                        indexPost = 1;

                        whichToCall = 'closeJob';
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="orange--text mr-1">block</v-icon>
                      {{
                        $store.state.language == "English" ? "Close" : "ልጥፉን ዝጋ"
                      }}
                    </v-btn>
                    <br v-if="post.message_id != ''" />

                    <v-btn
                      @click="
                        showConfirm(
                          $store.state.language == 'English'
                            ? 'Are you sure you want to delete this job vacancy?'
                            : 'እርግጠኛ ነዎት ይህንን የስራ ልጥፍ ማጥፋት ይፈልጋሉ?'
                        );

                        postData = post;

                        whichToCall = 'delete';
                      "
                      text
                      class="text-capitalize white--text ma-1"
                    >
                      <v-icon class="red--text mr-1">delete</v-icon
                      >{{
                        $store.state.language == "English" ? "delete" : "አጥፋ"
                      }}
                    </v-btn>
                  </v-card>
                </v-menu>
              </p>

              <p class="text-left ma-0 pa-0">
                <span
                  v-if="
                    new Date(post.deadline_date).getTime() <
                      Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                  "
                  class="pink--text"
                  >{{
                    $store.state.language == "English" ? "Expired" : "አብቅቷል"
                  }}</span
                >

                <span v-if="post.isrejected == 1" class="pink--text">{{
                  $store.state.language == "English" ? "Rejected" : "ውድቅ"
                }}</span>
                <span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 0
                  "
                  class="orange--text"
                  >{{
                    $store.state.language == "English" ? "Pending" : "በመጠባበቅ"
                  }}</span
                >

                <span
                  v-if="
                    new Date(post.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    post.isrejected == 0 &&
                    post.isapproved == 1
                  "
                  class="green--text"
                >
                  {{
                    $store.state.language == "English" ? "Active" : "ቀኑ ያላበቃ"
                  }}</span
                >
              </p>
            </v-flex>

            <v-flex xs12 class="mt-2 mb-2">
              <v-divider style="border-color: #272a2f"></v-divider>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="showApplicantsDialog"
          fullscreen
          persistent
          v-if="showApplicantsData != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English" ? "Job Detail" : "የሥራ ዝርዝር"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="showApplicantsDialog = false"
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-6">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex
                    xs12
                    v-for="(job, i) in showApplicantsData.title.split(
                      '@@cont@@'
                    )"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ showApplicantsData.company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ showApplicantsData.company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        showApplicantsData.sex.split('@@cont@@')[i] != '' &&
                        showApplicantsData.sex.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{ showApplicantsData.sex.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        showApplicantsData.sex.split('@@cont@@')[i] != '' &&
                        showApplicantsData.sex.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        showApplicantsData.vacancies.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{
                        showApplicantsData.vacancies.split("@@cont@@")[i]
                      }}</span
                    >

                    <br
                      v-if="
                        showApplicantsData.vacancies.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        showApplicantsData.salary.split('@@cont@@')[i] != '' &&
                        showApplicantsData.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{ showApplicantsData.salary.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        showApplicantsData.salary.split('@@cont@@')[i] != '' &&
                        showApplicantsData.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        showApplicantsData.experience.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{
                        showApplicantsData.experience.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        showApplicantsData.experience.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        showApplicantsData.education.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{
                        showApplicantsData.education.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        showApplicantsData.education.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        showApplicantsData.job_type.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{
                        showApplicantsData.job_type.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        showApplicantsData.job_type.split('@@cont@@')[i] !=
                          '' &&
                        showApplicantsData.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i ==
                        showApplicantsData.title.split('@@cont@@').length - 1
                      "
                    >
                      <span
                        v-if="
                          new Date(showApplicantsData.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadlineApplied }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadlineApplied }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >
                      <!-- 
                        <v-img
                          v-if="showApplicantsData.short_image != ''"
                          class="ml-0 mr-0 mt-2 mb-3"
                          width="100%"
                          height="150"
                          :src="showApplicantsData.short_image"
                          contain
                        ></v-img> -->

                      <span style="white-space: pre-line" class="truncate-text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ showApplicantsData.detail_description }}</span
                      >
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-card-text
              class="pa-0 ma-0 mt-3"
              style="color: white; background-color: #000000"
            >
              <img
                style="margin-top: -45px"
                width="100%"
                cover
                src="../assets/light2.png"
              />
            </v-card-text>
            <v-card-text style="color: white" class="pl-3 pr-3 pb-15">
              <h2 class="text-center" style="margin-top: -14px">
                <span v-if="selectedApplicants.length <= 1">
                  {{
                    $store.state.language == "English" ? "Applicants" : "አመልካቾች"
                  }}
                </span>

                <v-btn
                  @click="
                    showConfirm(
                      1 == 1
                        ? $store.state.language == 'English'
                          ? 'Are you sure you want to accept all the selected applicants?'
                          : 'እርግጠኛ ነዎት ሁሉንም የተመረጡ አመልካቾች መቀበል ይፈልጋሉ?'
                        : $store.state.language == 'English'
                        ? 'Are you sure you want to reject all the selected applicants?'
                        : 'እርግጠኛ ነዎት ሁሉንም የተመረጡ አመልካቾችን አይፈልጓቸውም?'
                    );

                    indexPost = 1;

                    whichToCall = 'acceptSelected';
                  "
                  v-if="selectedApplicants.length > 1"
                  text
                  small
                  outlined
                  style="border-color: blue"
                  class="green--text text-capitalize ml-3"
                >
                  <span>{{
                    $store.state.language == "English"
                      ? "Accept Selected"
                      : "የተመረጡትን ይመልምሉ"
                  }}</span>
                </v-btn>
                <v-btn
                  @click="
                    showConfirm(
                      2 == 1
                        ? $store.state.language == 'English'
                          ? 'Are you sure you want to accept all the selected applicants?'
                          : 'እርግጠኛ ነዎት ሁሉንም የተመረጡ አመልካቾች መቀበል ይፈልጋሉ?'
                        : $store.state.language == 'English'
                        ? 'Are you sure you want to reject all the selected applicants?'
                        : 'እርግጠኛ ነዎት ሁሉንም የተመረጡ አመልካቾችን አይፈልጓቸውም?'
                    );

                    indexPost = 2;

                    whichToCall = 'acceptSelected';
                  "
                  v-if="selectedApplicants.length > 1"
                  text
                  small
                  outlined
                  style="border-color: blue"
                  class="pink--text text-capitalize ml-3"
                >
                  <span>
                    {{
                      $store.state.language == "English"
                        ? "Reject Selected"
                        : "የተመረጡትን አይቀበሉ"
                    }}</span
                  >
                </v-btn>
              </h2>
              <h4
                class="text-center mt-4"
                v-if="showApplicantsData.getApplications.length == 0"
              >
                {{
                  $store.state.language == "English"
                    ? "There have been no applications for your job."
                    : "ለስራዎ ምንም ማመልከቻዎች የሉም"
                }}
              </h4>
              <v-layout
                row
                wrap
                class="white--text mt-3"
                v-for="(applicant, i) in showApplicantsData.getApplications"
                :key="i + 100"
              >
                <v-flex xs2 class="pl-2">
                  <a>
                    <v-avatar size="40px" id="userAvt">
                      <v-img
                        height="70"
                        width="70"
                        contain
                        v-if="applicant.getEmployees[0].user_pic != ''"
                        alt="Avatar"
                        :src="applicant.getEmployees[0].user_pic"
                      ></v-img>
                      <v-icon v-else class="white--text">person</v-icon>
                    </v-avatar>
                  </a>
                </v-flex>
                <v-flex xs9 class="pt-1">
                  <span
                    class="text-capitalize"
                    style="margin-left: -6px"
                    v-if="applicant.getEmployees[0].first_name != ''"
                    ><strong>{{
                      applicant.getEmployees[0].first_name +
                      " " +
                      applicant.getEmployees[0].middle_name
                    }}</strong>
                    &nbsp;<v-icon
                      v-if="applicant.getEmployees[0].isverified == 1"
                      small
                      class="blue--text"
                      >verified</v-icon
                    >
                  </span>
                  <br v-if="applicant.getEmployees[0].first_name != ''" />
                  <span
                    style="font-size: 11px; margin-top: -2px; margin-left: -6px"
                    v-if="applicant.getEmployees[0].study_field != ''"
                    class="studyText grey--text"
                    >{{ applicant.getEmployees[0].study_field }}</span
                  >

                  <span
                    style="font-size: 11px; margin-top: -2px; margin-left: -6px"
                    v-if="applicant.getEmployees[0].phone_number != ''"
                    class="studyText grey--text"
                    >{{ applicant.getEmployees[0].phone_number }}</span
                  >
                </v-flex>

                <v-flex xs1 class="pt-4 pr-2">
                  <input
                    @click="
                      if (applicant.isaccepted == 0)
                        selectApplicant(applicant, i);
                    "
                    type="checkbox"
                  />
                </v-flex>

                <v-flex xs12 class="mt-2 pl-2 pr-2">
                  <span class="letterText white--text">{{
                    applicant.cover_letter
                  }}</span>
                </v-flex>

                <v-flex xs12 class="mb-5"> </v-flex>

                <v-flex xs3 class="pl-2">
                  <v-btn
                    @click="
                      openCv = false;
                      seeDetailEmployeeDialog = true;
                      employeeDetailData = applicant;
                    "
                    text
                    x-small
                    class="orange--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English"
                        ? "See More"
                        : "የበለጠ ይመልከቱ"
                    }}</span>
                  </v-btn>
                </v-flex>
                <v-flex xs3 class="text-center">
                  <v-btn
                    @click="
                      contactEmployee(applicant.getEmployees[0].tg_username)
                    "
                    text
                    x-small
                    class="blue--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English"
                        ? "Send Message"
                        : "መልእክት ይላኩ"
                    }}</span>
                  </v-btn>
                </v-flex>
                <v-flex xs3 class="text-right">
                  <v-btn
                    v-if="applicant.isaccepted == 0"
                    @click="
                      showConfirm(
                        1 == 1
                          ? $store.state.language == 'English'
                            ? 'Are you sure you want to accept this applicant?'
                            : 'እርግጠኛ ነዎት ይህንን አመልካች መቀበል ይፈልጋሉ?'
                          : $store.state.language == 'English'
                          ? 'Are you sure you want to reject this applicant?'
                          : 'እርግጠኛ ነዎት ይህንን አመልካች አይፈልጉትም?'
                      );

                      postData = applicant;
                      actionStatus = 1;
                      indexPost = i;

                      whichToCall = 'acceptApplicant';
                    "
                    text
                    x-small
                    class="green--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English" ? "Accept" : "ይቀበሉ"
                    }}</span>
                  </v-btn>
                </v-flex>
                <v-flex xs3 class="pl-5 pr-2">
                  <v-btn
                    v-if="applicant.isaccepted == 0"
                    @click="
                      showConfirm(
                        2 == 1
                          ? $store.state.language == 'English'
                            ? 'Are you sure you want to accept this applicant?'
                            : 'እርግጠኛ ነዎት ይህንን አመልካች መቀበል ይፈልጋሉ?'
                          : $store.state.language == 'English'
                          ? 'Are you sure you want to reject this applicant?'
                          : 'እርግጠኛ ነዎት ይህንን አመልካች አይፈልጉትም?'
                      );

                      postData = applicant;
                      actionStatus = 2;
                      indexPost = i;

                      whichToCall = 'acceptApplicant';
                    "
                    text
                    x-small
                    class="pink--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English" ? "Reject" : "አይቀበሉ"
                    }}</span>
                  </v-btn>
                  <v-btn
                    v-if="applicant.isaccepted == 2"
                    text
                    x-small
                    class="pink--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English"
                        ? "Rejected"
                        : "አልተመረጠም"
                    }}</span>
                  </v-btn>

                  <v-btn
                    v-if="applicant.isaccepted == 1"
                    text
                    x-small
                    class="green--text text-capitalize"
                  >
                    <span>{{
                      $store.state.language == "English" ? "Accepted" : "ተመርጧል"
                    }}</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 class="mt-2 mb-4">
                  <v-divider style="border-color: #272a2f"></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="seeDetailEmployeeDialog"
          fullscreen
          persistent
          v-if="employeeDetailData != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English"
                  ? "Employee Detail"
                  : "የተቀጣሪ ዝርዝር"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  seeDetailEmployeeDialog = false;
                  openCv = false;
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-layout row wrap class="white--text pb-15">
                <v-flex xs12 class="text-center mt-4">
                  <a>
                    <v-avatar size="80px" id="userAvt">
                      <v-img
                        height="80"
                        width="80"
                        contain
                        v-if="employeeDetailData.getEmployees[0].user_pic != ''"
                        alt="Avatar"
                        :src="employeeDetailData.getEmployees[0].user_pic"
                      ></v-img>
                      <v-icon large v-else class="white--text">person</v-icon>
                    </v-avatar>
                  </a>
                  <br />
                  <v-icon
                    v-if="employeeDetailData.getEmployees[0].isverified == 1"
                    small
                    class="blue--text"
                    >verified</v-icon
                  >
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2">
                  <h3 class="mb-2 mt-3">
                    {{
                      $store.state.language == "English"
                        ? "Applicant's information"
                        : "የአመልካች መረጃ"
                    }}
                  </h3>

                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Full Name"
                          : "ሙሉ ስም"
                      }}: </strong
                    >{{
                      employeeDetailData.getEmployees[0].first_name +
                      " " +
                      employeeDetailData.getEmployees[0].middle_name +
                      " " +
                      employeeDetailData.getEmployees[0].last_name
                    }}</span
                  >
                  <br />
                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Phone Number"
                          : "ስልክ ቁጥር"
                      }}: </strong
                    >{{ employeeDetailData.getEmployees[0].phone_number }}</span
                  >
                  <br />

                  <span
                    ><strong
                      >{{
                        $store.state.language == "English" ? "Address" : "አድራሻ"
                      }}: </strong
                    >{{ employeeDetailData.getEmployees[0].address }}</span
                  >
                  <br />

                  <v-divider
                    class="mt-2"
                    style="border-color: grey"
                  ></v-divider>
                  <br />
                  <h3 class="mb-2">
                    {{
                      $store.state.language == "English"
                        ? "Education and Experience"
                        : "ትምህርት እና ልምድ"
                    }}
                  </h3>

                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Educational level"
                          : "የትምህርት ደረጃ"
                      }}: </strong
                    >{{ employeeDetailData.getEmployees[0].education }}</span
                  >
                  <br />
                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Field of Study | Skills"
                          : "የጥናት መስክ | ችሎታዎች"
                      }}: </strong
                    >{{ employeeDetailData.getEmployees[0].study_field }}</span
                  >
                  <br />
                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Profile Summary"
                          : "ፕሮፋይል መገለጫ"
                      }}: </strong
                    >{{ employeeDetailData.getEmployees[0].cover_letter }}</span
                  >
                  <br />
                  <br />
                  <span class="mt-3"
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Application Letter"
                          : "የማመልከቻ ደብዳቤ"
                      }}: </strong
                    >{{ employeeDetailData.cover_letter }}</span
                  >
                  <br />

                  <span class="mt-3" v-if="employeeDetailData.sector != ''"
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Selected Sector"
                          : "የተመረጠው ዘርፍ"
                      }}: </strong
                    >{{ employeeDetailData.sector }}</span
                  >
                  <br v-if="employeeDetailData.sector != ''" />

                  <v-divider
                    class="mt-2"
                    style="border-color: grey"
                  ></v-divider>
                  <br />
                  <h3 class="mb-2">
                    {{
                      $store.state.language == "English"
                        ? "Portfolio Links and CV"
                        : "ፖርትፎሊዮ ሊንክ እና ሲቪ"
                    }}
                  </h3>
                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "Portfolio"
                          : "ፖርትፎሊዮ"
                      }}: </strong
                    >{{
                      employeeDetailData.getEmployees[0].link != ""
                        ? employeeDetailData.getEmployees[0].link
                        : employeeDetailData.linkedin
                    }}</span
                  >
                  <br />
                  <span
                    ><strong
                      >{{
                        $store.state.language == "English"
                          ? "CV or Resum"
                          : "CV"
                      }}:
                    </strong>
                    <a
                      v-if="employeeDetailData.getEmployees[0].cv != ''"
                      @click="openCv = !openCv"
                      >{{
                        $store.state.language == "English"
                          ? "View CV"
                          : "ሲቪ ይመልከቱ"
                      }}</a
                    >
                    <span v-else>{{
                      $store.state.language == "English"
                        ? "Cv is not attached"
                        : "ሲቪ አልተያያዘም።"
                    }}</span>
                  </span>
                  <br />
                  <v-divider
                    class="mt-2"
                    style="border-color: grey"
                  ></v-divider>
                </v-flex>
                <v-flex
                  class="pb-15"
                  xs12
                  v-if="
                    employeeDetailData.getEmployees[0].cv != '' &&
                    openCv == true
                  "
                >
                  <!-- <h2 class="text-center mt-3" v-if="loadPdf">Loading...</h2> -->
                  <!-- <iframe
                      :src="employeeDetailData.getEmployees[0].cv"
                      width="100%"
                      height="600px"
                      style="border: none"
                      @load="onLoad"
                    ></iframe> -->

                  <div v-if="openCv == true">
                    <PDFViewer
                      class="pa-0 ma-0"
                      :source="employeeDetailData.getEmployees[0].cv"
                      style="height: 600px; width: 100%"
                      @load="handleDownload"
                    />
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="detailDialog"
          fullscreen
          persistent
          v-if="selectedDetail != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  detailDialog = false;
                  selectedDetail = '';
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex
                    xs12
                    v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ selectedDetail.company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ selectedDetail.company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{ selectedDetail.vacancies.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{ selectedDetail.experience.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{ selectedDetail.education.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i == selectedDetail.title.split('@@cont@@').length - 1
                      "
                    >
                      <span
                        v-if="
                          new Date(selectedDetail.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadline }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadline }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >

                      <v-img
                        v-if="selectedDetail.short_image != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.short_image"
                        contain
                      ></v-img>

                      <span style="white-space: pre-line"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ selectedDetail.detail_description }}</span
                      >

                      <br />
                      <br />
                      <span v-if="selectedDetail.bank_slip != ''">
                        <strong
                          >{{
                            $store.state.language == "English"
                              ? "Payment screenshot"
                              : "የደረሰኝ ፎቶ"
                          }}:
                        </strong>
                      </span>
                      <v-img
                        v-if="selectedDetail.bank_slip != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.bank_slip"
                        contain
                      ></v-img>
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="acceptRejectDialog"
          max-width="200px"
          persistent
        >
          <v-card style="background-color: white" class="white--text">
            <v-card-text>
              <div class="text-center pt-4" v-if="acceptRejectDialog">
                <v-progress-circular
                  :size="50"
                  :width="2"
                  color="#023679"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="editDialog"
          fullscreen
          persistent
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English"
                  ? "Edit post"
                  : "ልጥፍዎን ያስተካክሉ"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="editDialog = false"
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-0 mt-1 pr-1 pl-1">
                  <v-flex xs12 class="text-center white--text ma-1">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? " Please review and adjust as you like."
                          : "እባክዎን በጥንቃቄ ያንብቡ እና እንደፈለጉ ያስተካክሉ።"
                      }}
                    </h4>
                  </v-flex>
                  <v-flex xs12 md5 class="mt-2">
                    <!-- <span class="white--text ml-2">{{
                  $store.state.language == "English"
                    ? "Telegram Id"
                    : "የቴሌግራም ቁጥር"
                }}</span>

                <input
                  required
                  :rules="[(v) => !!v || 'title is required']"
                  v-model="tgid"
                  class="pa-2 pl-3 mb-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Telegram Id'
                      : 'የቴሌግራም ቁጥር'
                  "
                />
                <br /> -->
                    <span class="white--text"
                      >{{
                        $store.state.language == "English"
                          ? "Select a language to view your post on our Telegram channel in that language:"
                          : "ቋንቋ ይምረጡ፤ ልጥፍዎን በመረጡት ቋንቋ ቴሌግራም ቻናላችን ላይ ይታያል:"
                      }}
                      <br
                    /></span>
                    <div
                      class="pl-3 mt-2 mb-2 text-center"
                      style="
                        font-size: 18px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                        padding-top: 1px;
                      "
                    >
                      <v-radio-group
                        v-model="language"
                        row
                        class="white--text text-center"
                      >
                        <v-radio
                          @click="$store.dispatch('changeLanguage', 'English')"
                          base-color="white"
                          value="English"
                          color="white"
                          style="border-color: white; color: white"
                          class="custom-radio"
                        >
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "English"
                                : "English"
                            }}</span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="አማርኛ"
                          color="white"
                          @click="$store.dispatch('changeLanguage', 'አማርኛ')"
                        >
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "Amharic"
                                : "አማርኛ"
                            }}</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>

                    <input
                      required
                      :rules="[(v) => !!v || 'Company Name is required']"
                      v-model="companyName"
                      class="pa-2 pl-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Company Name*'
                          : 'የኩባንያ ስም*'
                      "
                    />
                    <br />

                    <input
                      :rules="[(v) => !!v || 'Location is required']"
                      required
                      v-model="address"
                      class="pa-2 pl-2 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Work Location*'
                          : 'የስራ ቦታ*'
                      "
                    />
                    <br />

                    <div v-for="index in postCounter" :key="index">
                      <input
                        required
                        :rules="[(v) => !!v || 'title is required']"
                        v-model="title[index - 1]"
                        class="pa-2 pl-3 mt-6"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Job Title*'
                            : 'የስራ መጠሪያ*'
                        "
                      />

                      <div
                        class="pl-3 mt-2"
                        style="
                          font-size: 18px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                          padding-top: 2px;
                        "
                      >
                        <v-radio-group
                          v-model="sex[index - 1]"
                          row
                          class="white--text"
                        >
                          <br />
                          <span
                            ><strong>{{
                              $store.state.language == "English" ? "Sex:" : "ጾታ"
                            }}</strong></span
                          >
                          &nbsp;&nbsp;&nbsp;
                          <v-radio
                            base-color="white"
                            value="Male"
                            color="white"
                            style="border-color: white; color: white"
                            class="custom-radio"
                          >
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Male"
                                  : "ወንድ"
                              }}</span>
                            </template>
                          </v-radio>
                          <v-radio value="Female" color="white">
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Female"
                                  : "ሴት"
                              }}</span>
                            </template>
                          </v-radio>

                          <v-radio value="Both" color="white">
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Both"
                                  : "ሁለቱም"
                              }}</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>

                      <input
                        required
                        v-model="vacancies[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'How many employees required (Opt)'
                            : 'ምን ያህል ሰራተኞች ያስፈልጋሉ'
                        "
                      />

                      <input
                        required
                        v-model="salary[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Salary (Optional)'
                            : 'ደሞዝ'
                        "
                      />

                      <input
                        required
                        v-model="experience[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Experience Level (Optional)'
                            : 'የስራ ልምድ'
                        "
                      />

                      <v-flex xs12>
                        <v-menu
                          max-width="100vw"
                          :close-on-content-click="true"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              v-bind="attrs"
                              v-on="on"
                              @focus="unfocus"
                              required
                              v-model="education[index - 1]"
                              class="pa-2 pl-3 mt-2"
                              style="
                                font-size: 16px;
                                border-radius: 5px;
                                color: white;
                                background-color: #272a2f;
                              "
                              type="text"
                              :placeholder="
                                $store.state.language == 'English'
                                  ? 'Education Qualification (Optional)'
                                  : 'የትምህርት ደረጃ'
                              "
                            />
                          </template>
                          <v-card
                            style="
                              width: 92vw;
                              max-height: 100vh;
                              overflow-y: auto;
                            "
                          >
                            <v-list :lines="false" density="compact">
                              <v-list-item
                                style="border-bottom: 1px solid grey"
                                v-for="item in educationLists"
                                :key="item"
                                :value="item"
                                color="primary"
                                @click="
                                  education[index - 1] = item;
                                  education.splice(index - 1, 1, item);
                                "
                              >
                                <v-list-item-title
                                  class="pl-5"
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-flex>

                      <input
                        v-if="
                          education[index - 1] != undefined &&
                          education[index - 1] != '' &&
                          education[index - 1] != 'Not Required'
                        "
                        required
                        v-model="fieldStudy[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Field of Study or Skill (Optional)'
                            : 'የጥናት መስክ ወይም ችሎታ'
                        "
                      />
                      <v-flex xs12>
                        <v-menu
                          max-width="100vw"
                          :close-on-content-click="true"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              v-bind="attrs"
                              v-on="on"
                              @focus="unfocus"
                              required
                              v-model="jobType[index - 1]"
                              class="pa-2 pl-3 mt-2"
                              style="
                                font-size: 16px;
                                border-radius: 5px;
                                color: white;
                                background-color: #272a2f;
                              "
                              type="text"
                              :placeholder="
                                $store.state.language == 'English'
                                  ? 'Job Type (Optional)'
                                  : 'የቅጥር አይነት'
                              "
                            />
                          </template>
                          <v-card
                            style="
                              width: 92vw;
                              max-height: 100vh;
                              overflow-y: auto;
                            "
                          >
                            <v-list density="compact">
                              <v-list-item
                                style="border-bottom: 1px solid grey"
                                @click="jobType[index - 1] = item"
                                v-for="(item, k) in jobTypLists"
                                :key="k"
                                :value="item"
                                color="primary"
                              >
                                <v-list-item-title
                                  class="pl-5"
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-flex>
                    </div>

                    <div class="text-right pt-3">
                      <v-btn
                        right
                        @click="postCounter = postCounter + 1"
                        text
                        small
                        class="text-capitalize blue--text"
                      >
                        {{
                          $store.state.language == "English"
                            ? "Add More"
                            : "ስራ ይጨምሩ"
                        }}
                        <v-icon class="blue--text ml-2 mr-2">add_circle</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="postCounter > 1"
                        @click="
                          education.splice(postCounter - 1, 1);
                          title.splice(postCounter - 1, 1);
                          sex.splice(postCounter - 1, 1);
                          vacancies.splice(postCounter - 1, 1);
                          experience.splice(postCounter - 1, 1);
                          fieldStudy.splice(postCounter - 1, 1);
                          jobType.splice(postCounter - 1, 1);
                          salary.splice(postCounter - 1, 1);
                          postCounter = postCounter - 1;
                        "
                        text
                        small
                        class="text-capitalize ml-1 blue--text text-right"
                      >
                        <v-icon class="pink--text ml-2">cancel</v-icon>
                      </v-btn>
                    </div>

                    <textarea
                      :rules="[(v) => !!v || 'Description is required']"
                      required
                      class="pa-2 pl-3 mt-6"
                      v-model="detailDescription"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Detail Description*'
                          : 'ዝርዝር መግለጫ*'
                      "
                      rows="5"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        background-color: #272a2f;
                      "
                    ></textarea>

                    <v-card
                      style="background-color: #272a2f"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage"
                        style="display: none"
                        @change="OnInputFile"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage"
                        style="display: none"
                        @change="OnInputFile"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image1"
                        height="142"
                        contain
                        v-if="image1 != ''"
                        @click="changeImage()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image1 == ''"
                        @click="simulateInputfileClicked"
                      >
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Company logo (Optional)"
                              : "የኩባንያ አርማ (መተው ይቻላል)"
                          }}
                        </p>
                        <br />
                        <br />
                      </div>
                    </v-card>

                    <!-- <v-card
                      style="background-color: #272a2f"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center mt-2"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image12"
                        height="142"
                        contain
                        v-if="image12 != ''"
                        @click="changeImage2()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image12 == ''"
                        @click="simulateInputfileClicked2"
                      >
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Company logo two*"
                              : "የኩባንያ አርማ ሁለት*"
                          }}
                        </p>
                        <br />
                        <br />
                      </div>
                    </v-card> -->
                  </v-flex>
                  <!-- <v-flex xs12 class="">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-3 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isZerf"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Does it has a sector?"
                        : "ዘርፍ አለው?"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs6>
                <v-switch
                  hide-details
                  class="white--text pl-3 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isDetail"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Read details"
                        : "ዝርዝሩን ያንብቡ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->
                  <!-- 
              <v-flex xs6 class="pl-2">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isTheSame"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Same Doc"
                        : "ተመሳሳይ ሰነድ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->

                  <v-flex xs6 class="mt-3">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isRegistration"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Registration Mandatory"
                            : "መመዝገብ ግዴታ ነው"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex xs6 class="pl-2 mt-3">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="islinkedin"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Portfolio Required"
                            : "ፖርትፎሊዮ ያስፈልጋል"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isCv"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "CV Mandatory"
                            : "CV ግዴታ ነው"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex xs6 class="pl-2">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isDirect"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Direct Contact"
                            : "በቀጥታ ያነጋግሩ"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs12 v-if="isDirect == 1">
                    <input
                      required
                      v-model="phone"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Enter Phone Number or'
                          : 'ስልክ ቁጥር ያስገቡ ወይም'
                      "
                    />
                    <br />
                    <input
                      required
                      v-model="tegcontact"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Tg Username @xyz, Web, Whats up, Facebook or'
                          : 'ቴሌግራም ስም  @xyz፣ ድህረገጽ፣ ዋትስአፕ፣ ፌስቡክ ወይም'
                      "
                    />
                    <br />

                    <input
                      required
                      v-model="email"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Enter Email'
                          : 'ኢሜይል ያስገቡ'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs6>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <input
                          @focus="unfocus"
                          :rules="[(v) => !!v || 'Deadline is required']"
                          v-bind="attrs"
                          v-on="on"
                          required
                          v-model="deadline"
                          class="pa-2 pl-3 mt-4"
                          style="
                            font-size: 16px;
                            border-radius: 5px;
                            color: white;
                            background-color: #272a2f;
                          "
                          type="text"
                          :placeholder="
                            $store.state.language == 'English'
                              ? 'Deadline Date*'
                              : 'የማብቂያ ቀን*'
                          "
                        />
                      </template>
                      <v-date-picker
                        :min="minDate"
                        v-model="deadline"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs6 class="pl-2">
                    <input
                      required
                      v-model="age"
                      class="pa-2 pl-3 mt-4"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Age Range (Opt)'
                          : 'የዕድሜ ክልል'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs12 class="text-center">
                    <v-btn
                      :loading="submitLoading"
                      @click="submitPost"
                      class="pl-15 pr-15 mt-10 white--text"
                      text
                      style="
                        background-image: linear-gradient(#023679, #023679);
                        border-radius: 10px;
                        font-size: 18px;
                      "
                    >
                      <span class="text-capitalize">{{
                        $store.state.language == "English"
                          ? "Save Changes"
                          : "ለውጦችን ያስቀምጡ"
                      }}</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="unpaidDialog"
          fullscreen
          persistent
          v-if="unpaidPost.length > 0"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English"
                  ? "Unpaid job post"
                  : "ያልተከፈለ የስራ ልጥፍ"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  unpaidDialog = false;
                  unpaidPost = [];
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-6 white--text">
              <v-layout row wrap>
                <v-flex xs12 class="pl-1 mb-3 mt-6 mb-3">
                  <p>
                    {{
                      $store.state.language == "English"
                        ? "You have an unpaid job vacancy. Please make the payment, and we will post your job vacancy on our Telegram channel."
                        : "ያልተከፈለ የስራ ልጥፍ አሎት፤ እባክዎ ክፍያውን ይክፈሉ እና ክፍት የስራ ቦታዎን በቴሌግራም ቻናላችን ላይ እንለጥፍሎታለን።"
                    }}
                  </p>
                </v-flex>
                <v-flex xs12>
                  <p>
                    <strong
                      >{{
                        $store.state.language == "English"
                          ? "Job Title"
                          : "የስራው መጠርያ"
                      }}:</strong
                    >
                    {{ unpaidPost[0].title.replace("@@cont@@", ",") }}
                  </p>
                </v-flex>
                <v-flex
                  xs12
                  class="ma-2 pa-4 pb-6 mb-6 mt-1 mr-3 ml-1 pl-7 grey--text"
                  style="
                    border: 1px solid;
                    border-color: #383838;
                    border-radius: 5px;
                    font-size: 14px;
                  "
                >
                  <p class="text-center pr-7 pb-1">
                    <Strong>
                      {{
                        $store.state.language == "English"
                          ? "Payment Methods"
                          : "የመክፈያ ዘዴዎች"
                      }}</Strong
                    >
                  </p>
                  <v-layout
                    row
                    wrap
                    v-for="(account, i) in allAcounts"
                    :key="account.account"
                    class="mt-5"
                  >
                    <v-flex xs2 class="text-right pr-3">
                      <h4>
                        <img
                          v-if="i != 2"
                          contain
                          height="25px"
                          width="25px"
                          :src="require(`../assets/${account.logo}`)"
                          alt="logo"
                        />
                        <img
                          v-else
                          contain
                          height="20px"
                          width="20px"
                          :src="require(`../assets/${account.logo}`)"
                          alt="logo"
                        />
                      </h4>
                    </v-flex>
                    <v-flex xs10>
                      <h4 v-if="i == 2">
                        <span> {{ getAccount(account.account) }} <br /></span>
                      </h4>

                      <h4 v-else class="pt-1">
                        <span> {{ getAccount(account.account) }} <br /></span>
                      </h4>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex
                  xs12
                  class="ma-2 pa-4 pt-0 pl-1 mb-1 mr-1 ml-1 grey--text"
                >
                  <h3 class="mb-2">
                    {{
                      $store.state.language == "English"
                        ? "Steps"
                        : "እንዴት ልክፈል?"
                    }}
                  </h3>
                  <span>
                    1.
                    {{
                      $store.state.language == "English"
                        ? "Select a payment method from the options above"
                        : "ከላይ ካሉት አማራጮች የመክፈያ ዘዴን ይምረጡ"
                    }}
                    <br />
                    2.
                    {{
                      $store.state.language == "English"
                        ? "Pay 200 birr using your preferred payment option"
                        : "የመረጡትን የክፍያ አማራጭ በመጠቀም 200 ብር ይክፈሉ"
                    }}
                    <br />
                    3.
                    {{
                      $store.state.language == "English"
                        ? "Submit a screenshot using the form provided below."
                        : "ከዚህ በታች የቀረበውን ቅጽ በመጠቀም የከፈሉበትን ደረሰኝ ያስገቡ።"
                    }}
                  </span>
                </v-flex>

                <v-flex xs12 class="text-center pb-15 pl-4 pr-5">
                  <v-card
                    style="background-color: #272a2f"
                    elevation="0"
                    light
                    @dragend="OnDragEnter"
                    @dragleave="OnDragLeave"
                    @dragover.prevent
                    @drop="OnDrop"
                    class="text-center"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref="selectpayImage"
                      style="display: none"
                      @change="payOnInputFile"
                    />

                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-0 mr-0"
                      :src="image1pay"
                      height="142"
                      contain
                      v-if="image1pay != ''"
                      @click="changepayImage()"
                    ></v-img>

                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image1pay == ''"
                      @click="simulatepayInputfileClicked"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 white--text"
                        >camera_alt</v-icon
                      >
                      <br />

                      <p class="text-center white--text">
                        {{
                          $store.state.language == "English"
                            ? "Upload your payment invoice screenshot"
                            : "ክፍያ የፈጸሙበትን ደረሰኝ ፎቶ ያስገቡ"
                        }}
                      </p>
                      <br />
                      <br />
                      <!-- </v-btn> -->
                    </div>
                  </v-card>
                  <span class="text-white" v-if="pdfUploadStatus != ''">{{
                    pdfUploadStatus
                  }}</span>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
        <AlertDialog
          :message="alertMessage"
          :show="showAlertDialog"
          @close="showAlertDialog = false"
        />
        <ConfirmDialog
          :message="confirmMessage"
          :show="showConfirmDialog"
          @confirm="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AlertDialog from "./AlertDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import profileHeader from "./profileHeader.vue";
// import { getDocument } from "pdfjs-dist/build/pdf";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";

export default {
  components: {
    profileHeader,
    PDFViewer,

    AlertDialog,
    ConfirmDialog,
  },
  data: () => ({
    pageLoading: false,
    showApplicantsDialog: false,
    allPosts: [],
    showApplicantsData: "",
    acceptRejectDialog: false,
    loadingCount: 0,
    selectedApplicants: [],
    seeDetailEmployeeDialog: false,
    employeeDetailData: "",
    openCv: false,
    loadPdf: false,
    detailDialog: false,
    selectedDetail: "",
    editDialog: false,
    editablePost: "",
    postCounter: 0,

    showAlertDialog: false,
    alertMessage: "",
    showConfirmDialog: false,
    confirmMessage: "",
    confirmResult: null,
    postData: "",
    indexPost: -1,
    whichToCall: "",
    actionStatus: 0,

    isDetail: 1,
    isDirect: 0,
    isRegistration: 0,
    isCv: 0,
    islinkedin: 0,
    isZerf: 0,
    isTheSame: 1,

    age: "",
    tgid: "",
    companyName: "",
    title: [],
    vacancies: [],
    sex: [],
    salary: [],
    experience: [],
    education: [],
    jobType: [],
    fieldStudy: [],
    tegcontact: "",
    phone: "",
    email: "",

    shortDescription: "",
    detailDescription: "",
    address: "",
    applyLink: "",
    language: "",

    image1: "",
    imageUrl1: "",

    image12: "",
    imageUrl12: "",
    files: [],
    files2: [],

    domainUrl: "https://kinu.abimabusiness.com",

    jobTypLists: [
      "Full-Time",
      "Part-Time",
      "Remote",
      "Contract",
      "Temporary",
      "Freelancer",
      "Internship",
      "Volunteer",
    ],

    educationLists: [
      "8+ (Above 8th-grade)",
      "10+ (Above 10th-grade)",
      "12+ (Completed 12th-grade)",
      "TVET",
      "Diploma",
      "Bachelor's Degree",
      "Master's Degree",
      "Not Required",
    ],
    minDate: new Date().toISOString().substr(0, 10),
    dateDialog: false,
    deadline: "",
    menu: false,
    submitLoading: false,
    unpaidPost: [],
    unpaidDialog: false,
    allAcounts: [
      {
        account: "4342453a2031303030313834303130323532",
        logo: "cbe.png",
      },
      {
        account: "42616e6b206f66204162797373696e69613a20313136393731323637",
        logo: "boa.png",
      },
      // {
      //   account: "41776173682042616e6b3a203635333732373839",
      //   logo: "awash.png",
      // },
      {
        account: "54656c65626972723a2030393132343733393237",
        logo: "telebirr.png",
      },
    ],
    image1pay: "",
    filespay: [],
    pdfUploadStatus: "",
  }),

  // mounted() {
  //   if (this.employeeDetailData != "")
  //     if (this.employeeDetailData.getEmployees[0].cv != "")
  //       this.loadPDF(this.employeeDetailData.getEmployees[0].cv);
  // },

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.deadline_date);
    },

    getDeadlineApplied() {
      return this.formatDate(this.showApplicantsData.deadline_date);
    },
  },

  methods: {
    showAlert(message) {
      this.alertMessage = message;
      this.showAlertDialog = true;
    },
    showConfirm(message) {
      this.confirmMessage = message;
      this.showConfirmDialog = true;
    },
    handleConfirm(answer) {
      this.confirmResult = answer;

      this.showConfirmDialog = false;
      if (answer == true) {
        if (this.whichToCall == "acceptSelected") {
          this.acceptSelecetedAppts(this.indexPost);
        } else if (this.whichToCall == "acceptApplicant") {
          this.acceptApplicant(
            this.postData,
            this.actionStatus,
            this.indexPost
          );
        } else if (this.whichToCall == "delete") {
          this.deletePost(this.postData);
        } else if (this.whichToCall == "closeJob") {
          this.closeJob(this.postData, this.indexPost);
        }
      } else {
        this.postData = "";
        this.indexPost = -1;
      }
    },
    getAccount(hex) {
      let str = "";
      for (let i = 0; i < hex.length; i += 2) {
        const hexValue = hex.substr(i, 2);

        const charCode = parseInt(hexValue, 16);

        str += String.fromCharCode(charCode);
      }
      return str;
    },
    editPostClicked(post) {
      this.editDialog = true;
      this.editablePost = post;
      this.postCounter = this.editablePost.title.split("@@cont@@").length;
      this.language = this.editablePost.language;
      this.$store.dispatch("changeLanguage", this.language);

      this.companyName = this.editablePost.company_name;
      this.address = this.editablePost.company_address;
      this.shortDescription = this.editablePost.short_description;
      this.detailDescription = this.editablePost.detail_description;
      this.image1 = this.editablePost.short_image;
      this.image12 = this.editablePost.detail_image;
      this.deadline = this.editablePost.deadline_date;

      this.isDetail = this.editablePost.isdetail;
      this.isDirect = this.editablePost.isdirect;
      this.isRegistration = this.editablePost.isregistered;
      this.isCv = this.editablePost.iscv;
      this.islinkedin = this.editablePost.islinkedin;
      this.isZerf = this.editablePost.iszerf;
      this.isTheSame = this.editablePost.isthesame;

      this.age = this.editablePost.age_above_bellow;

      if (this.editablePost.apply_link != "") {
        let applyLinkData = this.editablePost.apply_link.split(".cont.");

        if (applyLinkData[0] != "") this.phone = applyLinkData[0];
        if (applyLinkData[1] != "") this.tegcontact = applyLinkData[1];
        if (applyLinkData[2] != "") this.email = applyLinkData[2];
      }

      this.title = this.editablePost.title.split("@@cont@@");
      this.sex = this.editablePost.sex.split("@@cont@@");
      this.vacancies = this.editablePost.vacancies.split("@@cont@@");
      this.salary = this.editablePost.salary.split("@@cont@@");
      this.experience = this.editablePost.experience.split("@@cont@@");
      this.jobType = this.editablePost.job_type.split("@@cont@@");
      let educationSplit = this.editablePost.education.split("@@cont@@");
      this.education = [];
      this.fieldStudy = [];
      for (let i = 0; i < educationSplit.length; i++) {
        this.education.push(educationSplit[i].split(", in ")[0]);
        if (
          educationSplit[i].split(", in ")[1] != "" &&
          educationSplit[i].split(", in ")[1] != undefined
        )
          this.fieldStudy.push(educationSplit[i].split(", in ")[1]);
      }
    },

    async submitPost() {
      this.imageUrl1 = "";
      this.imageUrl12 = "";

      let isTitleEmpty = false;

      let tempjobTitle = "";
      let tempSex = "";
      let tempVacancies = "";
      let tempExperience = "";
      let tempEducation = "";

      let tempJobType = "";
      let tempSalary = "";

      for (let i = 0; i < this.postCounter; i++) {
        if (this.title[i] == "" || this.title[i] == undefined) {
          isTitleEmpty = true;
        }
        if (tempjobTitle == "") {
          tempjobTitle = this.title[i];
        } else {
          tempjobTitle = tempjobTitle + "@@cont@@" + this.title[i];
        }

        if (tempSex == "") {
          tempSex = this.sex[i];
        } else {
          tempSex = tempSex + "@@cont@@" + this.sex[i];
        }

        if (tempVacancies == "") {
          tempVacancies = this.vacancies[i];
        } else {
          tempVacancies = tempVacancies + "@@cont@@" + this.vacancies[i];
        }

        if (tempExperience == "") {
          tempExperience = this.experience[i];
        } else {
          tempExperience = tempExperience + "@@cont@@" + this.experience[i];
        }

        if (tempEducation == "") {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined &&
            this.education[i] != "undefined"
          ) {
            if (
              this.fieldStudy[i] != "" &&
              this.fieldStudy[i] != undefined &&
              this.fieldStudy[i] != "undefined"
            ) {
              tempEducation = this.education[i] + ", in " + this.fieldStudy[i];
            } else {
              tempEducation = this.education[i];
            }
          } else {
            tempEducation = this.education[i];
          }
        } else {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined &&
            this.education[i] != "undefined"
          ) {
            if (
              this.fieldStudy[i] != "" &&
              this.fieldStudy[i] != undefined &&
              this.fieldStudy[i] != "undefined"
            ) {
              tempEducation =
                tempEducation +
                "@@cont@@" +
                this.education[i] +
                ", in " +
                this.fieldStudy[i];
            } else {
              tempEducation = tempEducation + "@@cont@@" + this.education[i];
            }
          } else {
            tempEducation = tempEducation + "@@cont@@" + this.education[i];
          }
        }

        if (tempJobType == "") {
          tempJobType = this.jobType[i];
        } else {
          tempJobType = tempJobType + "@@cont@@" + this.jobType[i];
        }

        if (tempSalary == "") {
          tempSalary = this.salary[i];
        } else {
          tempSalary = tempSalary + "@@cont@@" + this.salary[i];
        }
      }
      if (isTitleEmpty) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter title"
            : "እባክዎ የስራውን መጠርያ ያስገቡ"
        );
        return;
      }

      // if (this.files.length < 1) {
      //  this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo one"
      //       : "እባክዎ የካምፓኒ አርማ አንድ ያስገቡ"
      //   );
      //   return;
      // }

      // if (this.files2.length < 1) {
      //  this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo two"
      //       : "እባክዎ የካምፓኒ አርማ ሁለት ያስገቡ"
      //   );
      //   return;
      // }

      if (
        this.companyName == "" ||
        this.companyName == undefined ||
        this.companyName == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company name"
            : "እባክዎ የኩባንያውን ስም ያስገቡ"
        );
        return;
      }

      if (
        this.detailDescription == "" ||
        this.detailDescription == undefined ||
        this.detailDescription == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter detail description"
            : "እባክዎ የስራዉን ዝርዝር ያስገቡ"
        );
        return;
      }

      if (
        this.address == "" ||
        this.address == undefined ||
        this.address == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company address"
            : "እባክዎ የኩባንያውን አድራሻ ያስገቡ"
        );
        return;
      }

      if (
        this.deadline == "" ||
        this.deadline == undefined ||
        this.deadline == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company address"
            : "እባክዎ ቀኑ የሚያበቃበትን ያስገቡ"
        );
        return;
      }
      this.applyLink = "";
      if (this.isDirect == 1) {
        if (this.tegcontact == "" && this.phone == "" && this.email == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "You chose direct contact,  but you haven’t provided your contact information. Please include your contact information from the options"
              : "ቀጥተኛ ግንኙነትን መርጠዋል፣ ነገር ግን የመገናኛ መረጃዎን አላቀረቡም። እባክዎን የሚገናኙበትን መረጃ ያካትቱ"
          );
          return;
        }

        this.applyLink = "";
        this.applyLink =
          this.phone + ".cont." + this.tegcontact + ".cont." + this.email;
      }

      let tempZerf = this.title.length > 1 ? 1 : 0;

      this.submitLoading = true;

      // if (this.education != "" && this.education != "Not Required") {
      //   if (this.fieldStudy != "")
      //     this.education = this.education + " in " + this.fieldStudy;
      // }

      try {
        if (this.files[0] != "" && this.files[0] != undefined) {
          await this.uploadImage(this.files[0], 1);
        } else this.imageUrl1 = this.image1;

        if (this.files2[0] != "" && this.files2[0] != undefined) {
          await this.uploadImage(this.files2[0], 2);
        } else this.imageUrl12 = this.image12;

        // if (this.imageUrl1 == "" || this.imageUrl12 == "") {
        //   this.showAlert(
        //     this.$store.state.language == "English"
        //       ? "The logo did not upload. Please try again."
        //       : "አርማው አልተጫነም። እባክዎ እንደገና ይሞክሩ።"
        //   );
        //   this.submitLoading = false;
        //   return;
        // }
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        axios.defaults.headers.get["Accept"] = "application/json;charset=UTF-8";
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,
          data: {
            query: `
                 mutation {
                  editPost(
                   post_id: ${parseInt(this.editablePost.post_id.toString())}
                   title:"${tempjobTitle}"
                    short_description:"${this.shortDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    short_image:"${this.imageUrl1}"

                    detail_description:"${this.detailDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    detail_image:"${this.imageUrl12}"
                    company_address:"${this.address}"
                    deadline_date:"${this.deadline}"
                    
                    sex:"${tempSex}"
                    isdetail:${this.isDetail + 0}
                    isdirect: ${this.isDirect + 0}
                    apply_link:"${this.applyLink}"
                    isregistered: ${this.isRegistration + 0}

                    iscv: ${this.isCv + 0}
                    islinkedin: ${this.islinkedin + 0}
                    iszerf: ${tempZerf + 0}
                    isthesame: ${this.isTheSame + 0}
                    age_above_bellow:"${this.age}"
                    language: "${this.$store.state.language}"

                    company_name:"${this.companyName}"
                    vacancies:"${tempVacancies}"
                    salary:"${tempSalary}"
                    experience:"${tempExperience}"
                    education:"${tempEducation}"
                    job_type:"${tempJobType}"

                    ){
                        statusMessage
                    }
                    }
              `,
          },
        });
        if (result.data.data.editPost[0].statusMessage.toString() == "true") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Your job change request has been submitted successfully. Please wait for admin approval."
              : "የስራ ማስተካከያ ጥያቄዎ በተሳካ ሁኔታ ተልኳል፣ እባክዎ ተቀባይነት እስኪያገኝ ድረስ ይጠብቁ።"
          );
          this.editDialog = false;
          await this.getAllData();
        } else {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Something went wrong, please try again"
              : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.submitLoading = false;
    },
    handleDownload() {
      this.loadPdf = false;
    },
    selectApplicant(selected, i) {
      if (
        this.selectedApplicants.indexOf(
          selected.application_id +
            "," +
            selected.getEmployees[0].tg_id +
            "," +
            i
        ) == -1
      ) {
        this.selectedApplicants.push(
          selected.application_id +
            "," +
            selected.getEmployees[0].tg_id +
            "," +
            i
        );
      } else {
        this.selectedApplicants.splice(
          this.selectedApplicants.indexOf(
            selected.application_id +
              "," +
              selected.getEmployees[0].tg_id +
              "," +
              i
          ),
          1
        );
      }
    },

    unfocus(event) {
      event.target.blur();
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;

      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.showAlert(
          this.$store.state.language == "English"
            ? `${file.name} is not an image`
            : `${file.name} ምስል አይደለም`
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    async uploadImage(fileToUpload, imageNumber) {
      const formData = new FormData();
      formData.append("image", fileToUpload);

      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageNumber == 1)
          this.imageUrl1 = this.domainUrl + response.data.filePath;
        else this.imageUrl12 = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.showAlert("Error uploading image: " + error);
      }
    },

    async payOnInputFile(e) {
      const files = e.target.files;
      this.filespay = e.target.files;
      this.pdfUploadStatus =
        this.$store.state.language == "English" ? "Uploading..." : "በመጫን ላይ...";

      const formData = new FormData();
      formData.append("image", this.filespay[0]);
      let payUrl = "";
      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        payUrl = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        return;
      }

      if (payUrl != "" && payUrl != undefined) {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
          attachPay(
            post_id:${parseInt(this.unpaidPost[0].post_id)}
            bank_slip:"${payUrl}"
            title:"${this.unpaidPost[0].title}"


            ){
           statusMessage
        }

        }
        `,
            },
          });

          if (
            result.data.data.attachPay[0].statusMessage.toString() == "true"
          ) {
            this.loadingCount = 0;
            this.getAllData();
            this.showAlert(
              this.$store.state.language == "English"
                ? "Payment uploaded successfully!. Please wait until it is approved by Admin."
                : "በተሳካ ሁኔታ ተልኳል፣ እባክዎ ተቀባይነት እስኪያገኝ ትንሽ ይጠብቁ!"
            );
            this.unpaidDialog = false;
            this.unpaidPost = [];
            this.image1pay = "";
            this.filespay = [];
          } else
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploading Error, Please try again"
                : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        } catch (err) {
          this.pdfUploadStatus =
            this.$store.state.language == "English"
              ? "Uploading Error, Please try again"
              : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        }
      } else
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
      Array.from(files).forEach((file) => this.addpayImage(file));
    },
    simulatepayInputfileClicked() {
      this.$refs.selectpayImage.click();
    },
    addpayImage(file) {
      if (!file.type.match("image.*")) {
        this.showAlert(
          this.$store.state.language == "English"
            ? `${file.name} is not an image`
            : `${file.name} ምስል አይደለም`
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1pay = f.target.result;
      };
      reader.readAsDataURL(file);
    },

    //image2

    OnInputFile2(e) {
      const files = e.target.files;
      this.files2 = e.target.files;
      Array.from(files).forEach((file) => this.addImage2(file));
    },
    simulateInputfileClicked2() {
      this.$refs.selectImage2.click();
    },

    addImage2(file) {
      if (!file.type.match("image.*")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        this.image12 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage2() {
      this.$refs.changeImage2.click();
    },
    async uploadImage2() {
      var storageReference = this.$storage.ref(
        "jobimages/image" + Math.random() + ".jpg"
      );
      try {
        await storageReference.put(this.files2[0]);
        this.imageUrl12 = await storageReference.getDownloadURL();
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
    },

    contactEmployee(tg) {
      window.location.href = "https://t.me/" + tg;
    },

    async deletePost(post) {
      // const acceptConfirm = confirm(
      //   this.$store.state.language == 'English'
      //     ? 'Are you sure you want to delete this job vacancy?'
      //     : 'እርግጠኛ ነዎት ይህንን የስራ ልጥፍ ማጥፋት ይፈልጋሉ?'
      // );
      const acceptConfirm = this.confirmResult;

      if (acceptConfirm) {
        this.pageLoading = true;
        this.loadingCount = 0;

        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `mutation{
                deleteJob(
                  post_id:${parseInt(post.post_id)}
                  message_id:"${post.message_id}"       

                  ){
                 statusMessage
              }
              }
              `,
            },
          });
          if (
            result.data.data.deleteJob[0].statusMessage.toString() == "true"
          ) {
            this.loadingCount = 0;
            this.getAllData();
          } else
            this.showAlert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      }
    },

    async acceptSelecetedAppts(status) {
      const acceptConfirm = this.confirmResult;
      if (acceptConfirm) {
        this.acceptRejectDialog = true;
        for (let i = 0; i < this.selectedApplicants.length; i++) {
          try {
            var result = await axios({
              method: "POST",
              url: this.$store.state.userURL,
              data: {
                query: `mutation{
                acceptRejectApplicant(
                  application_id:${parseInt(
                    this.selectedApplicants[i].split(",")[0].trim()
                  )}
                  tg_id:"${this.selectedApplicants[i].split(",")[1].trim()}"
                  company_name:"${this.showApplicantsData.company_name}"
                 title: "${this.showApplicantsData.title.replace(
                   "@@cont@@",
                   ","
                 )}"               
                  statusa: ${status}             

                  ){
                 statusMessage
              }
              }
              `,
              },
            });
            if (
              result.data.data.acceptRejectApplicant[0].statusMessage.toString() ==
              "true"
            ) {
              this.showApplicantsData.getApplications[
                parseInt(this.selectedApplicants[i].split(",")[2].trim())
              ].isaccepted = status;
            } else
              this.showAlert(
                this.$store.state.language == "English"
                  ? "Something went wrong, please try again"
                  : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
              );
          } catch (err) {
            this.showAlert(
              this.$store.state.language == "English"
                ? err.message
                : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
            );
          }
        }
        await this.getAllData();
        this.acceptRejectDialog = false;
        this.selectedApplicants = [];
      }
    },
    async getAllData() {
      if (this.loadingCount == 0) {
        this.pageLoading = true;
        this.loadingCount++;
      }

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address
                language
                message_id

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                 is_update_request
                 bank_slip
               
                  getApplications{
                        application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted

                        getEmployees{
                          user_id
                      tg_id
                      tg_username
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      user_pic
                      isverified
                      cv
                      link
                        }

                }


           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.pageLoading = false;
    },

    async acceptApplicant(applicant, status, index) {
      const acceptConfirm = this.confirmResult;
      if (acceptConfirm) {
        this.acceptRejectDialog = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `mutation{
                acceptRejectApplicant(
                  application_id:${parseInt(applicant.application_id)}
                  tg_id:"${applicant.getEmployees[0].tg_id}"
                  company_name:"${this.showApplicantsData.company_name}"
                  title: "${this.showApplicantsData.title.replace(
                    "@@cont@@",
                    ","
                  )}"               
                  statusa: ${status}             

                  ){
                 statusMessage
              }
              }
              `,
            },
          });
          if (
            result.data.data.acceptRejectApplicant[0].statusMessage.toString() ==
            "true"
          ) {
            await this.getAllData();
            this.showApplicantsData.getApplications[index].isaccepted = status;
          } else
            this.showAlert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
        this.acceptRejectDialog = false;
      }
    },

    async closeJob(job, isclose) {
      const approveConfirm = this.confirmResult;

      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                approveJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title}"

                  detail_description: "${job.detail_description
                    .toString()
                    .trim()
                    .replace(/\n/g, "\\n")}"
                  short_image: "${job.short_image}"
                  detail_image: "${job.detail_image}"
                  company_address: "${job.company_address}"
                  deadline_date: "${job.deadline_date}"
                  apply_link: "${job.apply_link}"
                  sex: "${job.sex}"

                company_name: "${job.company_name}"
              vacancies: "${job.vacancies}"
               salary: "${job.salary}"
                 experience: "${job.experience}"
                 education: "${job.education}"
                 job_type: "${job.job_type}"
                 language: "${job.language}"
                 is_update_request:${parseInt(job.is_update_request)}
                 message_id:"${job.message_id}"  
                 isclose:${isclose}



                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.approveJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllData();
          } else
            this.showAlert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }

        this.pageLoading = false;
      }
    },

    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },

  created() {
    this.getAllData();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#imageCamera {
  background-color: #272a2f;
  font-size: 16px;
}

.v-radio .v-icon {
  color: red !important;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

.studyText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 1; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
.letterText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

#userAvt {
  background-color: #e96910;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  accent-color: #0bb93f;
}
</style>
