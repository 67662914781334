var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.user != '' && !_vm.pageLoading)?_c('profileHeader'):_vm._e(),(_vm.$store.state.user != '')?_c('div',{staticClass:"mt-1 container",staticStyle:{"font-size":"15px"}},[(_vm.pageLoading)?_c('div',{staticClass:"text-center mt-15"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"#023679","indeterminate":""}})],1):_vm._e(),(!_vm.pageLoading)?_c('div',{staticClass:"pr-1 pt-2",attrs:{"id":"homePage"}},[_c('div',{staticStyle:{"margin-top":"0px"}},[_c('p',{staticClass:"text-center white--text pb-1"},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Choose a job from the listings or search for your desired position." : "ከዝርዝሩ ውስጥ ሥራ ይምረጡ ወይም የሚፈልጉትን ሥራ ይፈልጉ።")+" ")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"pa-2 pl-3 pt-2 pb-2 icon-rtl",staticStyle:{"font-size":"16px","border-radius":"5px","color":"white","background-color":"#272a2f"},attrs:{"append-icon":"search","type":"text","placeholder":_vm.$store.state.language == 'English' ? 'Find a job' : 'ሥራ ይፈልጉ'},domProps:{"value":(_vm.searchValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value},function($event){return _vm.searchJob($event)}]}})]),_c('br'),_c('br'),_vm._l((_vm.careers),function(post,i){return _c('v-layout',{key:i,staticClass:"white--text",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pl-2 pr-1",attrs:{"xs12":""}},[_c('a',{on:{"click":function($event){_vm.detailDialog = true;
                _vm.selectedDetail = post;}}},[_c('v-avatar',{attrs:{"size":"30px"}},[(post.short_image != '')?_c('v-img',{staticStyle:{"border-radius":"50%"},attrs:{"height":"70","width":"70","alt":"Avatar","src":post.short_image}}):_vm._e()],1),_vm._v("     "),_c('span',{staticClass:"white--text"},[_c('strong',[_vm._v(_vm._s(post.title.replace("@@cont@@", ",")))])])],1),_c('br')]),_c('v-flex',{staticClass:"mt-2 mb-2",attrs:{"xs12":""}},[_c('v-divider',{staticStyle:{"border-color":"#272a2f"}})],1)],1)})],2),(_vm.selectedDetail != '')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","persistent":""},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('v-card',{staticClass:"white--text",staticStyle:{"background-color":"#000000"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Detail" : "ዝርዝር")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"pink","text":""},on:{"click":function($event){_vm.detailDialog = false;
                _vm.selectedDetail = '';}}},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Close" : "ዝጋ")+" ")])],1),_c('v-card-text',{staticClass:"pb-15"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-layout',{staticClass:"pa-1 mt-1 white--text",attrs:{"row":"","wrap":""}},_vm._l((_vm.selectedDetail.title.split('@@cont@@')),function(job,i){return _c('v-flex',{key:i,attrs:{"xs12":""}},[(i == 0)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Company Name" : "የድርጅት ስም")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.company_name))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Work Location" : "የስራ ቦታ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.company_address))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('br'):_vm._e(),_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Title" : "የስራው መደብ")+": ")]),_vm._v(" "+_vm._s(job))]),_c('br'),(
                      _vm.selectedDetail.sex.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Applicants Needed" : "ተፈላጊ ጾታ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.sex.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.sex.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.vacancies.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Vacancies" : "ብዛት")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.vacancies.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.vacancies.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.salary.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.salary.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Salary" : "ደሞዝ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.salary.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.salary.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.salary.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.experience.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.experience.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Experience Level" : "የስራ ልምድ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.experience.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.experience.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.experience.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.education.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.education.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Education Qualification" : "የትምህርት ደረጃ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.education.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.education.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.education.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.job_type.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Job Type" : "የቅጥር አይነት")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.job_type.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                      _vm.selectedDetail.job_type.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      i == _vm.selectedDetail.title.split('@@cont@@').length - 1
                    )?_c('div',{staticClass:"mt-4"},[(
                        new Date(_vm.selectedDetail.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                        Date.now()
                      )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Deadline" : "ግዜው የሚያበቃበት")+": ")]),_vm._v(" "+_vm._s(_vm.getDeadline))]):_c('span',{staticClass:"pink--text"},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Deadline" : "ግዜው የሚያበቃበት")+": ")]),_vm._v(" ( "+_vm._s(_vm.getDeadline)+")"+_vm._s(_vm.$store.state.language == "English" ? ", Expired" : "፣ ጊዜው አልፎበታል።"))]),(_vm.selectedDetail.short_image != '')?_c('v-img',{staticClass:"ml-0 mr-0 mt-2 mb-3",attrs:{"width":"100%","height":"150","src":_vm.selectedDetail.short_image,"contain":""}}):_vm._e(),_c('span',{staticClass:"truncate-text mt-2",staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Description" : "ዝርዝር መግለጫ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.detail_description))]),_c('br')],1):_vm._e(),_c('br')])}),1)],1),_c('div',{staticClass:"mt-10 text-center"},[_c('v-btn',{staticClass:"pl-15 pr-15 mt-7 mb-15 white--text",staticStyle:{"background-image":"linear-gradient(#023679, #023679)","border-radius":"10px","font-size":"18px"},attrs:{"center":"","text":""},on:{"click":function($event){_vm.detailDialog = false;

                  _vm.$store.dispatch('changeParam', _vm.selectedDetail.post_id);
                  _vm.$store.dispatch('changeBtn', 'home');
                  _vm.$router.push({ name: 'home' });}}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "See More" : "የበለጠ ይመልከቱ"))])])],1)],1)],1)],1):_vm._e()],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }