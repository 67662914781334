<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" v-if="!pageLoading" class="pr-1 pt-2">
        <div style="margin-top: 0px">
          <p class="text-center white--text pb-3">
            {{
              $store.state.language == "English"
                ? "No alert has been found."
                : "እስካሁን ምንም ማሳወቅያ አልተገኘም"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
  }),

  methods: {},
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

#userAvt {
  background-color: #e96910;
}
</style>
