<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" v-if="!pageLoading" class="pr-1 pt-2">
        <div style="margin-top: 0x">
          <p class="white--text pb-3">
            <strong>
              {{
                $store.state.language == "English" ? "About Us" : "ስለ እኛ"
              }}</strong
            >
            <br />
            <br />
            {{
              $store.state.language == "English"
                ? "Welcome to Addis Ababa Jobs, the platform that connects employers" +
                  " with top talent effortlessly. " +
                  "Our goal is to streamline the hiring process, making it easier for" +
                  " companies to post job opportunities and for job seekers to find" +
                  " their next career move. Whether you're looking to fill a position" +
                  " quickly or explore new job listings, we provide a simple," +
                  " efficient solution to match the right people with the right roles."
                : "እንኳን ወደ አዲስ አበባ ስራዎች፤ አሰሪዎችን ከከፍተኛ ችሎታ ያላቸው ሰራተኞች ጋር ወደሚያገናኝ መድረክ በደህና መጡ። ግባችን የቅጥር ሂደቱን ማቅለል እና ማቀላጠፍ ነው፣ ይህም ድርጅቶች የስራ እድሎችን እንዲለጥፉ እና ስራ ፈላጊዎችም ስራዉን በስልካቸው በቀላሉ እንዲያገኙ ማድረግ ነው!።"
            }}

            <br />
            <br />
            <br />

            <strong>
              {{
                $store.state.language == "English" ? "Contact Us" : "ያግኙን"
              }}</strong
            >
            <br />
            <br />

            {{ $store.state.language == "English" ? "Telegram" : "ቴሌግራም" }}:
            <a href="https://t.me/W3w22121" style="text-decoration: none"
              >@customer_service</a
            >

            <br />
            {{ $store.state.language == "English" ? "Email" : "ኢሜይል" }}:
            <a>contact@jobsaddisababa.com </a><br /><br />

            <a href="https://t.me/W3w22121">{{
              $store.state.language == "English" ? "@Developers" : "@Developers"
            }}</a>
            <br />

            <br />
            {{
              $store.state.language == "English"
                ? "Join us and take the next step in building your team or advancing your career."
                : ""
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
  }),

  methods: {},
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

#userAvt {
  background-color: #e96910;
}
</style>
