<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" v-if="!pageLoading" class="pr-1 pt-2">
        <div style="margin-top: 0px">
          <p class="text-center white--text pb-1">
            {{
              $store.state.language == "English"
                ? "Choose a job from the listings or search for your desired position."
                : "ከዝርዝሩ ውስጥ ሥራ ይምረጡ ወይም የሚፈልጉትን ሥራ ይፈልጉ።"
            }}
          </p>
          <div>
            <input
              append-icon="search"
              v-model="searchValue"
              @input="searchJob($event)"
              class="pa-2 pl-3 pt-2 pb-2 icon-rtl"
              style="
                font-size: 16px;
                border-radius: 5px;
                color: white;
                background-color: #272a2f;
              "
              type="text"
              :placeholder="
                $store.state.language == 'English' ? 'Find a job' : 'ሥራ ይፈልጉ'
              "
            />
          </div>

          <br />
          <br />
          <v-layout
            row
            wrap
            class="white--text"
            v-for="(post, i) in careers"
            :key="i"
          >
            <v-flex xs12 class="pl-2 pr-1">
              <a
                @click="
                  detailDialog = true;
                  selectedDetail = post;
                "
              >
                <v-avatar size="30px">
                  <v-img
                    height="70"
                    width="70"
                    style="border-radius: 50%"
                    v-if="post.short_image != ''"
                    alt="Avatar"
                    :src="post.short_image"
                  ></v-img>
                </v-avatar>
                &nbsp; &nbsp;
                <span class="white--text">
                  <strong>{{ post.title.replace("@@cont@@", ",") }}</strong>
                </span></a
              >

              <br />
            </v-flex>

            <v-flex xs12 class="mt-2 mb-2">
              <v-divider style="border-color: #272a2f"></v-divider>
            </v-flex>
          </v-layout>
        </div>
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="detailDialog"
          fullscreen
          persistent
          v-if="selectedDetail != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  detailDialog = false;
                  selectedDetail = '';
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex
                    xs12
                    v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ selectedDetail.company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ selectedDetail.company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{ selectedDetail.vacancies.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{ selectedDetail.experience.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{ selectedDetail.education.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i == selectedDetail.title.split('@@cont@@').length - 1
                      "
                    >
                      <span
                        v-if="
                          new Date(selectedDetail.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadline }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadline }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >

                      <v-img
                        v-if="selectedDetail.short_image != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.short_image"
                        contain
                      ></v-img>

                      <span
                        style="white-space: pre-line"
                        class="truncate-text mt-2"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ selectedDetail.detail_description }}</span
                      >

                      <br />
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
              <div class="mt-10 text-center">
                <v-btn
                  @click="
                    detailDialog = false;

                    $store.dispatch('changeParam', selectedDetail.post_id);
                    $store.dispatch('changeBtn', 'home');
                    $router.push({ name: 'home' });
                  "
                  center
                  class="pl-15 pr-15 mt-7 mb-15 white--text"
                  text
                  style="
                    background-image: linear-gradient(#023679, #023679);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize">{{
                    $store.state.language == "English"
                      ? "See More"
                      : "የበለጠ ይመልከቱ"
                  }}</span>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";
import _ from "lodash";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    selectedDetail: "",
    detailDialog: false,
    careers: [],
    searchValue: "",
    loadingCount: 0,
  }),

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.deadline_date);
    },
  },

  methods: {
    searchJob(event) {
      this.searchValue = event.target.value;
      setTimeout(() => {
        this.getCareerData(this.searchValue);
      }, 700);
    },
    async getCareerData(value) {
      if (this.loadingCount == 0) {
        this.pageLoading = true;
        this.loadingCount++;
      }

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
                getCareerData(searchValue:"${value}"){
                         post_id
                 tg_id
                 title
                  short_description
                  short_image
                  detail_description
                  detail_image
                  company_address
  
                 deadline_date
                  application_format
                  isdetail
                  isdirect
                 apply_link
                  isregistered
                  posted_date
  
                  iscv
                  islinkedin
                  iszerf
                 sex
                 age_above_bellow
                 isthesame
                 isapproved
                 isrejected
                  language
                  message_id
                  company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                      
  
  
             }
        }
        `,
          },
        });

        this.careers = resultapp.data.data.getCareerData;
      } catch (err) {}
      this.pageLoading = false;
    },

    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },
  // watch: {
  //   searchValue: _.debounce(function (value) {
  //     this.getCareerData(value);
  //   }, 500),
  // },
  created() {
    this.getCareerData("");
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

#userAvt {
  background-color: #e96910;
}
input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

.icon-rtl {
  padding-right: 25px;
  background: url("../assets/search.png") no-repeat right;
  background-size: 30px;
}

.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
</style>
