<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="loginpage">
    <div class="text-center loadingText" v-if="pageLoading">
      <v-progress-circular
        :size="55"
        :width="4"
        color="#023679"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-5 mt-0"
        id="login"
      >
        <v-flex xs12 class="">
          <p>Bingo Games</p>
          <v-flex xs10 class="mt-4 ml-6 ma-4">
            <v-text-field
              v-model="ipAddress"
              dense
              class="white"
              label="IP Address"
              hide-details="true"
              single-line
              outlined
              @change="
                $store.dispatch(
                  'dogURL',
                  'http://' + ipAddress + ':8136/graphql'
                )
              "
            >
            </v-text-field>
          </v-flex>
          <p class="headline">Admin Login</p>
        </v-flex>
        <v-flex xs12 class="mt-1">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="Username"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="adminLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="adminLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout> -->

    <AlertDialog
      :message="alertMessage"
      :show="showAlertDialog"
      @close="showAlertDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import AlertDialog from "./AlertDialog.vue";
export default {
  components: {
    AlertDialog,
  },
  data: () => ({
    username: "",
    password: "",
    adminLoginLoading: false,
    pageLoading: false,
    ipAddress: "",
    tg_id: "",
    tg_username: "",
    showAlertDialog: false,
    alertMessage: "",
    // #292732
    // #110f1a
  }),

  methods: {
    showAlert(message) {
      this.alertMessage = message;
      this.showAlertDialog = true;
    },
    async login() {
      if (this.tg_id != "") {
        this.pageLoading = true;
        var profilePictureUrl = "";

        try {
          var userLoginResult = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `{
               userLogin(
               tg_id:"${this.tg_id}"
              user_pic: "${profilePictureUrl}"
              tg_username: "${this.tg_username}"
                 ){
                     user_id
                      tg_id
                      first_name
                      middle_name
                      last_name
                      user_pic
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                      tg_username
                      statusMessage
                      getActiveUserCount{
                      count_result
                      }

                   }
              }
              `,
            },
          });

          if (userLoginResult.data.data.userLogin.length > 0) {
            this.$router.push({ name: "home" });
            this.$store.dispatch(
              "loginUser",
              userLoginResult.data.data.userLogin[0]
            );
          } else {
            var addResult = await axios({
              method: "POST",
              url: this.$store.state.userURL,

              data: {
                query: `mutation{
                     addUser(
                        tg_id: "${this.tg_id}"
                        tg_username: "${this.tg_username}"
                        user_pic: "${profilePictureUrl}"

                            ){
                              statusMessage
                          }

                }
                `,
              },
            });

            if (
              addResult.data.data.addUser[0].statusMessage.toString() == "true"
            ) {
              this.login();
            } else {
              this.showAlert(
                this.$store.state.language == "English"
                  ? "Something went wrong, please try again"
                  : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
              );
            }
          }
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }

        this.pageLoading = false;
      } else
        this.showAlert(
          this.$store.state.language == "English"
            ? "Something went wrong, please try again"
            : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
        );
    },
  },

  async created() {
    this.$store.dispatch("loginUser", "");
    this.$store.dispatch("userHeader", "");
    this.$store.dispatch("changeeditProfileDialog", false);
    let param1 = "";
    // const url = new URL(window.location.href);
    // const params = new URLSearchParams(url.search);

    this.$store.dispatch("changeBtn", "home");
    // this.$store.dispatch("changeParam", 129);
    //window.Telegram.WebApp
    if (window.Telegram.WebApp) {
      await window.Telegram.WebApp.ready();

      const user = await window.Telegram.WebApp.initDataUnsafe.user;
      param1 = await window.Telegram.WebApp.initDataUnsafe.start_param;
      // param1 = params.get("postId");

      this.$store.dispatch("changeParam", param1);

      this.tg_id = user.id.toString();
      this.tg_username = user.username || "No Username";

      await window.Telegram.WebApp.expand();
      // await window.Telegram.WebApp.enableClosingConfirmation();
      // await window.Telegram.WebApp.disableVerticalSwipes();
      await window.Telegram.WebApp.setHeaderColor("#000000");
      await window.Telegram.WebApp.setBottomBarColor("#000000");
      await this.login();
    } else {
      this.tg_id = "6160879304";
      this.tg_username = "Misterx";
      await this.login();
    }
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}

#loginpage {
  background-image: url("../assets//Intro.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.loadingText {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
</style>
