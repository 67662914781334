import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/home";
import login from "@/components/login";

import myJobs from "@/components/myJobs";
import myApplication from "@/components/myApplication";
import approveJobs from "@/components/jobReview";
import careers from "@/components/career";
import alert from "@/components/alert";
import about from "@/components/about";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/Dashboard",
    name: "home",
    component: home,
  },

  {
    path: "/my-jobs",
    name: "myjobs",
    component: myJobs,
  },

  {
    path: "/my-applications",
    name: "myApplication",
    component: myApplication,
  },

  {
    path: "/job-careers",
    name: "career",
    component: careers,
  },

  {
    path: "/approve-jobs",
    name: "approve",
    component: approveJobs,
  },

  {
    path: "/alert",
    name: "alert",
    component: alert,
  },

  {
    path: "/about-us",
    name: "about",
    component: about,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
