var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.user != '' && !_vm.pageLoading)?_c('profileHeader'):_vm._e(),(_vm.$store.state.user != '')?_c('div',{staticClass:"mt-1 container",staticStyle:{"font-size":"15px"}},[(_vm.pageLoading)?_c('div',{staticClass:"text-center mt-15"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"#023679","indeterminate":""}})],1):_vm._e(),(!_vm.pageLoading)?_c('div',{staticClass:"pr-1 pt-2",attrs:{"id":"homePage"}},[_c('div',{staticStyle:{"margin-top":"0px"}},[_c('h4',{staticClass:"text-center white--text pb-5"},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? `You have applied ${_vm.allApplications.length} ${ _vm.allApplications.length == 1 ? " Job" : " Jobs" }` : `${_vm.allApplications.length} ${ _vm.allApplications.length == 1 ? " ስራ" : " ስራዎችን" } አመልክተዋል`)+" ")]),_c('br'),_vm._l((_vm.allApplications),function(post,i){return _c('v-layout',{key:i,staticClass:"white--text",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pl-2 pr-1",attrs:{"xs7":""}},[_c('span',[_c('strong',[_vm._v(_vm._s(post.getJobs[0].title.replace("@@cont@@", ",")))])]),_c('br'),_c('span',{staticClass:"truncate-text grey--text"},[_vm._v(_vm._s(post.getJobs[0].detail_description))])]),_c('v-flex',{staticClass:"pr-1 text-right pt-2",attrs:{"xs5":""}},[(post.isaccepted == 2)?_c('span',{staticClass:"pink--text"},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Rejected" : "ውድቅ"))]):_vm._e(),(post.isaccepted == 0)?_c('span',{staticClass:"orange--text"},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Pending" : "በመጠባበቅ")+" ")]):_vm._e(),(post.isaccepted == 1)?_c('span',{staticClass:"green--text"},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Accepted" : "ተመርጠዋል"))]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","text":""},on:{"click":function($event){_vm.selectedDetail = post;
                _vm.detailDialog = true;}}},[_c('v-icon',{staticClass:"grey--text"},[_vm._v("visibility")])],1)],1),_c('v-flex',{staticClass:"mt-2 mb-2",attrs:{"xs12":""}},[_c('v-divider',{staticStyle:{"border-color":"#272a2f"}})],1)],1)})],2),(_vm.selectedDetail != '')?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","fullscreen":"","persistent":""},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}},[_c('v-card',{staticClass:"white--text",staticStyle:{"background-color":"#000000"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Detail" : "ዝርዝር")+" "),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"dark":"","color":"pink","text":""},on:{"click":function($event){_vm.detailDialog = false;
                _vm.selectedDetail = '';}}},[_vm._v(" "+_vm._s(_vm.$store.state.language == "English" ? "Close" : "ዝጋ")+" ")])],1),_c('v-card-text',{staticClass:"pb-15"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-layout',{staticClass:"pa-1 mt-1 white--text",attrs:{"row":"","wrap":""}},_vm._l((_vm.selectedDetail.getJobs[0].title.split(
                    '@@cont@@'
                  )),function(job,i){return _c('v-flex',{key:i,attrs:{"xs12":""}},[(i == 0)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Company Name" : "የድርጅት ስም")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].company_name))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Work Location" : "የስራ ቦታ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].company_address))]):_vm._e(),(i == 0)?_c('br'):_vm._e(),(i == 0)?_c('br'):_vm._e(),_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Title" : "የስራው መደብ")+": ")]),_vm._v(" "+_vm._s(job))]),_c('br'),(
                      _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                        '' &&
                      _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Applicants Needed" : "ተፈላጊ ጾታ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].sex.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                        '' &&
                      _vm.selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Vacancies" : "ብዛት")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].vacancies.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                        '' &&
                      _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Salary" : "ደሞዝ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].salary.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                        '' &&
                      _vm.selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                        'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].experience.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].experience.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Experience Level" : "የስራ ልምድ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].experience.split("@@cont@@")[ i ]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].experience.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].experience.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Education Qualification" : "የትምህርት ደረጃ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].education.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].education.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('br'):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Job Type" : "የቅጥር አይነት")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].job_type.split("@@cont@@")[i]))]):_vm._e(),(
                      _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                        i
                      ] != '' &&
                      _vm.selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                        i
                      ] != 'undefined'
                    )?_c('br'):_vm._e(),(
                      i ==
                      _vm.selectedDetail.getJobs[0].title.split('@@cont@@')
                        .length -
                        1
                    )?_c('div',{staticClass:"mt-4"},[(
                        new Date(
                          _vm.selectedDetail.getJobs[0].deadline_date
                        ).getTime() +
                          24 * 60 * 60 * 1000 >
                        Date.now()
                      )?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Deadline" : "ግዜው የሚያበቃበት")+": ")]),_vm._v(" "+_vm._s(_vm.getDeadline))]):_c('span',{staticClass:"pink--text"},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Deadline" : "ግዜው የሚያበቃበት")+": ")]),_vm._v(" ( "+_vm._s(_vm.getDeadline)+")"+_vm._s(_vm.$store.state.language == "English" ? ", Expired" : "፣ ጊዜው አልፎበታል።"))]),(_vm.selectedDetail.getJobs[0].short_image != '')?_c('v-img',{staticClass:"ml-0 mr-0 mt-2 mb-3",attrs:{"width":"100%","height":"150","src":_vm.selectedDetail.getJobs[0].short_image,"contain":""}}):_vm._e(),_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Description" : "ዝርዝር መግለጫ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.getJobs[0].detail_description))]),_c('br'),_c('br'),_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Your Application Letter" : "የእርስዎ ማመልከቻ")+": "),_c('br')]),_vm._v(" "+_vm._s(_vm.selectedDetail.cover_letter))]),_c('br'),(_vm.selectedDetail.sector != '')?_c('span',{staticStyle:{"white-space":"pre-line"}},[_c('strong',[_vm._v(_vm._s(_vm.$store.state.language == "English" ? "Your selected sector is" : "የመረጡት ዘርፍ")+": ")]),_vm._v(" "+_vm._s(_vm.selectedDetail.sector))]):_vm._e()],1):_vm._e(),_c('br')])}),1)],1)],1)],1)],1):_vm._e()],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }