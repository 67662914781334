<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="pageLoading && $store.state.userHeaders == ''" id="loginpage">
      <div class="text-center loadingText">
        <v-progress-circular
          :size="55"
          :width="4"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div
      class="text-center mt-15"
      v-if="pageLoading && $store.state.userHeaders != ''"
    >
      <v-progress-circular
        :size="70"
        :width="2"
        color="#023679"
        indeterminate
      ></v-progress-circular>
    </div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <v-container
      v-if="$store.state.user != '' && !pageLoading"
      class="mt-1"
      style="font-size: 15px"
    >
      <div id="homePage" class="pr-1">
        <v-layout row wrap class="pl-1 pr-1" style="padding-top: 1px">
          <!-- <v-flex xs12>
            <v-btn
              class="green white--text"
              id="'zoomme"
              :class="{ zoom: isZoomed }"
              @mouseover="startZoom"
              @mouseleave="stopZoom"
            >
              Zoom Me!
            </v-btn>
          </v-flex> -->
          <v-flex xs12 class="mb-2 pl-4 pr-4 mt-1">
            <a
              @click="
                postJobDialog = true;
                tgid = $store.state.user.tg_id;
                education = [];
              "
            >
              <!-- background-color: rgba(38, 41, 48, 0.4); -->
              <v-card
                elevation="0"
                style="
                  background-color: rgba(58, 60, 98, 0.7);
                  border-radius: 25px;
                "
                class="white--text text-center pa-1"
              >
                <v-layout row wrap class="pl-3">
                  <v-flex xs5 class="text-right pb-1 pt-3">
                    <img
                      src="../assets/bag.png"
                      height="30px"
                      width="35px"
                      alt="L"
                      contain
                    />
                  </v-flex>
                  <v-flex xs7 class="text-left pt-4 pl-1">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? "Post a Job"
                          : "ሥራ ይለጥፉ"
                      }}
                    </h4>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>
          <!-- <v-flex xs6 class="mb-4 pl-3 pr-3">
            <a
              @click="
                postJobDialog = true;
                tgid = $store.state.user.tg_id;
                education = [];
              "
            >
              <v-card
                style="
                  background-image: linear-gradient(#343754, #3d3f6d);
                  border-radius: 10px;
                "
                class="white--text text-center pa-1"
              >
                <h4>
                  {{
                    $store.state.language == "English"
                      ? "Post a Job"
                      : "ሥራ ይለጥፉ"
                  }}
                </h4>
              </v-card>
            </a>
          </v-flex> -->
          <v-flex xs6 md4>
            <a
              @click="
                $store.dispatch('changeBtn', 'jobs');
                $router
                  .push({
                    name: 'myjobs',
                  })
                  .catch((err) => {});
              "
            >
              <v-card
                class="white--text ma-1 mb-2 text-center pt-3 pb-5 pa-1"
                height="238px"
                style="
                  background-image: linear-gradient(#0c3440, #03080c);
                  border-radius: 20px;
                  border: none;
                "
              >
                <div class="pa-5 pb-1 pl-6 pr-7 pt-4 text-center">
                  <v-img
                    style="border-radius: 6px"
                    alt="Avatar"
                    contain
                    src="../assets/myvacancies.png"
                  ></v-img>
                </div>
                <!-- <v-icon style="font-size: 50px" x-large class="blue--text">
                  work
                </v-icon> -->
                <h4 style="font-size: 12px">
                  {{
                    $store.state.language == "English"
                      ? "My Vacancies"
                      : "የእኔ የስራ ልጥፎች"
                  }}
                </h4>
                <v-layout row wrap class="pt-4 pb-1">
                  <v-flex
                    xs7
                    style="font-size: 10px; color: #5e676c"
                    class="mb-1 text-right pr-1"
                  >
                    {{
                      $store.state.language == "English"
                        ? "All Posted Jobs"
                        : "ሁሉም ልጥፎቼ"
                    }}
                  </v-flex>
                  <v-flex xs1>
                    <img
                      src="../assets/point.png"
                      height="19px"
                      width="14px"
                      alt="L"
                      contain
                    />
                  </v-flex>
                  <v-flex xs3 class="text-left">
                    <h3 style="margin-top: -6px">
                      <strong style="font-size: 14px; color: white"
                        >+{{ allPosts.length }}</strong
                      >
                    </h3>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="mt-2 pb-2">
                  <v-flex
                    xs7
                    style="font-size: 10px; color: #5e676c"
                    class="mb-1 text-right pr-1"
                  >
                    {{
                      $store.state.language == "English"
                        ? "Active Jobs"
                        : "ቀነ ገደብ ያላበቃ"
                    }}
                  </v-flex>
                  <v-flex xs1>
                    <img
                      src="../assets/point.png"
                      height="19px"
                      width="14px"
                      alt="L"
                      contain
                    />
                  </v-flex>
                  <v-flex xs3 class="text-left">
                    <h3 style="margin-top: -6px">
                      <strong style="font-size: 14px; color: white"
                        >+{{ activeJobs }}</strong
                      >
                    </h3>
                  </v-flex>
                </v-layout>
                <!-- 
                <p style="font-size: 10px; color: #5e676c" class="mb-1">
                 
                
            
               
                </p> -->

                <v-divider id="divider"></v-divider>

                <v-layout row wrap class="mt-2 pl-1 pr-2">
                  <v-flex xs4 clas="text-center">
                    <div
                      style="
                        border-right: 1px solid;
                        border-color: #383838;
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        color: #5e676c;
                      "
                    >
                      {{
                        $store.state.language == "English"
                          ? "Pending"
                          : "በመጠባበቅ"
                      }}

                      <p style="color: #5e676c; margin-top: -3px">
                        <strong style="font-size: 10px; color: #5e676c">
                          +{{ pendingJobs }}</strong
                        >
                      </p>
                    </div>
                  </v-flex>
                  <v-flex xs4 clas="text-center">
                    <div
                      style="
                        border-right: 1px solid;
                        border-color: #383838;
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        color: #5e676c;
                      "
                    >
                      {{
                        $store.state.language == "English"
                          ? "Expired"
                          : "ያለፈባቸው"
                      }}

                      <p style="color: #5e676c; margin-top: -3px">
                        <strong style="font-size: 10px; color: #5e676c">
                          +{{ expiredJobs }}</strong
                        >
                      </p>
                    </div>
                  </v-flex>

                  <v-flex xs4 clas="text-center">
                    <div
                      style="
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        color: #5e676c;
                      "
                    >
                      {{
                        $store.state.language == "English"
                          ? "Rejected"
                          : "ውድቅ የሆኑ"
                      }}

                      <p style="color: #5e676c; margin-top: -3px">
                        <strong style="font-size: 10px; color: #5e676c">
                          +{{ rejectedJobs }}</strong
                        >
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>

          <v-flex xs6 md4>
            <a
              @click="
                $store.dispatch('changeBtn', '');
                $router
                  .push({
                    name: 'myApplication',
                  })
                  .catch((err) => {});
              "
            >
              <v-card
                class="white--text ma-1 mb-2 text-center pt-3 pb-5 pa-1"
                height="238px"
                style="
                  background-image: linear-gradient(#43364a, #0e0a0e);
                  border-radius: 20px;
                "
              >
                <div class="pa-5 pb-1 pl-6 pr-7 pt-4 text-center">
                  <v-img
                    style="border-radius: 6px"
                    contain
                    src="../assets/myapplication.png"
                  ></v-img>
                </div>
                <!-- <v-icon style="font-size: 50px" x-large class="blue--text">
                  work
                </v-icon> -->
                <h4 style="font-size: 12px">
                  {{
                    $store.state.language == "English"
                      ? "My Applications"
                      : "የእኔ ማመልከቻዎች"
                  }}
                </h4>

                <v-layout row wrap class="pt-4 pb-1">
                  <v-flex
                    xs7
                    style="font-size: 10px; color: #6b666b"
                    class="mb-1 text-right pr-1"
                  >
                    {{
                      $store.state.language == "English"
                        ? "Total Applied"
                        : "ጠቅላላ ማመልከቻ"
                    }}
                  </v-flex>
                  <v-flex xs1>
                    <img
                      src="../assets/point.png"
                      height="19px"
                      width="14px"
                      alt="L"
                      contain
                    />
                  </v-flex>
                  <v-flex xs3 class="text-left">
                    <h3 style="margin-top: -6px">
                      <strong style="font-size: 14px; color: white"
                        >+{{ allApplications.length }}</strong
                      >
                    </h3>
                  </v-flex>
                </v-layout>

                <v-layout row wrap class="mt-2 pb-2">
                  <v-flex
                    xs8
                    style="font-size: 10px; color: #6b666b"
                    class="mb-1 text-right pr-1"
                  >
                    {{
                      $store.state.language == "English"
                        ? "Accepted Applications"
                        : "ተቀባይነት ያገኙ"
                    }}
                  </v-flex>
                  <v-flex xs1>
                    <img
                      src="../assets/point.png"
                      height="19px"
                      width="14px"
                      alt="L"
                      contain
                    />
                  </v-flex>
                  <v-flex xs3 class="text-left">
                    <h3 style="margin-top: -6px">
                      <strong style="font-size: 14px; color: white"
                        >+{{ acceptedApplications }}</strong
                      >
                    </h3>
                  </v-flex>
                </v-layout>

                <v-divider id="divider"></v-divider>

                <v-layout row wrap class="mt-2 pl-2 pr-3">
                  <v-flex xs6 clas="text-center">
                    <div
                      style="
                        border-right: 1px solid;
                        border-color: #383838;
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        color: #6b666b;
                      "
                    >
                      {{
                        $store.state.language == "English"
                          ? "Pending"
                          : "በመጠባበቅ"
                      }}

                      <p style="color: #6b666b; margin-top: -3px">
                        <strong style="font-size: 10px; color: #6b666b">
                          +{{
                            allApplications.length -
                            acceptedApplications -
                            rejectedApplications
                          }}</strong
                        >
                      </p>
                    </div>
                  </v-flex>

                  <v-flex xs6 clas="text-center">
                    <div
                      style="
                        padding: 0;
                        margin: 0;
                        font-size: 10px;
                        color: #6b666b;
                      "
                    >
                      {{
                        $store.state.language == "English"
                          ? "Rejected"
                          : "ውድቅ የሆኑ"
                      }}

                      <p style="color: #6b666b; margin-top: -3px">
                        <strong style="font-size: 10px; color: #6b666b">
                          +{{ rejectedApplications }}</strong
                        >
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>

          <v-flex xs6 md4>
            <a>
              <v-card
                class="white--text ma-1 mb-2 text-center pt-3 pb-5 pa-1"
                height="238px"
                style="
                  background-image: linear-gradient(#44494b, #080a09);
                  border-radius: 20px;
                "
              >
                <div class="pa-5 pb-0 pl-6 pr-7 pt-4 text-center">
                  <v-img
                    style="border-radius: 6px"
                    alt="Avatar"
                    contain
                    src="../assets/activeusers.png"
                  ></v-img>
                </div>
                <!-- <v-icon style="font-size: 50px" x-large class="blue--text">
                  work
                </v-icon> -->
                <h4 class="pt-1" style="font-size: 12px">
                  {{
                    $store.state.language == "English"
                      ? "Active Users"
                      : "አጠቃላይ ተጠቃሚዎች"
                  }}
                </h4>

                <v-icon style="color: #535456" class="mb-1"> group </v-icon>

                <span style="font-size: 10px; color: #5e676c" class="ml-2 pt-1">
                  <strong style="font-size: 12px; color: #535456">{{
                    $store.state.user.getActiveUserCount[0].count_result.toLocaleString()
                  }}</strong>
                </span>

                <br />

                <p style="font-size: 10px; color: #535456" class="mb-1 mt-1">
                  {{
                    $store.state.language == "English"
                      ? "Thank you for visiting us!"
                      : "ስለጎበኙን እናመሰግናለን!"
                  }}
                </p>

                <v-divider id="divider"></v-divider>

                <v-layout row wrap class="mt-2 pl-2 pr-3">
                  <v-flex
                    xs4
                    style="border-right: 1px solid; border-color: #494949"
                  >
                    <v-btn
                      @click="shareChannel"
                      x-small
                      left
                      text
                      style="font-size: 18px"
                      class="text-capitalize pr-5"
                    >
                      <v-icon small class="grey--text text--lighten-1 pt-1 pb-1"
                        >share</v-icon
                      ></v-btn
                    >
                  </v-flex>

                  <v-flex xs8>
                    <v-btn
                      @click="getCustomerService('W3w22121')"
                      text
                      x-small
                      style="font-size: 10px"
                      class="white--text text-capitalize"
                      >{{
                        $store.state.language == "English"
                          ? "Comment"
                          : "አስተያየት ይስጡን"
                      }}</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>
          <v-flex xs6 md4>
            <a>
              <v-card
                class="white--text ma-1 mb-2 text-center pt-3 pb-5 pa-1"
                height="238px"
                style="
                  background-image: linear-gradient(#343d20, #0c0f06);
                  border-radius: 20px;
                "
              >
                <div class="pa-5 pb-0 pl-6 pr-7 pt-4 text-center">
                  <v-img
                    style="border-radius: 6px"
                    alt="Avatar"
                    contain
                    src="../assets/service.png"
                  ></v-img>
                </div>
                <!-- <v-icon style="font-size: 50px" x-large class="blue--text">
                  work
                </v-icon> -->
                <h4 class="pt-1" style="font-size: 12px">
                  {{
                    $store.state.language == "English"
                      ? "Customer Service"
                      : "የደንበኛ አገልግሎት"
                  }}
                </h4>
                <a @click="getCustomerService('W3w22121')"
                  ><v-icon style="color: #636759; font-size: 16px">
                    alternate_email</v-icon
                  >

                  <span style="font-size: 10px; color: #636759" class="">
                    <strong style="font-size: 12px; color: #636759">{{
                      "customer_service"
                    }}</strong>
                  </span></a
                >

                <br />

                <p
                  style="font-size: 10px; color: #636759; padding-top: 2px"
                  class="mt-2 mb-1"
                >
                  {{
                    $store.state.language == "English"
                      ? "Discover more info"
                      : "ተጨማሪ መረጃ ያግኙ"
                  }}
                </p>

                <v-divider id="divider"></v-divider>

                <v-layout row wrap class="mt-2 pl-3 pr-4">
                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      @click="getCustomerService('W3w22121')"
                      target="_blank"
                      ><img
                        height="21"
                        width="26"
                        contain
                        src="../assets/fb.svg"
                      />
                    </v-btn>
                  </v-flex>

                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      href="https://www.tiktok.com/@addis_ababa_jobs?lang=en"
                      target="_blank"
                      ><img
                        height="20"
                        width="20"
                        contain
                        src="../assets/tiktok.png"
                      />
                    </v-btn>
                  </v-flex>

                  <v-flex xs4 class="text-center">
                    <v-btn
                      text
                      x-small
                      href="https://www.linkedin.com/in/addis-ababa-jobs-3b213a335/"
                      target="_blank"
                      ><img
                        height="20"
                        width="20"
                        contain
                        src="../assets/in.png"
                      />
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card>
            </a>
          </v-flex>
        </v-layout>
      </div>

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="postJobDialog"
        fullscreen
        persistent
      >
        <v-card style="background-color: #000000" class="white--text">
          <v-card-title>
            {{ $store.state.language == "English" ? "Post a Job" : "ሥራ ይለጥፉ" }}
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              dark
              color="pink"
              text
              @click="postJobDialog = false"
            >
              {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-15">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-layout row wrap class="pa-0 mt-1 pr-1 pl-1">
                <v-flex xs12 class="text-center white--text ma-1">
                  <h4>
                    {{
                      $store.state.language == "English"
                        ? " Please read carefully and complete all the required fields."
                        : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያጠናቅቁ።"
                    }}
                  </h4>
                </v-flex>
                <v-flex xs12 md5 class="mt-2">
                  <!-- <span class="white--text ml-2">{{
                  $store.state.language == "English"
                    ? "Telegram Id"
                    : "የቴሌግራም ቁጥር"
                }}</span>

                <input
                  required
                  :rules="[(v) => !!v || 'title is required']"
                  v-model="tgid"
                  class="pa-2 pl-3 mb-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Telegram Id'
                      : 'የቴሌግራም ቁጥር'
                  "
                />
                <br /> -->
                  <span class="white--text"
                    >{{
                      $store.state.language == "English"
                        ? "Select a language to view your post on our Telegram channel in that language:"
                        : "ቋንቋ ይምረጡ፤ ልጥፍዎን በመረጡት ቋንቋ ቴሌግራም ቻናላችን ላይ ይታያል:"
                    }}
                    <br
                  /></span>
                  <div
                    class="pl-3 mt-2 mb-2 text-center"
                    style="
                      font-size: 18px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                      padding-top: 1px;
                    "
                  >
                    <v-radio-group
                      v-model="language"
                      row
                      class="white--text text-center"
                    >
                      <v-radio
                        @click="$store.dispatch('changeLanguage', 'English')"
                        base-color="white"
                        value="English"
                        color="white"
                        style="border-color: white; color: white"
                        class="custom-radio"
                      >
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English"
                              ? "English"
                              : "English"
                          }}</span>
                        </template>
                      </v-radio>
                      <v-radio
                        value="አማርኛ"
                        color="white"
                        @click="$store.dispatch('changeLanguage', 'አማርኛ')"
                      >
                        <template v-slot:label>
                          <span class="white--text">{{
                            $store.state.language == "English"
                              ? "Amharic"
                              : "አማርኛ"
                          }}</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <input
                    required
                    :rules="[(v) => !!v || 'Company Name is required']"
                    v-model="companyName"
                    class="pa-2 pl-3"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Company Name*'
                        : 'የኩባንያ ስም*'
                    "
                  />
                  <br />

                  <input
                    :rules="[(v) => !!v || 'Location is required']"
                    required
                    v-model="address"
                    class="pa-2 pl-2 mt-2"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Work Location*'
                        : 'የስራ ቦታ*'
                    "
                  />
                  <br />

                  <div v-for="index in postCounter" :key="index">
                    <input
                      required
                      :rules="[(v) => !!v || 'title is required']"
                      v-model="title[index - 1]"
                      class="pa-2 pl-3 mt-6"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Job Title*'
                          : 'የስራ መጠሪያ*'
                      "
                    />

                    <div
                      class="pl-3 mt-2"
                      style="
                        font-size: 18px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                        padding-top: 2px;
                      "
                    >
                      <v-radio-group
                        v-model="sex[index - 1]"
                        row
                        class="white--text"
                      >
                        <br />
                        <span
                          ><strong>{{
                            $store.state.language == "English" ? "Sex:" : "ጾታ"
                          }}</strong></span
                        >
                        &nbsp;&nbsp;&nbsp;
                        <v-radio
                          base-color="white"
                          value="Male"
                          color="white"
                          style="border-color: white; color: white"
                          class="custom-radio"
                        >
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "Male"
                                : "ወንድ"
                            }}</span>
                          </template>
                        </v-radio>
                        <v-radio value="Female" color="white">
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "Female"
                                : "ሴት"
                            }}</span>
                          </template>
                        </v-radio>

                        <v-radio value="Both" color="white">
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "Both"
                                : "ሁለቱም"
                            }}</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>

                    <input
                      required
                      v-model="vacancies[index - 1]"
                      class="pa-2 pl-3 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'How many employees required (Opt)'
                          : 'ምን ያህል ሰራተኞች ያስፈልጋሉ'
                      "
                    />

                    <input
                      required
                      v-model="salary[index - 1]"
                      class="pa-2 pl-3 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Salary (Optional)'
                          : 'ደሞዝ'
                      "
                    />

                    <input
                      required
                      v-model="experience[index - 1]"
                      class="pa-2 pl-3 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Experience Level (Optional)'
                          : 'የስራ ልምድ'
                      "
                    />

                    <v-flex xs12>
                      <v-menu
                        max-width="100vw"
                        :close-on-content-click="true"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <input
                            v-bind="attrs"
                            v-on="on"
                            @focus="unfocus"
                            required
                            v-model="education[index - 1]"
                            class="pa-2 pl-3 mt-2"
                            style="
                              font-size: 16px;
                              border-radius: 5px;
                              color: white;
                              background-color: #272a2f;
                            "
                            type="text"
                            :placeholder="
                              $store.state.language == 'English'
                                ? 'Education Qualification (Optional)'
                                : 'የትምህርት ደረጃ'
                            "
                          />
                        </template>
                        <v-card
                          style="
                            width: 92vw;
                            max-height: 100vh;
                            overflow-y: auto;
                          "
                        >
                          <v-list :lines="false" density="compact">
                            <v-list-item
                              style="border-bottom: 1px solid grey"
                              v-for="item in educationLists"
                              :key="item"
                              :value="item"
                              color="primary"
                              @click="
                                education[index - 1] = item;
                                education.splice(index - 1, 1, item);
                              "
                            >
                              <v-list-item-title
                                class="pl-5"
                                v-text="item"
                              ></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-flex>

                    <input
                      v-if="
                        education[index - 1] != undefined &&
                        education[index - 1] != '' &&
                        education[index - 1] != 'Not Required'
                      "
                      required
                      v-model="fieldStudy[index - 1]"
                      class="pa-2 pl-3 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Field of Study or Skill (Optional)'
                          : 'የጥናት መስክ ወይም ችሎታ'
                      "
                    />
                    <v-flex xs12>
                      <v-menu
                        max-width="100vw"
                        :close-on-content-click="true"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <input
                            v-bind="attrs"
                            v-on="on"
                            @focus="unfocus"
                            required
                            v-model="jobType[index - 1]"
                            class="pa-2 pl-3 mt-2"
                            style="
                              font-size: 16px;
                              border-radius: 5px;
                              color: white;
                              background-color: #272a2f;
                            "
                            type="text"
                            :placeholder="
                              $store.state.language == 'English'
                                ? 'Job Type (Optional)'
                                : 'የቅጥር አይነት'
                            "
                          />
                        </template>
                        <v-card
                          style="
                            width: 92vw;
                            max-height: 100vh;
                            overflow-y: auto;
                          "
                        >
                          <v-list density="compact">
                            <v-list-item
                              style="border-bottom: 1px solid grey"
                              @click="jobType[index - 1] = item"
                              v-for="(item, k) in jobTypLists"
                              :key="k"
                              :value="item"
                              color="primary"
                            >
                              <v-list-item-title
                                class="pl-5"
                                v-text="item"
                              ></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                    </v-flex>
                  </div>

                  <div class="text-right pt-3">
                    <v-btn
                      right
                      @click="postCounter = postCounter + 1"
                      text
                      small
                      class="text-capitalize blue--text"
                    >
                      {{
                        $store.state.language == "English"
                          ? "Add More"
                          : "ስራ ይጨምሩ"
                      }}
                      <v-icon class="blue--text ml-2 mr-2">add_circle</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="postCounter > 1"
                      @click="
                        education.splice(postCounter - 1, 1);
                        title.splice(postCounter - 1, 1);
                        sex.splice(postCounter - 1, 1);
                        vacancies.splice(postCounter - 1, 1);
                        experience.splice(postCounter - 1, 1);
                        fieldStudy.splice(postCounter - 1, 1);
                        jobType.splice(postCounter - 1, 1);
                        salary.splice(postCounter - 1, 1);
                        postCounter = postCounter - 1;
                      "
                      text
                      small
                      class="text-capitalize ml-1 blue--text text-right"
                    >
                      <v-icon class="pink--text ml-2">cancel</v-icon>
                    </v-btn>
                  </div>

                  <textarea
                    :rules="[(v) => !!v || 'Description is required']"
                    required
                    class="pa-2 pl-3 mt-6"
                    v-model="detailDescription"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Detail Description*'
                        : 'ዝርዝር መግለጫ*'
                    "
                    rows="5"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      background-color: #272a2f;
                    "
                  ></textarea>

                  <v-card
                    style="background-color: #272a2f"
                    elevation="0"
                    light
                    @dragend="OnDragEnter"
                    @dragleave="OnDragLeave"
                    @dragover.prevent
                    @drop="OnDrop"
                    class="text-center"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref="selectImage"
                      style="display: none"
                      @change="OnInputFile"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      ref="changeImage"
                      style="display: none"
                      @change="OnInputFile"
                    />

                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-0 mr-0"
                      :src="image1"
                      height="142"
                      contain
                      v-if="image1 != ''"
                      @click="changeImage()"
                    ></v-img>

                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-5 mr-5 text-center"
                      v-if="image1 == ''"
                      @click="simulateInputfileClicked"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 white--text"
                        >camera_alt</v-icon
                      >
                      <br />

                      <p class="text-center white--text">
                        {{
                          $store.state.language == "English"
                            ? "Company logo (Optional)"
                            : "የኩባንያ አርማ (መተው ይቻላል)"
                        }}
                      </p>
                      <br />
                      <br />
                      <!-- </v-btn> -->
                    </div>
                  </v-card>

                  <!-- <v-card
                    style="background-color: #272a2f"
                    elevation="0"
                    light
                    @dragend="OnDragEnter"
                    @dragleave="OnDragLeave"
                    @dragover.prevent
                    @drop="OnDrop"
                    class="text-center mt-2"
                  >
                    <input
                      type="file"
                      accept="image/*"
                      ref="selectImage2"
                      style="display: none"
                      @change="OnInputFile2"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      ref="changeImage2"
                      style="display: none"
                      @change="OnInputFile2"
                    />

                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-0 mr-0"
                      :src="image12"
                      height="142"
                      contain
                      v-if="image12 != ''"
                      @click="changeImage2()"
                    ></v-img>

                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-5 mr-5 text-center"
                      v-if="image12 == ''"
                      @click="simulateInputfileClicked2"
                    >
                  
                      <v-icon centered class="mt-10 white--text"
                        >camera_alt</v-icon
                      >
                      <br />

                      <p class="text-center white--text">
                        {{
                          $store.state.language == "English"
                            ? "Company logo two*"
                            : "የኩባንያ አርማ ሁለት*"
                        }}
                      </p>
                      <br />
                      <br />
               
                    </div>
                  </v-card> -->
                </v-flex>
                <!-- <v-flex xs12 class="">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-3 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isZerf"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Does it has a sector?"
                        : "ዘርፍ አለው?"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs6>
                <v-switch
                  hide-details
                  class="white--text pl-3 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isDetail"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Read details"
                        : "ዝርዝሩን ያንብቡ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->
                <!-- 
              <v-flex xs6 class="pl-2">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isTheSame"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Same Doc"
                        : "ተመሳሳይ ሰነድ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->

                <v-flex xs6 class="mt-3" v-if="isDirect == 0">
                  <v-switch
                    hide-details
                    class="white--text pl-2 pt-2 pb-2"
                    style="
                      font-size: 15px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    v-model="isRegistration"
                  >
                    <template v-slot:label>
                      <span class="white--text">{{
                        $store.state.language == "English"
                          ? "Registration Mandatory"
                          : "መመዝገብ ግዴታ ነው"
                      }}</span>
                    </template>
                  </v-switch>
                </v-flex>
                <v-flex xs6 class="pl-2 mt-3" v-if="isDirect == 0">
                  <v-switch
                    hide-details
                    class="white--text pl-2 pt-2 pb-2"
                    style="
                      font-size: 15px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    v-model="islinkedin"
                  >
                    <template v-slot:label>
                      <span class="white--text">{{
                        $store.state.language == "English"
                          ? "Portfolio Required"
                          : "ፖርትፎሊዮ ያስፈልጋል"
                      }}</span>
                    </template>
                  </v-switch>
                </v-flex>

                <v-flex xs6 v-if="isDirect == 0">
                  <v-switch
                    hide-details
                    class="white--text pl-2 pt-2 pb-2"
                    style="
                      font-size: 15px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    v-model="isCv"
                  >
                    <template v-slot:label>
                      <span class="white--text">{{
                        $store.state.language == "English"
                          ? "CV Mandatory"
                          : "CV ግዴታ ነው"
                      }}</span>
                    </template>
                  </v-switch>
                </v-flex>
                <v-flex xs6 class="pl-2">
                  <v-switch
                    hide-details
                    class="white--text pl-2 pt-2 pb-2"
                    style="
                      font-size: 15px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    v-model="isDirect"
                  >
                    <template v-slot:label>
                      <span class="white--text">{{
                        $store.state.language == "English"
                          ? "Direct Contact"
                          : "በቀጥታ ያነጋግሩ"
                      }}</span>
                    </template>
                  </v-switch>
                </v-flex>

                <v-flex xs12 v-if="isDirect == 1">
                  <input
                    required
                    v-model="phone"
                    class="pa-2 pl-3 mt-3"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Enter Phone Number or'
                        : 'ስልክ ቁጥር ያስገቡ ወይም'
                    "
                  />
                  <br />
                  <input
                    required
                    v-model="tegcontact"
                    class="pa-2 pl-3 mt-3"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Tg Username @xyz, Web, Whats up, Facebook or'
                        : 'ቴሌግራም ስም  @xyz፣ ድህረገጽ፣ ዋትስአፕ፣ ፌስቡክ ወይም'
                    "
                  />
                  <br />

                  <input
                    required
                    v-model="email"
                    class="pa-2 pl-3 mt-3"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    type="text"
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Enter Email'
                        : 'ኢሜይል ያስገቡ'
                    "
                  />
                  <br />
                </v-flex>

                <v-flex xs6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        @focus="unfocus"
                        :rules="[(v) => !!v || 'Deadline is required']"
                        v-bind="attrs"
                        v-on="on"
                        required
                        v-model="deadline"
                        class="pa-2 pl-3 mt-4"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Deadline Date*'
                            : 'የማብቂያ ቀን*'
                        "
                      />
                    </template>
                    <v-date-picker
                      :min="minDate"
                      v-model="deadline"
                      no-title
                      @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs6 class="pl-2">
                  <input
                    required
                    v-model="age"
                    class="pa-2 pl-3 mt-4"
                    style="
                      font-size: 16px;
                      border-radius: 5px;
                      color: white;
                      background-color: #272a2f;
                    "
                    :placeholder="
                      $store.state.language == 'English'
                        ? 'Age Range (Opt)'
                        : 'የዕድሜ ክልል'
                    "
                  />
                  <br />
                </v-flex>

                <v-flex xs12 class="text-center">
                  <v-btn
                    :loading="submitLoading"
                    @click="submitPost"
                    class="pl-15 pr-15 mt-10 white--text"
                    text
                    style="
                      background-image: linear-gradient(#023679, #023679);
                      border-radius: 10px;
                      font-size: 18px;
                    "
                  >
                    <span class="text-capitalize">{{
                      $store.state.language == "English" ? "Submit" : "ይለጥፉ"
                    }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="applyDialog"
        fullscreen
        persistent
        v-if="applyData.length > 0"
      >
        <v-card
          style="background-color: #000000"
          class="white--text"
          id="watermark"
        >
          <v-card-text class="pa-0 ma-0" id="contentScroll">
            <v-card-title class="black">
              <v-layout row wrap class="pt-1">
                <v-flex xs2 class="text-center pl-2">
                  <img height="50px" contain src="../assets/logo2.png" />
                </v-flex>

                <v-flex
                  xs10
                  style="font-size: 16px; font-weight: bold; padding-top: 2px"
                >
                  <h3 class="white--text">
                    {{
                      $store.state.language == "English"
                        ? "ADDIS ABABA JOBS"
                        : "አዲስ አበባ ስራዎች"
                    }}
                  </h3>
                </v-flex>
              </v-layout>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                style="margin-top: -20px"
                dark
                color="pink"
                text
                @click="
                  applyDialog = false;
                  applyData = [];
                  $store.dispatch('changeParam', '');
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>
            <v-card-text class="pb-6">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex xs12 class="pl-1 mb-3">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? "To apply for the position, please review the details thoroughly and provide all the required information."
                          : "ሥራውን ለማመልከት, እባክዎን ዝርዝሩን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያስገቡ"
                      }}
                    </h4>
                  </v-flex>

                  <v-flex
                    xs12
                    v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ selectedDetail.company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ selectedDetail.company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{ selectedDetail.vacancies.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{ selectedDetail.experience.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{ selectedDetail.education.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i == selectedDetail.title.split('@@cont@@').length - 1
                      "
                    >
                      <span
                        v-if="
                          new Date(selectedDetail.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadline }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadline }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >

                      <v-img
                        v-if="selectedDetail.short_image != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.short_image"
                        contain
                      ></v-img>

                      <span style="white-space: pre-line"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ selectedDetail.detail_description }}</span
                      >

                      <br />

                      <br />

                      <span
                        v-if="
                          applyData[0].apply_link != '' &&
                          new Date(applyData[0].deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                            Date.now()
                        "
                      >
                        <span
                          style="white-space: pre-line mt-1"
                          v-if="
                            (applyData[0].sex.includes($store.state.user.sex) &&
                              $store.state.user.sex != '') ||
                            applyData[0].sex.includes('Both') ||
                            applyData[0].sex.includes('undefined')
                          "
                          ><strong
                            >{{
                              $store.state.language == "English"
                                ? "How To Apply"
                                : "እንዴት ማመልከት ይቻላል?"
                            }}:
                          </strong>
                          {{
                            $store.state.language == "English"
                              ? "Contact the employer using the given information bellow."
                              : "ከዚህ በታች ያለውን መረጃ በመጠቀም ቀጣሪውን ያነጋግሩ።"
                          }}
                          <br />
                        </span>

                        <span
                          style="white-space: pre-line"
                          v-if="
                            (applyData[0].sex.includes($store.state.user.sex) &&
                              $store.state.user.sex != '') ||
                            applyData[0].sex.includes('Both') ||
                            applyData[0].sex.includes('undefined')
                          "
                          ><strong
                            >{{
                              $store.state.language == "English"
                                ? "Apply through"
                                : "ለማመልከት"
                            }}:
                          </strong>

                          <span
                            v-if="
                              applyData[0].apply_link.split('.cont.')[0] != ''
                            "
                          >
                            <a
                              @click="
                                copyNumber(
                                  applyData[0].apply_link.split('.cont.')[0]
                                )
                              "
                            >
                              {{
                                applyData[0].apply_link.split(".cont.")[0] +
                                " " +
                                contactCopyMessage
                              }}</a
                            >
                            <br />
                          </span>

                          <span
                            v-if="
                              applyData[0].apply_link.split('.cont.')[1] != ''
                            "
                          >
                            <a
                              v-if="
                                applyData[0].apply_link
                                  .split('.cont.')[1]
                                  .startsWith('@')
                              "
                              :href="`https://t.me/${applyData[0].apply_link
                                .split('.cont.')[1]
                                .slice(1)}`"
                              target="_blank"
                              >{{
                                applyData[0].apply_link.split(".cont.")[1]
                              }}</a
                            >

                            <a
                              target="_blank"
                              v-else
                              :href="applyData[0].apply_link.split('.cont.')[1]"
                              >{{
                                applyData[0].apply_link.split(".cont.")[1]
                              }}</a
                            >
                            <br />
                          </span>

                          <span
                            v-if="
                              applyData[0].apply_link.split('.cont.')[2] != ''
                            "
                          >
                            <a>
                              {{
                                applyData[0].apply_link.split(".cont.")[2]
                              }}</a
                            >
                            <br />
                          </span>
                        </span>

                        <span style="white-space: pre-line" v-else
                          ><span class="white--text mr-1">{{
                            $store.state.language == "English"
                              ? "This job has a gender preference, so you cannot apply"
                              : "ይህ ሥራ የጾታ ምርጫ አለው፣ ስለዚህ ማመልከት አይችሉም።"
                          }}</span>
                          <a
                            class="white--text"
                            @click="
                              $store.dispatch('changeeditProfileDialog', true)
                            "
                          >
                            <span class="white--text"
                              >{{
                                $store.state.language == "English"
                                  ? ", If you haven't registered, please"
                                  : ", ወይም እስካሁን ካልተመዘገቡ እባክዎትን"
                              }}
                            </span>
                            <span class="orange--text text--darken-3">
                              {{
                                $store.state.language == "English"
                                  ? " Complete Registration"
                                  : "ምዝገባ ያጠናቅቁ"
                              }}</span
                            >
                          </a>
                        </span>
                      </span>

                      <div
                        v-if="
                          new Date(applyData[0].deadline_date).getTime() +
                            24 * 60 * 60 * 1000 <
                          Date.now()
                        "
                        class="text-center"
                      >
                        <div>
                          <v-btn
                            center
                            class="red white--text pl-7 pr-7"
                            text
                            style="font-size: 18px"
                          >
                            <span class="text-capitalize">{{
                              $store.state.language == "English"
                                ? "Deadline Passed"
                                : "ቀነ ገደብ አልፏል"
                            }}</span>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>

            <v-card-text
              v-if="applyClicked"
              class="pa-0 ma-0"
              style="
                color: white;
                background-color: #000000;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
              "
            >
              <img
                style="margin-top: -45px"
                width="100%"
                cover
                src="../assets/light2.png"
              />
            </v-card-text>

            <v-card-text
              v-if="applyClicked"
              style="color: white; background-color: #000000"
              class="pl-3 pr-3 pb-15 pt-0 mt-0"
            >
              <div
                class="pb-15"
                v-if="
                  applyClicked &&
                  applyData[0].isregistered == 1 &&
                  $store.state.user.first_name == ''
                "
                style="margin-top: -14px"
              >
                <span
                  >{{
                    $store.state.language == "English"
                      ? "Your registration is not complete. Please"
                      : "ምዝገባዎ አልተጠናቀቀም።  እባክዎትን"
                  }}
                  <a
                    @click="$store.dispatch('changeeditProfileDialog', true)"
                    class="blue--text"
                    >{{
                      $store.state.language == "English"
                        ? "Update"
                        : "ፕሮፋይሎትን ያስተካክሉ"
                    }}</a
                  >
                  {{
                    $store.state.language == "English"
                      ? "it with the necessary information to apply for this job."
                      : ""
                  }}
                  <br />
                  <br />
                </span>
              </div>
              <div
                style="margin-top: -14px"
                v-if="
                  applyClicked &&
                  ($store.state.user.first_name != '' ||
                    applyData[0].isregistered == 0)
                "
              >
                <span>{{
                  $store.state.language == "English"
                    ? "Please write a short note outlining your qualifications and explaining why you are a good fit for this position. This information will be taken into account when the employer reviews your application."
                    : "እባክዎትን እርስዎን የሚገልጽ አጭር ማስታወሻ ይጻፉ።"
                }}</span>

                <textarea
                  :rules="[(v) => !!v || 'Cover Letter is required']"
                  required
                  class="pa-2 pl-3 mt-3"
                  v-model="coverLetter"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Write a short cover letter'
                      : 'የሽፋን ደብዳቤ ይጻፉ'
                  "
                  rows="5"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    background-color: #272a2f;
                  "
                ></textarea>

                <input
                  v-if="applyData[0].iszerf"
                  v-model="sector"
                  class="pa-2 pl-3 mt-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Which sector do you prefer?'
                      : 'ዘርፍ ያስገቡ'
                  "
                />
                <br />
                <input
                  v-if="applyData[0].islinkedin && $store.state.user.link == ''"
                  v-model="linkedin"
                  class="pa-2 pl-3 mt-3"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Include your Portfolio, LinkedIn or GitHub.'
                      : 'የ LinkedIn, Portfolio ወይም GitHub መገለጫህን አስገባ።'
                  "
                />

                <v-card
                  v-if="applyData[0].iscv"
                  style="background-color: #272a2f"
                  elevation="0"
                  light
                  @dragend="OnDragEnter"
                  @dragleave="OnDragLeave"
                  @dragover.prevent
                  @drop="OnDrop"
                  class="text-center mt-3 pb-2"
                >
                  <input
                    type="file"
                    accept=".pdf"
                    ref="selectCv"
                    style="display: none"
                    @change="handleFileChange"
                  />

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-5 mr-5 text-center"
                    @click="simulateInputfileCV"
                  >
                    <!-- <v-btn centered outlined large fab class="ma-4"> -->
                    <v-icon large centered class="mt-4 white--text"
                      >file_upload</v-icon
                    >
                    <br />

                    <p
                      class="text-center white--text mt-1"
                      v-if="pdfUploadStatus == ''"
                    >
                      <span v-if="$store.state.user.cv == ''">{{
                        $store.state.language == "English"
                          ? "Attach Your CV"
                          : "CV ያያይዙ"
                      }}</span>

                      <span v-else>{{
                        $store.state.language == "English"
                          ? "Your CV already attached. Tap here to change"
                          : "ሲቪዎ አስቀድሞ ተያይዟል \n ለመቀየር ነካ ያድርጉ"
                      }}</span>
                    </p>

                    <p class="text-center white--text mt-1" v-else>
                      {{ pdfUploadStatus }}
                    </p>
                  </div>
                </v-card>
              </div>

              <div
                v-if="
                  applyClicked &&
                  ($store.state.user.first_name != '' ||
                    applyData[0].isregistered == 0)
                "
                class="text-center"
              >
                <v-btn
                  id="'zoomme"
                  :class="{ zoom: isZoomed }"
                  v-if="!isAlreadyApplied"
                  :loading="submitLoading"
                  @click="applyJob"
                  center
                  class="pl-10 pr-10 mt-5 pt-7 pb-7 white--text"
                  text
                  style="
                    background-image: linear-gradient(#023679, #023679);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize"
                    >{{
                      $store.state.language == "English"
                        ? "Complete Application"
                        : "ማመልከቻዎትን ይላኩ"
                    }}
                  </span>
                </v-btn>

                <v-btn
                  v-else
                  center
                  class="pl-10 pr-10 mt-5 white--text"
                  text
                  style="
                    background-image: linear-gradient(#023679, #023679);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                >
                  <span class="text-capitalize"
                    >{{
                      $store.state.language == "English"
                        ? "You Already Applied"
                        : "አስቀድመው አመልክተዋል"
                    }}
                  </span>
                </v-btn>
              </div>
            </v-card-text>
            <div id="additional-content"></div>
            <div v-if="!applyClicked"><br /><br /><br /></div>
          </v-card-text>

          <v-footer
            style="border-top: 1px solid #000000"
            app
            class="pl-0 pb-4 pt-4 text-center"
            id="footer"
            v-if="
              !applyClicked &&
              applyData[0].apply_link == '' &&
              new Date(applyData[0].deadline_date).getTime() +
                24 * 60 * 60 * 1000 >
                Date.now()
            "
          >
            <!-- <v-layout row wrap class="text-center">
              <v-flex xs12>
                <v-btn class="text-capitalize" text style="font-size: 18px">
                  <span class="text-capitalize white--text">
                    {{
                      $store.state.language == "English" ? "Apply" : "ያመልክቱ"
                    }}</span
                  >
                </v-btn>
              </v-flex>
            </v-layout> -->

            <v-layout
              row
              wrap
              v-if="
                applyData[0].apply_link == '' &&
                new Date(applyData[0].deadline_date).getTime() +
                  24 * 60 * 60 * 1000 >
                  Date.now()
              "
            >
              <v-flex
                xs12
                class="text-center"
                v-if="
                  (applyData[0].sex.includes($store.state.user.sex) &&
                    $store.state.user.sex != '') ||
                  applyData[0].sex.includes('Both') ||
                  applyData[0].sex.includes('undefined')
                "
              >
                <v-btn
                  v-if="!isAlreadyApplied"
                  @click="
                    startZoom();
                    applyClicked = true;
                  "
                  href="#additional-content"
                  center
                  class="white--text"
                  text
                  style="font-size: 18px"
                >
                  <span class="text-capitalize">
                    {{
                      $store.state.language == "English" ? "Apply" : "ያመልክቱ"
                    }}</span
                  >
                </v-btn>

                <v-btn
                  v-else
                  center
                  class="orange--text text--darken-3"
                  text
                  style="font-size: 18px"
                >
                  <span class="text-capitalize">{{
                    $store.state.language == "English"
                      ? "You Already Applied"
                      : "አስቀድመው አመልክተዋል"
                  }}</span>
                </v-btn>
              </v-flex>

              <v-flex xs12 v-else class="pl-4 pr-2 white--text">
                <span style="white-space: pre-line"
                  >{{
                    $store.state.language == "English"
                      ? "This job has a gender preference, so you cannot apply"
                      : "ይህ ሥራ የጾታ ምርጫ አለው፣ ስለዚህ ማመልከት አይችሉም።"
                  }}
                  <a
                    @click="$store.dispatch('changeeditProfileDialog', true)"
                    class="white--text"
                  >
                    <span clas="white--text"
                      >{{
                        $store.state.language == "English"
                          ? ", If you haven't registered, please"
                          : "እስካሁን ካልተመዘገቡ እባክዎትን"
                      }}
                    </span>
                    <span class="orange--text text--darken-3">{{
                      $store.state.language == "English"
                        ? " Complete Registration"
                        : " ምዝገባን ያጠናቅቁ"
                    }}</span>
                  </a>
                </span>
              </v-flex>
            </v-layout>

            <!-- <div
              v-if="
                new Date(applyData[0].deadline_date).getTime() +
                  24 * 60 * 60 * 1000 <
                Date.now()
              "
              class="text-center"
            >
              <div>
                <v-btn
                  center
                  class="orange--text text--darken-3"
                  text
                  style="font-size: 18px"
                >
                  <span class="text-capitalize">{{
                    $store.state.language == "English"
                      ? "Deadline Passed"
                      : "ቀነ ገደብ አልፏል"
                  }}</span>
                </v-btn>
              </div>
            </div> -->
          </v-footer>
        </v-card>
      </v-dialog>

      <v-dialog
        transition="dialog-bottom-transition"
        v-model="unpaidDialog"
        fullscreen
        persistent
        v-if="unpaidPost.length > 0"
      >
        <v-card style="background-color: #000000" class="white--text">
          <v-card-title>
            {{
              $store.state.language == "English"
                ? "Unpaid job post"
                : "ያልተከፈለ የስራ ልጥፍ"
            }}
            <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              dark
              color="pink"
              text
              @click="
                unpaidDialog = false;
                unpaidPost = [];
              "
            >
              {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
            </v-btn>
          </v-card-title>

          <v-card-text class="pb-6 white--text">
            <v-layout row wrap>
              <v-flex xs12 class="pl-1 mb-3 mt-6 mb-3">
                <p>
                  {{
                    $store.state.language == "English"
                      ? "You have an unpaid job vacancy. Please make the payment, and we will post your job vacancy on our Telegram channel."
                      : "ያልተከፈለ የስራ ልጥፍ አሎት፤ እባክዎ ክፍያውን ይክፈሉ እና ክፍት የስራ ቦታዎን በቴሌግራም ቻናላችን ላይ እንለጥፍሎታለን።"
                  }}
                </p>
              </v-flex>
              <v-flex xs12>
                <p>
                  <strong
                    >{{
                      $store.state.language == "English"
                        ? "Job Title"
                        : "የስራው መጠርያ"
                    }}:</strong
                  >
                  {{ unpaidPost[0].title.replace("@@cont@@", ",") }}
                </p>
              </v-flex>
              <v-flex
                xs12
                class="ma-2 pa-4 pb-6 mb-6 mt-1 mr-3 ml-1 pl-7 grey--text"
                style="
                  border: 1px solid;
                  border-color: #383838;
                  border-radius: 5px;
                  font-size: 14px;
                "
              >
                <p class="text-center pr-7 pb-1">
                  <Strong>
                    {{
                      $store.state.language == "English"
                        ? "Payment Methods"
                        : "የመክፈያ ዘዴዎች"
                    }}</Strong
                  >
                </p>
                <v-layout
                  row
                  wrap
                  v-for="(account, i) in allAcounts"
                  :key="account.account"
                  class="mt-5"
                >
                  <v-flex xs2 class="text-right pr-3">
                    <h4>
                      <img
                        v-if="i != 2"
                        contain
                        height="25px"
                        width="25px"
                        :src="require(`../assets/${account.logo}`)"
                        alt="logo"
                      />
                      <img
                        v-else
                        contain
                        height="20px"
                        width="20px"
                        :src="require(`../assets/${account.logo}`)"
                        alt="logo"
                      />
                    </h4>
                  </v-flex>
                  <v-flex xs10>
                    <h4 v-if="i == 2">
                      <span> {{ getAccount(account.account) }} <br /></span>
                    </h4>

                    <h4 v-else class="pt-1">
                      <span> {{ getAccount(account.account) }} <br /></span>
                    </h4>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                class="ma-2 pa-4 pt-0 pl-1 mb-1 mr-1 ml-1 grey--text"
              >
                <h3 class="mb-2">
                  {{
                    $store.state.language == "English" ? "Steps" : "እንዴት ልክፈል?"
                  }}
                </h3>
                <span>
                  1.
                  {{
                    $store.state.language == "English"
                      ? "Select a payment method from the options above"
                      : "ከላይ ካሉት አማራጮች የመክፈያ ዘዴን ይምረጡ"
                  }}
                  <br />
                  2.
                  {{
                    $store.state.language == "English"
                      ? "Pay 200 birr using your preferred payment option"
                      : "የመረጡትን የክፍያ አማራጭ በመጠቀም 200 ብር ይክፈሉ"
                  }}
                  <br />
                  3.
                  {{
                    $store.state.language == "English"
                      ? "Submit a screenshot using the form provided below."
                      : "ከዚህ በታች የቀረበውን ቅጽ በመጠቀም የከፈሉበትን ደረሰኝ ያስገቡ።"
                  }}
                </span>
              </v-flex>

              <v-flex xs12 class="text-center pb-15 pl-4 pr-5">
                <v-card
                  style="background-color: #272a2f"
                  elevation="0"
                  light
                  @dragend="OnDragEnter"
                  @dragleave="OnDragLeave"
                  @dragover.prevent
                  @drop="OnDrop"
                  class="text-center"
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref="selectpayImage"
                    style="display: none"
                    @change="payOnInputFile"
                  />

                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-0 mr-0"
                    :src="image1pay"
                    height="142"
                    contain
                    v-if="image1pay != ''"
                    @click="changepayImage()"
                  ></v-img>

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-5 mr-5 text-center"
                    v-if="image1pay == ''"
                    @click="simulatepayInputfileClicked"
                  >
                    <!-- <v-btn centered outlined large fab class="ma-4"> -->
                    <v-icon centered class="mt-10 white--text"
                      >camera_alt</v-icon
                    >
                    <br />

                    <p class="text-center white--text">
                      {{
                        $store.state.language == "English"
                          ? "Upload your payment invoice screenshot"
                          : "ክፍያ የፈጸሙበትን ደረሰኝ ፎቶ ያስገቡ"
                      }}
                    </p>
                    <br />
                    <br />
                    <!-- </v-btn> -->
                  </div>
                </v-card>
                <span class="text-white" v-if="pdfUploadStatus != ''">{{
                  pdfUploadStatus
                }}</span>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>

      <AlertDialog
        :message="alertMessage"
        :show="showAlertDialog"
        @close="showAlertDialog = false"
      />
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

import profileHeader from "./profileHeader.vue";
import AlertDialog from "./AlertDialog.vue";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
    profileHeader,
    AlertDialog,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,
      dateDialog: false,

      isZoomed: false,
      zoomInterval: null,

      showAlertDialog: false,
      alertMessage: "",

      menu: false,
      postCounter: 1,

      postJobDialog: false,
      valid: true,
      submitLoading: false,

      title: [],
      vacancies: [],
      sex: [],
      salary: [],
      experience: [],
      education: [],
      jobType: [],
      fieldStudy: [],
      tegcontact: "",
      phone: "",
      email: "",
      isDetail: 1,
      isDirect: 0,
      isRegistration: 0,
      isCv: 0,
      islinkedin: 0,
      isZerf: 0,
      isTheSame: 1,
      deadline: "",
      age: "",
      tgid: "",
      companyName: "",

      shortDescription: "",
      detailDescription: "",
      address: "",
      applyLink: "",
      language: "",

      image1: "",
      image1pay: "",
      filespay: [],
      imageUrl1: "",

      image12: "",
      imageUrl12: "",
      files: [],

      files2: [],
      allPosts: [],
      activeJobs: 0,
      pendingJobs: 0,
      rejectedJobs: 0,
      expiredJobs: 0,
      applyData: [],
      selectedDetail: "",
      applyDialog: false,
      applyClicked: false,
      coverLetter: "",
      sector: "",
      linkedin: "",
      pdf: null,
      cvUrl: "",
      indexoFChecked: -1,

      pdfUploadStatus: false,

      allApplications: [],
      contactPhone: "0918440700",
      contactCopyMessage: "",
      acceptedApplications: 0,
      rejectedApplications: 0,
      isAlreadyApplied: false,
      editProfileDialog: false,
      domainUrl: "https://kinu.abimabusiness.com",
      channelName: "https://t.me/addis_ababa_jobs",
      jobTypLists: [
        "Full-Time",
        "Part-Time",
        "Remote",
        "Contract",
        "Temporary",
        "Freelancer",
        "Internship",
        "Volunteer",
      ],

      educationLists: [
        "8+ (Above 8th-grade)",
        "10+ (Above 10th-grade)",
        "12+ (Completed 12th-grade)",
        "TVET",
        "Diploma",
        "Bachelor's Degree",
        "Master's Degree",
        "Not Required",
      ],
      minDate: new Date().toISOString().substr(0, 10),
      unpaidPost: [],
      unpaidDialog: false,
      allAcounts: [
        {
          account: "4342453a2031303030313834303130323532",
          logo: "cbe.png",
        },
        {
          account: "42616e6b206f66204162797373696e69613a20313136393731323637",
          logo: "boa.png",
        },
        // {
        //   account: "41776173682042616e6b3a203635333732373839",
        //   logo: "awash.png",
        // },
        {
          account: "54656c65626972723a2030393132343733393237",
          logo: "telebirr.png",
        },
      ],
    };
  },

  computed: {
    getDeadline() {
      return this.formatDate(this.applyData[0].deadline_date);
    },

    getEducation() {
      return this.education[this.indexoFChecked];
    },
  },

  methods: {
    startZoom() {
      this.isZoomed = true;
      this.zoomInterval = setInterval(() => {
        this.isZoomed = !this.isZoomed;
      }, 500); // Set interval for slower toggling
    },
    stopZoom() {
      this.isZoomed = false;
      clearInterval(this.zoomInterval);
    },
    callTo(item, index) {
      this.education[index] = item.toString();
      // this.indexoFChecked = index;
    },

    showAlert(message) {
      this.alertMessage = message;
      this.showAlertDialog = true;
    },

    getAccount(hex) {
      let str = "";
      for (let i = 0; i < hex.length; i += 2) {
        const hexValue = hex.substr(i, 2);

        const charCode = parseInt(hexValue, 16);

        str += String.fromCharCode(charCode);
      }
      return str;
    },

    getCustomerService(link) {
      window.location.href = "https://t.me/" + link;
    },
    shareChannel() {
      const url = this.channelName;
      const text = "Join our job posting telegram channel";
      // Opens the Telegram interface where the user can select friends or groups to share
      window.open(
        `https://t.me/share/url?url=${encodeURIComponent(
          url
        )}&text=${encodeURIComponent(text)}`,
        "_blank"
      );
    },

    async getUserPic() {
      const botToken = "7433805398:AAG4xsYgRB0rw3UWrS9dMV1cj44xBT7TcWE";
      const userId = this.$store.state.user.tg_id;
      var resultUrl = "";

      await fetch(
        `https://api.telegram.org/bot${botToken}/getUserProfilePhotos?user_id=${userId}`
      )
        .then((response) => response.json())
        .then(async (data) => {
          if (data.ok && data.result.total_count > 0) {
            // You can access the file_id of the first profile picture
            const fileId = data.result.photos[0][0].file_id;

            // Fetch the file URL using the file_id
            await fetch(
              `https://api.telegram.org/bot${botToken}/getFile?file_id=${fileId}`
            )
              .then((response) => response.json())
              .then((fileData) => {
                const filePath = fileData.result.file_path;
                resultUrl = `https://api.telegram.org/file/bot${botToken}/${filePath}`;
              });
          }
        })
        .catch((err) => {});

      return resultUrl;
    },

    copyNumber(contactPhone) {
      navigator.clipboard.writeText(contactPhone).then(() => {
        this.contactCopyMessage =
          this.$store.state.language == "English"
            ? "Phone number copied"
            : "ስልክ ቁጥር ተቀድቷል።";

        setTimeout(() => {
          this.contactCopyMessage = "";
        }, 3000);
      });
    },
    async handleFileChange(event) {
      this.pdf = event.target.files[0];

      this.pdfUploadStatus =
        this.$store.state.language == "English" ? "Uploading..." : "በመጫን ላይ...";

      const formData = new FormData();
      formData.append("pdf", this.pdf);
      this.cvUrl = "";
      try {
        const response = await axios.post(
          `${this.domainUrl}/uploadpdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.cvUrl = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        return;
      }

      if (this.cvUrl != "") {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                attachCv(
                  user_id:${parseInt(this.$store.state.user.user_id)}
                  cvurl:"${this.cvUrl}"


                  ){
                 statusMessage
              }

              }
              `,
            },
          });

          if (result.data.data.attachCv[0].statusMessage.toString() == "true") {
            this.getAccountInfo();
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploaded Successfully!."
                : "በተሳካ ሁኔታ ተጭኗል!";
          } else
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploading Error, Please try again"
                : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        } catch (err) {
          this.pdfUploadStatus =
            this.$store.state.language == "English"
              ? "Uploading Error, Please try again"
              : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        }
      } else
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },

    unfocus(event) {
      event.target.blur();
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;

      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    async payOnInputFile(e) {
      const files = e.target.files;
      this.filespay = e.target.files;
      this.pdfUploadStatus =
        this.$store.state.language == "English" ? "Uploading..." : "በመጫን ላይ...";

      const formData = new FormData();
      formData.append("image", this.filespay[0]);
      let payUrl = "";
      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        payUrl = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        return;
      }

      if (payUrl != "" && payUrl != undefined) {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
          attachPay(
            post_id:${parseInt(this.unpaidPost[0].post_id)}
            bank_slip:"${payUrl}"
            title:"${this.unpaidPost[0].title}"


            ){
           statusMessage
        }

        }
        `,
            },
          });

          if (
            result.data.data.attachPay[0].statusMessage.toString() == "true"
          ) {
            this.getAllData();
            this.showAlert(
              this.$store.state.language == "English"
                ? "Payment uploaded successfully!. Please wait until it is approved by Admin."
                : "በተሳካ ሁኔታ ተልኳል፣ እባክዎ ተቀባይነት እስኪያገኝ ትንሽ ይጠብቁ!"
            );
            this.unpaidDialog = false;
            this.unpaidPost = [];
            this.image1pay = "";
            this.filespay = [];
          } else
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploading Error, Please try again"
                : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        } catch (err) {
          this.pdfUploadStatus =
            this.$store.state.language == "English"
              ? "Uploading Error, Please try again"
              : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        }
      } else
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
      Array.from(files).forEach((file) => this.addpayImage(file));
    },
    simulatepayInputfileClicked() {
      this.$refs.selectpayImage.click();
    },
    addpayImage(file) {
      if (!file.type.match("image.*")) {
        this.showAlert(
          this.$store.state.language == "English"
            ? `${file.name} is not an image`
            : `${file.name} ምስል አይደለም`
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1pay = f.target.result;
      };
      reader.readAsDataURL(file);
    },

    changepayImage() {
      this.$refs.selectpayImage.click();
    },

    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },

    addImage(file) {
      if (!file.type.match("image.*")) {
        this.showAlert(
          this.$store.state.language == "English"
            ? `${file.name} is not an image`
            : `${file.name} ምስል አይደለም`
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },

    changeImage() {
      this.$refs.changeImage.click();
    },

    async uploadImage(fileToUpload, imageNumber) {
      const formData = new FormData();
      formData.append("image", fileToUpload);

      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageNumber == 1)
          this.imageUrl1 = this.domainUrl + response.data.filePath;
        else this.imageUrl12 = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.showAlert("Error uploading image: " + error);
      }
    },

    //image2

    OnInputFile2(e) {
      const files = e.target.files;
      this.files2 = e.target.files;
      Array.from(files).forEach((file) => this.addImage2(file));
    },
    simulateInputfileClicked2() {
      this.$refs.selectImage2.click();
    },

    simulateInputfileCV() {
      this.$refs.selectCv.click();
    },
    addImage2(file) {
      if (!file.type.match("image.*")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        this.image12 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage2() {
      this.$refs.changeImage2.click();
    },
    async uploadImage2() {
      var storageReference = this.$storage.ref(
        "jobimages/image" + Math.random() + ".jpg"
      );
      try {
        await storageReference.put(this.files2[0]);
        this.imageUrl12 = await storageReference.getDownloadURL();
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
    },

    // formatDate(date) {
    //   var d = new Date(date),
    //     month = "" + (d.getMonth() + 1),
    //     day = "" + d.getDate(),
    //     year = d.getFullYear();

    //   if (month.length < 2) month = "0" + month;
    //   if (day.length < 2) day = "0" + day;

    //   return [year, month, day].join("-");
    // },

    async getAllData() {
      this.pageLoading = true;
      try {
        let result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllPosts(tg_id:"${this.$store.state.user.tg_id}"){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                company_name
                vacancies
                salary
                experience
                education
                job_type
                is_update_request
                bank_slip
           }
      }
      `,
          },
        });

        this.allPosts = result.data.data.getAllPosts;
        this.expiredJobs = 0;
        this.rejectedJobs = 0;
        this.pendingJobs = 0;
        this.activeJobs = 0;
        this.unpaidPost = [];
        for (let i = 0; i < this.allPosts.length; i++) {
          if (
            new Date(this.allPosts[i].deadline_date).getTime() <
              Date.now() - 24 * 60 * 60 * 1000 &&
            this.allPosts[i].isrejected == 0
          ) {
            this.expiredJobs++;
          }

          if (this.allPosts[i].isrejected == 1) {
            this.rejectedJobs++;
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 0
          ) {
            this.pendingJobs++;
            if (this.allPosts[i].bank_slip == "") {
              this.unpaidPost.push(this.allPosts[i]);
            }
          }

          if (
            new Date(this.allPosts[i].deadline_date).getTime() +
              24 * 60 * 60 * 1000 >
              Date.now() &&
            this.allPosts[i].isrejected == 0 &&
            this.allPosts[i].isapproved == 1
          ) {
            this.activeJobs++;
          }
        }

        if (this.unpaidPost) {
          this.unpaidDialog = true;
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.$store.dispatch("userHeader", this.$store.state.user);
      // if (window.Telegram.WebApp) {
      //   await window.Telegram.WebApp.setHeaderColor("#000000");
      // }

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllApplications(user_id:${parseInt(this.$store.state.user.user_id)}){
                       application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted


           }
      }
      `,
          },
        });

        this.allApplications = resultapp.data.data.getAllApplications;
        this.acceptedApplications = 0;
        this.rejectedApplications = 0;

        for (let k = 0; k < this.allApplications.length; k++) {
          if (this.applyData.length > 0)
            if (this.applyData[0].post_id == this.allApplications[k].post_id) {
              this.isAlreadyApplied = true;
            }

          if (this.allApplications[k].isaccepted == 1) {
            this.acceptedApplications = this.acceptedApplications + 1;
          } else if (this.allApplications[k].isaccepted == 2) {
            this.rejectedApplications = this.rejectedApplications + 1;
          }
        }
      } catch (err) {}

      this.pageLoading = false;
    },

    async getApplyData() {
      this.pageLoading = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getJobById(post_id: ${parseInt(this.$store.state.paramId.toString())}){
               post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                company_name
   vacancies
      salary
  experience
   education
   job_type


           }
      }
      `,
          },
        });

        this.applyData = result.data.data.getJobById;
        this.selectedDetail = this.applyData[0];

        this.applyDialog = true;
      } catch (err) {}

      this.pageLoading = false;
    },

    async submitPost() {
      this.imageUrl1 = "";
      this.imageUrl12 = "";

      let isTitleEmpty = false;

      let tempjobTitle = "";
      let tempSex = "";
      let tempVacancies = "";
      let tempExperience = "";
      let tempEducation = "";

      let tempJobType = "";
      let tempSalary = "";

      for (let i = 0; i < this.postCounter; i++) {
        if (this.title[i] == "" || this.title[i] == undefined) {
          isTitleEmpty = true;
        }
        if (tempjobTitle == "") {
          tempjobTitle = this.title[i];
        } else {
          tempjobTitle = tempjobTitle + "@@cont@@" + this.title[i];
        }

        if (tempSex == "") {
          tempSex = this.sex[i];
        } else {
          tempSex = tempSex + "@@cont@@" + this.sex[i];
        }

        if (tempVacancies == "") {
          tempVacancies = this.vacancies[i];
        } else {
          tempVacancies = tempVacancies + "@@cont@@" + this.vacancies[i];
        }

        if (tempExperience == "") {
          tempExperience = this.experience[i];
        } else {
          tempExperience = tempExperience + "@@cont@@" + this.experience[i];
        }

        if (tempEducation == "") {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined
          ) {
            if (this.fieldStudy[i] != "" && this.fieldStudy[i] != undefined) {
              tempEducation = this.education[i] + ", in " + this.fieldStudy[i];
            } else {
              tempEducation = this.education[i];
            }
          } else {
            tempEducation = this.education[i];
          }
        } else {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined
          ) {
            if (this.fieldStudy[i] != "" && this.fieldStudy[i] != undefined) {
              tempEducation =
                tempEducation +
                "@@cont@@" +
                this.education[i] +
                ", in " +
                this.fieldStudy[i];
            } else {
              tempEducation = tempEducation + "@@cont@@" + this.education[i];
            }
          } else {
            tempEducation = tempEducation + "@@cont@@" + this.education[i];
          }
        }

        if (tempJobType == "") {
          tempJobType = this.jobType[i];
        } else {
          tempJobType = tempJobType + "@@cont@@" + this.jobType[i];
        }

        if (tempSalary == "") {
          tempSalary = this.salary[i];
        } else {
          tempSalary = tempSalary + "@@cont@@" + this.salary[i];
        }
      }
      if (isTitleEmpty) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter title"
            : "እባክዎ የስራውን ርእስ ያስገቡ"
        );
        return;
      }

      // if (this.files.length < 1) {
      //   this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo one"
      //       : "እባክዎ የካምፓኒ አርማ አንድ ያስገቡ"
      //   );
      //   return;
      // }

      // if (this.files2.length < 1) {
      //   this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo two"
      //       : "እባክዎ የካምፓኒ አርማ ሁለት ያስገቡ"
      //   );
      //   return;
      // }

      if (this.companyName == "") {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company name"
            : "እባክዎ የኩባንያውን ስም ያስገቡ"
        );
        return;
      }

      if (this.detailDescription == "") {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter detail description"
            : "እባክዎ የስራዉን ዝርዝር ያስገቡ"
        );
        return;
      }

      if (this.address == "") {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company address"
            : "እባክዎ የኩባንያውን አድራሻ ያስገቡ"
        );
        return;
      }

      if (this.deadline == "") {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter deadline"
            : "እባክዎ ቀኑ የሚያበቃበትን ያስገቡ"
        );
        return;
      }

      if (this.isDirect == 1) {
        if (this.tegcontact == "" && this.phone == "" && this.email == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "You chose direct contact,  but you haven’t provided your contact information. Please include your contact information from the options"
              : "ቀጥተኛ ግንኙነትን መርጠዋል፣ ነገር ግን የመገናኛ መረጃዎን አላቀረቡም። እባክዎን የሚገናኙበትን መረጃ ያካትቱ"
          );
          return;
        }

        this.applyLink = "";
        this.applyLink =
          this.phone + ".cont." + this.tegcontact + ".cont." + this.email;
      }

      let tempZerf = this.title.length > 1 ? 1 : 0;

      this.submitLoading = true;

      // if (this.education != "" && this.education != "Not Required") {
      //   if (this.fieldStudy != "")
      //     this.education = this.education + " in " + this.fieldStudy;
      // }

      try {
        if (
          this.files[0] != "" &&
          this.files[0] != undefined &&
          this.files.length > 0
        ) {
          await this.uploadImage(this.files[0], 1);
        }

        // if (this.files2[0] != "") {
        //   await this.uploadImage(this.files2[0], 2);
        // }

        // if (this.imageUrl1 == "" || this.imageUrl12 == "") {
        //   this.showAlert(
        //     this.$store.state.language == "English"
        //       ? "The logo did not upload. Please try again."
        //       : "አርማው አልተጫነም። እባክዎ እንደገና ይሞክሩ።"
        //   );
        //   this.submitLoading = false;
        //   return;
        // }
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        axios.defaults.headers.get["Accept"] = "application/json;charset=UTF-8";
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,
          data: {
            query: `
                 mutation {
                  addPost(
                   tg_id:"${parseInt(this.tgid)}"
                   title:"${tempjobTitle}"
                    short_description:"${this.shortDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    short_image:"${this.imageUrl1}"

                    detail_description:"${this.detailDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    detail_image:"${this.imageUrl12}"
                    company_address:"${this.address}"
                    deadline_date:"${this.deadline}"

                    application_format:"${""}"
                    sex:"${tempSex}"
                    isdetail:${this.isDetail + 0}
                    isdirect: ${this.isDirect + 0}
                    apply_link:"${this.applyLink}"
                    isregistered: ${this.isRegistration + 0}

                    iscv: ${this.isCv + 0}
                    islinkedin: ${this.islinkedin + 0}
                    iszerf: ${tempZerf + 0}
                    isthesame: ${this.isTheSame + 0}
                    age_above_bellow:"${this.age}"
                    language: "${this.$store.state.language}"

                    company_name:"${this.companyName}"
                 vacancies:"${tempVacancies}"
                  salary:"${tempSalary}"
                    experience:"${tempExperience}"
                    education:"${tempEducation}"
                    job_type:"${tempJobType}"

                    ){
                        statusMessage
                    }
                    }
              `,
          },
        });
        if (result.data.data.addPost[0].statusMessage.toString() == "true") {
          // this.showAlert(
          //   this.$store.state.language == "English"
          //     ? "Your Job is submited successfully"
          //     : "ሥራዎ በተሳካ ሁኔታ ተመዝግቧል"
          // );
          await this.getAllData();
          this.postJobDialog = false;
        } else {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Something went wrong, please try again"
              : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.submitLoading = false;
    },

    async applyJob() {
      if (this.applyData[0].isregistered == 1) {
        if (
          this.$store.state.user.first_name == "" ||
          this.$store.state.user.last_name == "" ||
          this.$store.state.user.phone_number == "" ||
          this.$store.state.user.phone_number == ""
        ) {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Your registration is not complete. Please complete it before moving on."
              : "ምዝገባዎ አልተጠናቀቀም። እባክዎ ከመቀጠልዎ በፊት ያጠናቅቁት።"
          );
          return;
        }
      }
      if (this.applyData[0].iscv == 1) {
        if (this.$store.state.user.cv == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "A CV is required. Please attach your CV or resume."
              : "ሲቪ ያስፈልጋል። እባኮትን CV ያያይዙ"
          );
          return;
        }
      }

      if (this.applyData[0].islinkedin == 1) {
        if (this.linkedin == "" && this.$store.state.user.link == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "A portfolio is required. Please attach your portfolio, Linkedin or GitHub."
              : "የLinkedIn፣ ፖርትፎሊዮ ወይም GitHub ሊንክ ያስፈልጋል"
          );
          return;
        }
      }

      if (this.applyData[0].iszerf == 1) {
        if (this.sector == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Sector is required, Please enter sector"
              : "ዘርፍ ያስፈልጋል፣ እባኮትን ዘርፍ ያስገቡ"
          );
          return;
        }
      }

      if (this.coverLetter.length < 40) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Cover letter must be greater than 40 characters"
            : "የሽፋን ደብዳቤ ከ 40 ፊደሎች በላይ መሆን አለበት"
        );
        return;
      }

      if (1 == 1) {
        this.submitLoading = true;
        this.stopZoom();
        try {
          axios.defaults.headers.post["Content-Type"] =
            "application/json;charset=UTF-8";
          axios.defaults.headers.get["Accept"] =
            "application/json;charset=UTF-8";
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `
                 mutation {
                  applyJob(
                   post_id:${this.applyData[0].post_id}
                   tg_id:"${this.applyData[0].tg_id}"
                    user_id:${parseInt(this.$store.state.user.user_id)}
                    cover_letter:"${this.coverLetter
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    sector:"${this.sector}"
                    linkedin:"${this.linkedin}"

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.applyJob[0].statusMessage.toString() == "true") {
            this.showAlert(
              this.$store.state.language == "English"
                ? "Application Submitted Successfully"
                : "ማመልከቻው በተሳካ ሁኔታ ተልኳል።"
            );
            this.applyDialog = false;
            this.applyData = [];
            this.selectedDetail = "";
            this.$store.dispatch("changeParam", "");
            await this.getAllData();
          } else {
            this.showAlert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
          }
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
        this.submitLoading = false;
      } else
        this.showAlert(
          this.$store.state.language == "English"
            ? " Please read carefully and complete all the required fields."
            : "እባክዎን በጥንቃቄ ያንብቡ እና ሁሉንም አስፈላጊ መረጃዎችን ያስገቡ።"
        );
    },

    async getAccountInfo() {
      var profilePictureUrl = await this.getUserPic();

      if (profilePictureUrl == undefined) profilePictureUrl = "";
      try {
        var userLoginResult = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
               userLogin(
               tg_id:"${this.$store.state.user.tg_id}"
                  user_pic: "${profilePictureUrl}"
                  tg_username: "${this.$store.state.user.tg_username}"
               ){
                     user_id
                      tg_id
                      first_name
                      middle_name
                      last_name
                      user_pic
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                      tg_username
                      statusMessage
                      getActiveUserCount{
                      count_result
                      }

                   }
              }
              `,
          },
        });

        if (userLoginResult.data.data.userLogin.length > 0) {
          if (profilePictureUrl != "")
            userLoginResult.data.data.userLogin[0].user_pic = profilePictureUrl;
          this.$store.dispatch(
            "loginUser",
            userLoginResult.data.data.userLogin[0]
          );
        }
      } catch (err) {}
    },
  },

  async created() {
    this.applyData = [];
    if (this.$store.state.user != "") {
      if (
        this.$store.state.paramId != "" &&
        this.$store.state.paramId != null
      ) {
        await this.getApplyData();
      }
      this.$store.dispatch("changeeditProfileDialog", false);
      await this.getAllData();

      this.getAccountInfo();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
zoomme {
  transition: transform 0.5s ease-in-out; /* Smooth transition */
  will-change: transform; /* Hint for smoother animations */
}

.zoom {
  transform: scale(1.05); /* Slightly less scaling for a more subtle effect */
}
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}
#loginpage {
  background-image: url("../assets//Intro.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.loadingText {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

#userAvt {
  background-color: #e96910;
}

input {
  width: 100%;
  color: white;
}

input:focus {
  width: 100%;
  color: white;
  border: 2px solid;
  border-color: rgb(2, 54, 121);
  outline: none;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea:focus {
  width: 100%;
  color: white;
  border: 2px solid;
  border-color: rgb(2, 54, 121);
  outline: none;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#imageCamera {
  background-color: #272a2f;
  font-size: 16px;
}

.v-radio .v-icon {
  color: red !important;
}

#divider {
  border-color: #272727;
}

#watermark {
  position: relative;
  overflow: hidden;
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/watermark-01.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}

#contentScroll {
  height: 100vh;
  overflow-y: auto;
}

#footer {
  background-color: rgb(2, 54, 121);
}

#applySection {
  /* background-image: url("../assets/light2.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  height: 30px;
}

/* #light-border {
  box-shadow: 0 -4px 4px rgba(0, 0, 255, 0.6);
  position: relative;
} */
</style>
