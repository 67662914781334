<template>
  <v-dialog persistent v-model="showDialog" max-width="400">
    <v-card>
      <v-card-title style="font-size: 16px"
        ><p>{{ message }}</p></v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="blue--text"
          style="font-size: 18px"
          @click="closeDialog"
          ><strong>OK</strong></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: String,
    show: Boolean,
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
  watch: {
    show(val) {
      this.showDialog = val;
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit("close");
    },
  },
};
</script>
