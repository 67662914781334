<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" class="pr-1 pt-4" v-if="!pageLoading">
        <div style="margin-top: 0px">
          <h4 class="text-center white--text pb-3">
            You have {{ allPosts.length }} Jobs to Approve
          </h4>
          <br />

          <v-layout
            row
            wrap
            class="white--text"
            v-for="(post, i) in allPosts"
            :key="i"
          >
            <v-flex xs7 class="pl-2 pr-1">
              <span style="font-size: 11px">
                <v-chip
                  @click="
                    showApplicantsDialog = true;
                    showApplicantsData = post;
                  "
                  :style="{
                    backgroundImage:
                      'linear-gradient(to right, #7632e0, #f97a68)',
                    color: 'white',
                    fontSize: '11px',
                    paddingBottom: '2px',
                    paddingTop: '1px',
                  }"
                  class="white--text"
                  x-small
                >
                  {{ post.getApplications.length }}
                  {{
                    $store.state.language == "English"
                      ? "Applied"
                      : `${
                          post.getApplications.length == 1 ? "አመልካች" : "አመልካቾች"
                        }`
                  }}
                </v-chip></span
              >
              <br />
              <span
                ><strong>{{ post.title.replace("@@cont@@", ",") }}</strong>
              </span>
              <br />

              <span class="truncate-text grey--text">{{
                post.company_name
              }}</span>

              <a
                @click="contactEmployee(post.getEmployer[0].tg_username)"
                target="-blank"
              >
                <span class="truncate-text blue--text mt-1"
                  >@{{ post.getEmployer[0].tg_username }}</span
                >
              </a>
            </v-flex>

            <v-flex xs5 class="pr-1 text-right pt-2">
              <span
                v-if="
                  new Date(post.deadline_date).getTime() <
                    Date.now() - 24 * 60 * 60 * 1000 && post.isrejected == 0
                "
                class="pink--text"
                >Expired</span
              >

              <span v-if="post.isrejected == 1" class="pink--text"
                >Rejected</span
              >
              <span
                v-if="
                  new Date(post.deadline_date).getTime() + 24 * 60 * 60 * 1000 >
                    Date.now() &&
                  post.isrejected == 0 &&
                  post.isapproved == 0
                "
                class="orange--text"
                >Pending</span
              >

              <span
                v-if="
                  new Date(post.deadline_date).getTime() + 24 * 60 * 60 * 1000 >
                    Date.now() &&
                  post.isrejected == 0 &&
                  post.isapproved == 1
                "
                class="green--text"
                >Active</span
              >

              <v-menu transition="scale-transition" rounded="lg">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn small text class="ml-1" v-bind="attrs" v-on="on">
                    <v-icon class="white--text">more_vert</v-icon></v-btn
                  >
                </template>
                <v-card
                  style="background-color: #272a2f"
                  width="150px"
                  class="pt-2 pb-2 pr-5"
                >
                  <span v-if="1 == 1">
                    <v-btn
                      @click="
                        showConfirm(
                          'Are you sure you want to approve this Job?'
                        );

                        postData = post;
                        indexPost = 0;
                        whichToCall = 'approve';
                      "
                      text
                      class="text-capitalize green--text ma-1"
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0 &&
                        post.bank_slip != ''
                      "
                    >
                      <v-icon class="green--text mr-1">check</v-icon> Approve
                    </v-btn>
                    <v-btn
                      @click="
                        selectedDetail = post;
                        detailDialog = true;
                      "
                      text
                      class="text-capitalize orange--text ma-1"
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0 &&
                        post.bank_slip == ''
                      "
                    >
                      <v-icon class="orange--text mr-1">attach_money</v-icon>
                      Not Paid
                    </v-btn>
                    <v-btn
                      @click="
                        showConfirm(
                          'Are you sure you want to approve this Job?'
                        );

                        postData = post;
                        indexPost = 0;
                        whichToCall = 'approve';
                      "
                      text
                      class="text-capitalize green--text ma-1"
                      v-if="
                        (post.isrejected == 1 || post.isapproved == 1) &&
                        post.bank_slip != ''
                      "
                    >
                      <v-icon class="green--text mr-1">check</v-icon> Repost
                    </v-btn>
                    <v-btn
                      @click="
                        showConfirm(
                          'Are you sure you want to approve this Job?'
                        );

                        postData = post;
                        indexPost = 0;
                        whichToCall = 'approve';
                      "
                      text
                      class="text-capitalize orange--text ma-1"
                      v-if="
                        post.isrejected == 1 ||
                        (post.isapproved == 1 && post.is_update_request == 1)
                      "
                    >
                      <v-icon class="orange--text mr-1">change_circle</v-icon>
                      change
                    </v-btn>
                    <v-btn
                      @click="
                        showConfirm(
                          'Are you sure you want to reject this Job?'
                        );

                        postData = post;

                        whichToCall = 'reject';
                      "
                      text
                      class="text-capitalize pink--text ma-1"
                      v-if="
                        new Date(post.deadline_date).getTime() +
                          24 * 60 * 60 * 1000 >
                          Date.now() &&
                        post.isrejected == 0 &&
                        post.isapproved == 0
                      "
                    >
                      <v-icon class="red--text mr-1">close</v-icon> Reject
                    </v-btn>
                  </span>

                  <v-divider
                    class="ml-3 ma-2"
                    v-if="
                      new Date(post.deadline_date).getTime() +
                        24 * 60 * 60 * 1000 >
                        Date.now() &&
                      post.isrejected == 0 &&
                      post.isapproved == 0
                    "
                    style="border-color: grey"
                  ></v-divider>
                  <v-btn
                    @click="
                      selectedDetail = post;
                      detailDialog = true;
                    "
                    text
                    class="text-capitalize white--text ma-1"
                  >
                    <v-icon class="grey--text mr-1">visibility</v-icon> view
                  </v-btn>
                  <br />
                  <v-btn
                    @click="editPostClicked(post)"
                    text
                    class="text-capitalize white--text ma-1"
                  >
                    <v-icon class="blue--text mr-1">edit</v-icon> Edit
                  </v-btn>
                  <br />

                  <v-btn
                    @click="
                      showConfirm('Are you sure you want to close this Job?');

                      postData = post;
                      indexPost = 1;
                      whichToCall = 'approve';
                    "
                    text
                    class="text-capitalize white--text ma-1"
                    v-if="post.message_id != ''"
                  >
                    <v-icon class="orange--text mr-1">block</v-icon> Close
                  </v-btn>
                  <br v-if="post.message_id != ''" />

                  <v-btn
                    @click="
                      showConfirm(
                        $store.state.language == 'English'
                          ? 'Are you sure you want to delete this job vacancy?'
                          : 'እርግጠኛ ነዎት ይህንን የስራ ልጥፍ ማጥፋት ይፈልጋሉ?'
                      );

                      postData = post;

                      whichToCall = 'delete';
                    "
                    text
                    class="text-capitalize white--text ma-1"
                  >
                    <v-icon class="red--text mr-1">delete</v-icon> delete
                  </v-btn>
                </v-card>
              </v-menu>
            </v-flex>

            <v-flex xs12 class="mt-2 mb-2">
              <v-divider style="border-color: #272a2f"></v-divider>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="detailDialog"
          fullscreen
          persistent
          v-if="selectedDetail != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  detailDialog = false;
                  selectedDetail = '';
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex
                    xs12
                    v-for="(job, i) in selectedDetail.title.split('@@cont@@')"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ selectedDetail.company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ selectedDetail.company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{ selectedDetail.sex.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.sex.split('@@cont@@')[i] != '' &&
                        selectedDetail.sex.split('@@cont@@')[i] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{ selectedDetail.vacancies.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.vacancies.split('@@cont@@')[i] != '' &&
                        selectedDetail.vacancies.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{ selectedDetail.salary.split("@@cont@@")[i] }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.salary.split('@@cont@@')[i] != '' &&
                        selectedDetail.salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{ selectedDetail.experience.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.experience.split('@@cont@@')[i] != '' &&
                        selectedDetail.experience.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{ selectedDetail.education.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.education.split('@@cont@@')[i] != '' &&
                        selectedDetail.education.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{ selectedDetail.job_type.split("@@cont@@")[i] }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.job_type.split('@@cont@@')[i] != '' &&
                        selectedDetail.job_type.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i == selectedDetail.title.split('@@cont@@').length - 1
                      "
                    >
                      <span
                        v-if="
                          new Date(selectedDetail.deadline_date).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadline }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadline }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >

                      <v-img
                        v-if="selectedDetail.short_image != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.short_image"
                        contain
                      ></v-img>

                      <span style="white-space: pre-line"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ selectedDetail.detail_description }}</span
                      >

                      <br />
                      <br v-if="selectedDetail.bank_slip != ''" />
                      <span v-if="selectedDetail.bank_slip != ''">
                        <strong
                          >{{
                            $store.state.language == "English"
                              ? "Payment screenshot"
                              : "የደረሰኝ ፎቶ"
                          }}:
                        </strong>
                      </span>
                      <v-img
                        v-if="selectedDetail.bank_slip != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.bank_slip"
                        contain
                      ></v-img>
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
              <div class="mt-10 mb-3 text-center">
                <v-btn
                  @click="
                    showConfirm('Are you sure you want to approve this Job?');

                    postData = selectedDetail;
                    indexPost = 0;
                    whichToCall = 'approve';
                  "
                  class="pl-5 pr-5 mt-5 mb-2 white--text mr-7 text-capitalize"
                  text
                  small
                  style="
                    background-image: linear-gradient(#023679, #023679);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                  v-if="
                    new Date(selectedDetail.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    selectedDetail.isrejected == 0 &&
                    selectedDetail.isapproved == 0 &&
                    selectedDetail.bank_slip != ''
                  "
                >
                  Approve
                </v-btn>

                <v-btn
                  class="pl-5 pr-5 mt-5 mb-2 white--text mr-7 text-capitalize"
                  text
                  small
                  style="
                    background-image: linear-gradient(red, pink);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                  v-if="selectedDetail.bank_slip == ''"
                >
                  Not Paid
                </v-btn>

                <v-btn
                  @click="
                    showConfirm('Are you sure you want to reject this Job?');

                    postData = selectedDetail;

                    whichToCall = 'reject';
                  "
                  class="pl-5 pr-5 mt-5 mb-2 white--text text-capitalize"
                  text
                  small
                  style="
                    background-image: linear-gradient(red, red);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                  v-if="
                    new Date(selectedDetail.deadline_date).getTime() +
                      24 * 60 * 60 * 1000 >
                      Date.now() &&
                    selectedDetail.isrejected == 0 &&
                    selectedDetail.isapproved == 0
                  "
                >
                  Reject
                </v-btn>
              </div>

              <!-- <input
                v-model="messageValue"
                class="pa-2 pl-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                placeholder=" Write Message
                   
                    "
              /> -->

              <textarea
                class="pa-2 pl-3 mt-6"
                v-model="messageValue"
                placeholder=" Write Message"
                rows="3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  background-color: #272a2f;
                "
              ></textarea>
              <div class="text-center mb-15">
                <v-btn
                  :loading="messageLoading"
                  @click="sendMessage"
                  class="pl-5 pr-5 mt-5 mb-15 pt-5 pb-5 white--text mr-7 text-capitalize"
                  text
                  small
                  style="
                    background-image: linear-gradient(#023679, #023679);
                    border-radius: 10px;
                    font-size: 18px;
                  "
                  >Send Message
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="editDialog"
          fullscreen
          persistent
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{
                $store.state.language == "English"
                  ? "Edit post"
                  : "ልጥፍዎን ያስተካክሉ"
              }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="editDialog = false"
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-0 mt-1 pr-1 pl-1">
                  <v-flex xs12 class="text-center white--text ma-1">
                    <h4>
                      {{
                        $store.state.language == "English"
                          ? " Please review and adjust as you like."
                          : "እባክዎን በጥንቃቄ ያንብቡ እና እንደፈለጉ ያስተካክሉ።"
                      }}
                    </h4>
                  </v-flex>
                  <v-flex xs12 md5 class="mt-2">
                    <!-- <span class="white--text ml-2">{{
                  $store.state.language == "English"
                    ? "Telegram Id"
                    : "የቴሌግራም ቁጥር"
                }}</span>

                <input
                  required
                  :rules="[(v) => !!v || 'title is required']"
                  v-model="tgid"
                  class="pa-2 pl-3 mb-2"
                  style="
                    font-size: 16px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  type="text"
                  :placeholder="
                    $store.state.language == 'English'
                      ? 'Telegram Id'
                      : 'የቴሌግራም ቁጥር'
                  "
                />
                <br /> -->
                    <span class="white--text"
                      >{{
                        $store.state.language == "English"
                          ? "Select a language to view your post on our Telegram channel in that language:"
                          : "ቋንቋ ይምረጡ፤ ልጥፍዎን በመረጡት ቋንቋ ቴሌግራም ቻናላችን ላይ ይታያል:"
                      }}
                      <br
                    /></span>
                    <div
                      class="pl-3 mt-2 mb-2 text-center"
                      style="
                        font-size: 18px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                        padding-top: 1px;
                      "
                    >
                      <v-radio-group
                        v-model="language"
                        row
                        class="white--text text-center"
                      >
                        <v-radio
                          @click="$store.dispatch('changeLanguage', 'English')"
                          base-color="white"
                          value="English"
                          color="white"
                          style="border-color: white; color: white"
                          class="custom-radio"
                        >
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "English"
                                : "English"
                            }}</span>
                          </template>
                        </v-radio>
                        <v-radio
                          value="አማርኛ"
                          color="white"
                          @click="$store.dispatch('changeLanguage', 'አማርኛ')"
                        >
                          <template v-slot:label>
                            <span class="white--text">{{
                              $store.state.language == "English"
                                ? "Amharic"
                                : "አማርኛ"
                            }}</span>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>

                    <input
                      required
                      :rules="[(v) => !!v || 'Company Name is required']"
                      v-model="companyName"
                      class="pa-2 pl-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Company Name*'
                          : 'የኩባንያ ስም*'
                      "
                    />
                    <br />

                    <input
                      :rules="[(v) => !!v || 'Location is required']"
                      required
                      v-model="address"
                      class="pa-2 pl-2 mt-2"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Work Location*'
                          : 'የስራ ቦታ*'
                      "
                    />
                    <br />

                    <div v-for="index in postCounter" :key="index">
                      <input
                        required
                        :rules="[(v) => !!v || 'title is required']"
                        v-model="title[index - 1]"
                        class="pa-2 pl-3 mt-6"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Job Title*'
                            : 'የስራ መጠሪያ*'
                        "
                      />

                      <div
                        class="pl-3 mt-2"
                        style="
                          font-size: 18px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                          padding-top: 2px;
                        "
                      >
                        <v-radio-group
                          v-model="sex[index - 1]"
                          row
                          class="white--text"
                        >
                          <br />
                          <span
                            ><strong>{{
                              $store.state.language == "English" ? "Sex:" : "ጾታ"
                            }}</strong></span
                          >
                          &nbsp;&nbsp;&nbsp;
                          <v-radio
                            base-color="white"
                            value="Male"
                            color="white"
                            style="border-color: white; color: white"
                            class="custom-radio"
                          >
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Male"
                                  : "ወንድ"
                              }}</span>
                            </template>
                          </v-radio>
                          <v-radio value="Female" color="white">
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Female"
                                  : "ሴት"
                              }}</span>
                            </template>
                          </v-radio>

                          <v-radio value="Both" color="white">
                            <template v-slot:label>
                              <span class="white--text">{{
                                $store.state.language == "English"
                                  ? "Both"
                                  : "ሁለቱም"
                              }}</span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>

                      <input
                        required
                        v-model="vacancies[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'How many employees required (Opt)'
                            : 'ምን ያህል ሰራተኞች ያስፈልጋሉ'
                        "
                      />

                      <input
                        required
                        v-model="salary[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Salary (Optional)'
                            : 'ደሞዝ'
                        "
                      />

                      <input
                        required
                        v-model="experience[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Experience Level (Optional)'
                            : 'የስራ ልምድ'
                        "
                      />

                      <v-flex xs12>
                        <v-menu
                          max-width="100vw"
                          :close-on-content-click="true"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              v-bind="attrs"
                              v-on="on"
                              @focus="unfocus"
                              required
                              v-model="education[index - 1]"
                              class="pa-2 pl-3 mt-2"
                              style="
                                font-size: 16px;
                                border-radius: 5px;
                                color: white;
                                background-color: #272a2f;
                              "
                              type="text"
                              :placeholder="
                                $store.state.language == 'English'
                                  ? 'Education Qualification (Optional)'
                                  : 'የትምህርት ደረጃ'
                              "
                            />
                          </template>
                          <v-card
                            style="
                              width: 92vw;
                              max-height: 100vh;
                              overflow-y: auto;
                            "
                          >
                            <v-list :lines="false" density="compact">
                              <v-list-item
                                style="border-bottom: 1px solid grey"
                                v-for="item in educationLists"
                                :key="item"
                                :value="item"
                                color="primary"
                                @click="
                                  education[index - 1] = item;
                                  education.splice(index - 1, 1, item);
                                "
                              >
                                <v-list-item-title
                                  class="pl-5"
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-flex>

                      <input
                        v-if="
                          education[index - 1] != undefined &&
                          education[index - 1] != '' &&
                          education[index - 1] != 'Not Required'
                        "
                        required
                        v-model="fieldStudy[index - 1]"
                        class="pa-2 pl-3 mt-2"
                        style="
                          font-size: 16px;
                          border-radius: 5px;
                          color: white;
                          background-color: #272a2f;
                        "
                        type="text"
                        :placeholder="
                          $store.state.language == 'English'
                            ? 'Field of Study or Skill (Optional)'
                            : 'የጥናት መስክ ወይም ችሎታ'
                        "
                      />
                      <v-flex xs12>
                        <v-menu
                          max-width="100vw"
                          :close-on-content-click="true"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <input
                              v-bind="attrs"
                              v-on="on"
                              @focus="unfocus"
                              required
                              v-model="jobType[index - 1]"
                              class="pa-2 pl-3 mt-2"
                              style="
                                font-size: 16px;
                                border-radius: 5px;
                                color: white;
                                background-color: #272a2f;
                              "
                              type="text"
                              :placeholder="
                                $store.state.language == 'English'
                                  ? 'Job Type (Optional)'
                                  : 'የቅጥር አይነት'
                              "
                            />
                          </template>
                          <v-card
                            style="
                              width: 92vw;
                              max-height: 100vh;
                              overflow-y: auto;
                            "
                          >
                            <v-list density="compact">
                              <v-list-item
                                style="border-bottom: 1px solid grey"
                                @click="jobType[index - 1] = item"
                                v-for="(item, k) in jobTypLists"
                                :key="k"
                                :value="item"
                                color="primary"
                              >
                                <v-list-item-title
                                  class="pl-5"
                                  v-text="item"
                                ></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-flex>
                    </div>

                    <div class="text-right pt-3">
                      <v-btn
                        right
                        @click="postCounter = postCounter + 1"
                        text
                        small
                        class="text-capitalize blue--text"
                      >
                        {{
                          $store.state.language == "English"
                            ? "Add More"
                            : "ስራ ይጨምሩ"
                        }}
                        <v-icon class="blue--text ml-2 mr-2">add_circle</v-icon>
                      </v-btn>

                      <v-btn
                        v-if="postCounter > 1"
                        @click="
                          education.splice(postCounter - 1, 1);
                          title.splice(postCounter - 1, 1);
                          sex.splice(postCounter - 1, 1);
                          vacancies.splice(postCounter - 1, 1);
                          experience.splice(postCounter - 1, 1);
                          fieldStudy.splice(postCounter - 1, 1);
                          jobType.splice(postCounter - 1, 1);
                          salary.splice(postCounter - 1, 1);
                          postCounter = postCounter - 1;
                        "
                        text
                        small
                        class="text-capitalize ml-1 blue--text text-right"
                      >
                        <v-icon class="pink--text ml-2">cancel</v-icon>
                      </v-btn>
                    </div>

                    <textarea
                      :rules="[(v) => !!v || 'Description is required']"
                      required
                      class="pa-2 pl-3 mt-6"
                      v-model="detailDescription"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Detail Description*'
                          : 'ዝርዝር መግለጫ*'
                      "
                      rows="5"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        background-color: #272a2f;
                      "
                    ></textarea>

                    <v-card
                      style="background-color: #272a2f"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage"
                        style="display: none"
                        @change="OnInputFile"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage"
                        style="display: none"
                        @change="OnInputFile"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image1"
                        height="142"
                        contain
                        v-if="image1 != ''"
                        @click="changeImage()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image1 == ''"
                        @click="simulateInputfileClicked"
                      >
                        <!-- <v-btn centered outlined large fab class="ma-4"> -->
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Company logo (Optional)"
                              : "የኩባንያ አርማ (መተው ይቻላል)"
                          }}
                        </p>
                        <br />
                        <br />
                        <!-- </v-btn> -->
                      </div>
                    </v-card>

                    <v-card
                      style="background-color: #272a2f"
                      elevation="0"
                      light
                      @dragend="OnDragEnter"
                      @dragleave="OnDragLeave"
                      @dragover.prevent
                      @drop="OnDrop"
                      class="text-center mt-2"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        ref="changeImage2"
                        style="display: none"
                        @change="OnInputFile2"
                      />

                      <v-img
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-0 mr-0"
                        :src="image12"
                        height="142"
                        contain
                        v-if="image12 != ''"
                        @click="changeImage2()"
                      ></v-img>

                      <div
                        @mouseenter="changeCursor"
                        id="imageCamera"
                        class="ml-5 mr-5 text-center"
                        v-if="image12 == ''"
                        @click="simulateInputfileClicked2"
                      >
                        <v-icon centered class="mt-10 white--text"
                          >camera_alt</v-icon
                        >
                        <br />

                        <p class="text-center white--text">
                          {{
                            $store.state.language == "English"
                              ? "Company logo two*"
                              : "የኩባንያ አርማ ሁለት*"
                          }}
                        </p>
                        <br />
                        <br />
                      </div>
                    </v-card>
                  </v-flex>
                  <!-- <v-flex xs12 class="">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-3 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isZerf"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Does it has a sector?"
                        : "ዘርፍ አለው?"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex>

              <v-flex xs6>
                <v-switch
                  hide-details
                  class="white--text pl-3 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isDetail"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Read details"
                        : "ዝርዝሩን ያንብቡ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->
                  <!-- 
              <v-flex xs6 class="pl-2">
                <v-switch
                  hide-details
                  class="white--text pl-2 pt-2 pb-2"
                  style="
                    font-size: 15px;
                    border-radius: 5px;
                    color: white;
                    background-color: #272a2f;
                  "
                  v-model="isTheSame"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English"
                        ? "Same Doc"
                        : "ተመሳሳይ ሰነድ"
                    }}</span>
                  </template>
                </v-switch>
              </v-flex> -->

                  <v-flex xs6 class="mt-3">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isRegistration"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Registration Mandatory"
                            : "መመዝገብ ግዴታ ነው"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex xs6 class="pl-2 mt-3">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="islinkedin"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Portfolio Required"
                            : "ፖርትፎሊዮ ያስፈልጋል"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs6>
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isCv"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "CV Mandatory"
                            : "CV ግዴታ ነው"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex xs6 class="pl-2">
                    <v-switch
                      hide-details
                      class="white--text pl-2 pt-2 pb-2"
                      style="
                        font-size: 15px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      v-model="isDirect"
                    >
                      <template v-slot:label>
                        <span class="white--text">{{
                          $store.state.language == "English"
                            ? "Direct Contact"
                            : "በቀጥታ ያነጋግሩ"
                        }}</span>
                      </template>
                    </v-switch>
                  </v-flex>

                  <v-flex xs12 v-if="isDirect == 1">
                    <input
                      required
                      v-model="phone"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Enter Phone Number or'
                          : 'ስልክ ቁጥር ያስገቡ ወይም'
                      "
                    />
                    <br />
                    <input
                      required
                      v-model="tegcontact"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Tg Username @xyz, Web, Whats up, Facebook or'
                          : 'ቴሌግራም ስም  @xyz፣ ድህረገጽ፣ ዋትስአፕ፣ ፌስቡክ ወይም'
                      "
                    />
                    <br />

                    <input
                      required
                      v-model="email"
                      class="pa-2 pl-3 mt-3"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      type="text"
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Enter Email'
                          : 'ኢሜይል ያስገቡ'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs6>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <input
                          @focus="unfocus"
                          :rules="[(v) => !!v || 'Deadline is required']"
                          v-bind="attrs"
                          v-on="on"
                          required
                          v-model="deadline"
                          class="pa-2 pl-3 mt-4"
                          style="
                            font-size: 16px;
                            border-radius: 5px;
                            color: white;
                            background-color: #272a2f;
                          "
                          type="text"
                          :placeholder="
                            $store.state.language == 'English'
                              ? 'Deadline Date*'
                              : 'የማብቂያ ቀን*'
                          "
                        />
                      </template>
                      <v-date-picker
                        :min="minDate"
                        v-model="deadline"
                        no-title
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs6 class="pl-2">
                    <input
                      required
                      v-model="age"
                      class="pa-2 pl-3 mt-4"
                      style="
                        font-size: 16px;
                        border-radius: 5px;
                        color: white;
                        background-color: #272a2f;
                      "
                      :placeholder="
                        $store.state.language == 'English'
                          ? 'Age Range (Opt)'
                          : 'የዕድሜ ክልል'
                      "
                    />
                    <br />
                  </v-flex>

                  <v-flex xs12 class="text-center">
                    <v-btn
                      :loading="submitLoading"
                      @click="submitPost"
                      class="pl-15 pr-15 mt-10 white--text"
                      text
                      style="
                        background-image: linear-gradient(#023679, #023679);
                        border-radius: 10px;
                        font-size: 18px;
                      "
                    >
                      <span class="text-capitalize">{{
                        $store.state.language == "English"
                          ? "Save Changes"
                          : "ለውጦችን ያስቀምጡ"
                      }}</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <AlertDialog
          :message="alertMessage"
          :show="showAlertDialog"
          @close="showAlertDialog = false"
        />
        <ConfirmDialog
          :message="confirmMessage"
          :show="showConfirmDialog"
          @confirm="handleConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";
import AlertDialog from "./AlertDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  components: {
    profileHeader,

    AlertDialog,
    ConfirmDialog,
  },
  data: () => ({
    pageLoading: false,
    allPosts: [],
    detailDialog: false,
    selectedDetail: "",

    showAlertDialog: false,
    alertMessage: "",
    showConfirmDialog: false,
    confirmMessage: "",
    confirmResult: null,
    postData: "",
    indexPost: -1,
    whichToCall: "",
    messageValue: "",
    messageLoading: false,

    editDialog: false,
    editablePost: "",
    postCounter: 0,

    isDetail: 1,
    isDirect: 0,
    isRegistration: 0,
    isCv: 0,
    islinkedin: 0,
    isZerf: 0,
    isTheSame: 1,

    age: "",
    tgid: "",
    companyName: "",
    title: [],
    vacancies: [],
    sex: [],
    salary: [],
    experience: [],
    education: [],
    jobType: [],
    fieldStudy: [],
    tegcontact: "",
    phone: "",
    email: "",

    shortDescription: "",
    detailDescription: "",
    address: "",
    applyLink: "",
    language: "",

    image1: "",
    imageUrl1: "",

    image12: "",
    imageUrl12: "",
    files: [],
    files2: [],

    domainUrl: "https://kinu.abimabusiness.com",
    channelName: "https://t.me/addis_ababa_jobs",
    jobTypLists: [
      "Full-Time",
      "Part-Time",
      "Remote",
      "Contract",
      "Temporary",
      "Freelancer",
      "Internship",
      "Volunteer",
    ],

    educationLists: [
      "8+ (Above 8th-grade)",
      "10+ (Above 10th-grade)",
      "12+ (Completed 12th-grade)",
      "TVET",
      "Diploma",
      "Bachelor's Degree",
      "Master's Degree",
      "Not Required",
    ],
    minDate: new Date().toISOString().substr(0, 10),
    dateDialog: false,
    deadline: "",
    menu: false,
    submitLoading: false,
  }),

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.deadline_date);
    },
  },

  methods: {
    showAlert(message) {
      this.alertMessage = message;
      this.showAlertDialog = true;
    },
    showConfirm(message) {
      this.confirmMessage = message;
      this.showConfirmDialog = true;
    },
    handleConfirm(answer) {
      this.confirmResult = answer;

      this.showConfirmDialog = false;
      if (answer == true) {
        if (this.whichToCall == "approve") {
          this.approveJob(this.postData, this.indexPost);
        } else if (this.whichToCall == "reject") {
          this.rejectJob(this.postData);
        } else if (this.whichToCall == "delete") {
          this.deletePost(this.postData);
        }
      } else {
        this.postData = "";
        this.indexPost = -1;
      }
    },
    editPostClicked(post) {
      this.editDialog = true;
      this.editablePost = post;
      this.postCounter = this.editablePost.title.split("@@cont@@").length;
      this.language = this.editablePost.language;
      this.$store.dispatch("changeLanguage", this.language);

      this.companyName = this.editablePost.company_name;
      this.address = this.editablePost.company_address;
      this.shortDescription = this.editablePost.short_description;
      this.detailDescription = this.editablePost.detail_description;
      this.image1 = this.editablePost.short_image;
      this.image12 = this.editablePost.detail_image;
      this.deadline = this.editablePost.deadline_date;

      this.isDetail = this.editablePost.isdetail;
      this.isDirect = this.editablePost.isdirect;
      this.isRegistration = this.editablePost.isregistered;
      this.isCv = this.editablePost.iscv;
      this.islinkedin = this.editablePost.islinkedin;
      this.isZerf = this.editablePost.iszerf;
      this.isTheSame = this.editablePost.isthesame;

      this.age = this.editablePost.age_above_bellow;

      if (this.editablePost.apply_link != "") {
        let applyLinkData = this.editablePost.apply_link.split(".cont.");

        if (applyLinkData[0] != "") this.phone = applyLinkData[0];
        if (applyLinkData[1] != "") this.tegcontact = applyLinkData[1];
        if (applyLinkData[2] != "") this.email = applyLinkData[2];
      }

      this.title = this.editablePost.title.split("@@cont@@");
      this.sex = this.editablePost.sex.split("@@cont@@");
      this.vacancies = this.editablePost.vacancies.split("@@cont@@");
      this.salary = this.editablePost.salary.split("@@cont@@");
      this.experience = this.editablePost.experience.split("@@cont@@");
      this.jobType = this.editablePost.job_type.split("@@cont@@");
      let educationSplit = this.editablePost.education.split("@@cont@@");
      this.education = [];
      this.fieldStudy = [];
      for (let i = 0; i < educationSplit.length; i++) {
        this.education.push(educationSplit[i].split(", in ")[0]);
        if (
          educationSplit[i].split(", in ")[1] != "" &&
          educationSplit[i].split(", in ")[1] != undefined
        )
          this.fieldStudy.push(educationSplit[i].split(", in ")[1]);
      }
    },

    async submitPost() {
      this.imageUrl1 = "";
      this.imageUrl12 = "";

      let isTitleEmpty = false;

      let tempjobTitle = "";
      let tempSex = "";
      let tempVacancies = "";
      let tempExperience = "";
      let tempEducation = "";

      let tempJobType = "";
      let tempSalary = "";

      for (let i = 0; i < this.postCounter; i++) {
        if (this.title[i] == "" || this.title[i] == undefined) {
          isTitleEmpty = true;
        }
        if (tempjobTitle == "") {
          tempjobTitle = this.title[i];
        } else {
          tempjobTitle = tempjobTitle + "@@cont@@" + this.title[i];
        }

        if (tempSex == "") {
          tempSex = this.sex[i];
        } else {
          tempSex = tempSex + "@@cont@@" + this.sex[i];
        }

        if (tempVacancies == "") {
          tempVacancies = this.vacancies[i];
        } else {
          tempVacancies = tempVacancies + "@@cont@@" + this.vacancies[i];
        }

        if (tempExperience == "") {
          tempExperience = this.experience[i];
        } else {
          tempExperience = tempExperience + "@@cont@@" + this.experience[i];
        }

        if (tempEducation == "") {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined &&
            this.education[i] != "undefined"
          ) {
            if (
              this.fieldStudy[i] != "" &&
              this.fieldStudy[i] != undefined &&
              this.fieldStudy[i] != "undefined"
            ) {
              tempEducation = this.education[i] + ", in " + this.fieldStudy[i];
            } else {
              tempEducation = this.education[i];
            }
          } else {
            tempEducation = this.education[i];
          }
        } else {
          if (
            this.education[i] != "" &&
            this.education[i] != "Not Required" &&
            this.education[i] != undefined &&
            this.education[i] != "undefined"
          ) {
            if (
              this.fieldStudy[i] != "" &&
              this.fieldStudy[i] != undefined &&
              this.fieldStudy[i] != "undefined"
            ) {
              tempEducation =
                tempEducation +
                "@@cont@@" +
                this.education[i] +
                ", in " +
                this.fieldStudy[i];
            } else {
              tempEducation = tempEducation + "@@cont@@" + this.education[i];
            }
          } else {
            tempEducation = tempEducation + "@@cont@@" + this.education[i];
          }
        }

        if (tempJobType == "") {
          tempJobType = this.jobType[i];
        } else {
          tempJobType = tempJobType + "@@cont@@" + this.jobType[i];
        }

        if (tempSalary == "") {
          tempSalary = this.salary[i];
        } else {
          tempSalary = tempSalary + "@@cont@@" + this.salary[i];
        }
      }
      if (isTitleEmpty) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter title"
            : "እባክዎ የስራውን መጠርያ ያስገቡ"
        );
        return;
      }

      // if (this.files.length < 1) {
      //  this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo one"
      //       : "እባክዎ የካምፓኒ አርማ አንድ ያስገቡ"
      //   );
      //   return;
      // }

      // if (this.files2.length < 1) {
      //  this.showAlert(
      //     this.$store.state.language == "English"
      //       ? "Please upload campany logo two"
      //       : "እባክዎ የካምፓኒ አርማ ሁለት ያስገቡ"
      //   );
      //   return;
      // }

      if (
        this.companyName == "" ||
        this.companyName == undefined ||
        this.companyName == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company name"
            : "እባክዎ የኩባንያውን ስም ያስገቡ"
        );
        return;
      }

      if (
        this.detailDescription == "" ||
        this.detailDescription == undefined ||
        this.detailDescription == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter detail description"
            : "እባክዎ የስራዉን ዝርዝር ያስገቡ"
        );
        return;
      }

      if (
        this.address == "" ||
        this.address == undefined ||
        this.address == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company address"
            : "እባክዎ የኩባንያውን አድራሻ ያስገቡ"
        );
        return;
      }

      if (
        this.deadline == "" ||
        this.deadline == undefined ||
        this.deadline == "undefined"
      ) {
        this.showAlert(
          this.$store.state.language == "English"
            ? "Please enter company address"
            : "እባክዎ ቀኑ የሚያበቃበትን ያስገቡ"
        );
        return;
      }
      this.applyLink = "";
      if (this.isDirect == 1) {
        if (this.tegcontact == "" && this.phone == "" && this.email == "") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "You chose direct contact,  but you haven’t provided your contact information. Please include your contact information from the options"
              : "ቀጥተኛ ግንኙነትን መርጠዋል፣ ነገር ግን የመገናኛ መረጃዎን አላቀረቡም። እባክዎን የሚገናኙበትን መረጃ ያካትቱ"
          );
          return;
        }

        this.applyLink = "";
        this.applyLink =
          this.phone + ".cont." + this.tegcontact + ".cont." + this.email;
      }

      let tempZerf = this.title.length > 1 ? 1 : 0;

      this.submitLoading = true;

      // if (this.education != "" && this.education != "Not Required") {
      //   if (this.fieldStudy != "")
      //     this.education = this.education + " in " + this.fieldStudy;
      // }

      try {
        if (this.files[0] != "" && this.files[0] != undefined) {
          await this.uploadImage(this.files[0], 1);
        } else this.imageUrl1 = this.image1;

        if (this.files2[0] != "" && this.files2[0] != undefined) {
          await this.uploadImage(this.files2[0], 2);
        } else this.imageUrl12 = this.image12;

        // if (this.imageUrl1 == "" || this.imageUrl12 == "") {
        //   this.showAlert(
        //     this.$store.state.language == "English"
        //       ? "The logo did not upload. Please try again."
        //       : "አርማው አልተጫነም። እባክዎ እንደገና ይሞክሩ።"
        //   );
        //   this.submitLoading = false;
        //   return;
        // }
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        axios.defaults.headers.get["Accept"] = "application/json;charset=UTF-8";
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,
          data: {
            query: `
                 mutation {
                  editPost(
                   post_id: ${parseInt(this.editablePost.post_id.toString())}
                   title:"${tempjobTitle}"
                    short_description:"${this.shortDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    short_image:"${this.imageUrl1}"

                    detail_description:"${this.detailDescription
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    detail_image:"${this.imageUrl12}"
                    company_address:"${this.address}"
                    deadline_date:"${this.deadline}"
                    
                    sex:"${tempSex}"
                    isdetail:${this.isDetail + 0}
                    isdirect: ${this.isDirect + 0}
                    apply_link:"${this.applyLink}"
                    isregistered: ${this.isRegistration + 0}

                    iscv: ${this.isCv + 0}
                    islinkedin: ${this.islinkedin + 0}
                    iszerf: ${tempZerf + 0}
                    isthesame: ${this.isTheSame + 0}
                    age_above_bellow:"${this.age}"
                    language: "${this.$store.state.language}"

                    company_name:"${this.companyName}"
                    vacancies:"${tempVacancies}"
                    salary:"${tempSalary}"
                    experience:"${tempExperience}"
                    education:"${tempEducation}"
                    job_type:"${tempJobType}"

                    ){
                        statusMessage
                    }
                    }
              `,
          },
        });
        if (result.data.data.editPost[0].statusMessage.toString() == "true") {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Your job change request has been submitted successfully. Please wait for admin approval."
              : "የስራ ማስተካከያ ጥያቄዎ በተሳካ ሁኔታ ተልኳል፣ እባክዎ ተቀባይነት እስኪያገኝ ድረስ ይጠብቁ።"
          );
          this.editDialog = false;
          await this.getAllDataToApprove();
        } else {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Something went wrong, please try again"
              : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.submitLoading = false;
    },

    unfocus(event) {
      event.target.blur();
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;

      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = e.target.files;
      Array.from(files).forEach((file) => this.addImage(file));
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        this.showAlert(
          this.$store.state.language == "English"
            ? `${file.name} is not an image`
            : `${file.name} ምስል አይደለም`
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = async (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    async uploadImage(fileToUpload, imageNumber) {
      const formData = new FormData();
      formData.append("image", fileToUpload);

      try {
        const response = await axios.post(
          `${this.domainUrl}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (imageNumber == 1)
          this.imageUrl1 = this.domainUrl + response.data.filePath;
        else this.imageUrl12 = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.showAlert("Error uploading image: " + error);
      }
    },

    //image2

    OnInputFile2(e) {
      const files = e.target.files;
      this.files2 = e.target.files;
      Array.from(files).forEach((file) => this.addImage2(file));
    },
    simulateInputfileClicked2() {
      this.$refs.selectImage2.click();
    },

    addImage2(file) {
      if (!file.type.match("image.*")) {
        return;
      }

      const reader = new FileReader();
      reader.onload = (f) => {
        this.image12 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage2() {
      this.$refs.changeImage2.click();
    },
    async uploadImage2() {
      var storageReference = this.$storage.ref(
        "jobimages/image" + Math.random() + ".jpg"
      );
      try {
        await storageReference.put(this.files2[0]);
        this.imageUrl12 = await storageReference.getDownloadURL();
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
    },

    contactEmployee(tg) {
      window.location.href = "https://t.me/" + tg;
    },

    async sendMessage() {
      if (this.messageValue != "") {
        this.messageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                sendMessage(
                  message_value:"${this.messageValue}"
                  tg_id:"${this.selectedDetail.tg_id}"
                  title: "${this.selectedDetail.title.replace(
                    "@@cont@@",
                    ","
                  )}"            


                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.sendMessage[0].statusMessage.toString() == "true"
          ) {
            this.showAlert("Message was sent");
          } else this.showAlert("Something went wrong, please try again");
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }

        this.messageLoading = false;
      }
    },
    async approveJob(job, isclose) {
      const approveConfirm = this.confirmResult;
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                approveJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                  title: "${job.title}"

                  detail_description: "${job.detail_description
                    .toString()
                    .trim()
                    .replace(/\n/g, "\\n")}"
                  short_image: "${job.short_image}"
                  detail_image: "${job.detail_image}"
                  company_address: "${job.company_address}"
                  deadline_date: "${job.deadline_date}"
                  apply_link: "${job.apply_link}"
                  sex: "${job.sex}"

                company_name: "${job.company_name}"
              vacancies: "${job.vacancies}"
               salary: "${job.salary}"
                 experience: "${job.experience}"
                 education: "${job.education}"
                 job_type: "${job.job_type}"
                 language: "${job.language}"
                 is_update_request:${parseInt(job.is_update_request)}
                 message_id:"${job.message_id}"  
                 isclose:${isclose}



                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.approveJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else this.showAlert("Not approved, Something went wrong");
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }

        this.pageLoading = false;
        this.detailDialog = false;
      }
    },

    async rejectJob(job) {
      const approveConfirm = this.confirmResult;
      if (approveConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                rejectJob(
                  post_id:${parseInt(job.post_id)}
                  tg_id:"${job.tg_id}"
                title: "${job.title.replace("@@cont@@", ",")}"            


                  ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            result.data.data.rejectJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else
            this.showAlert(
              "Not rejected, Something went wrong, please try again"
            );
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
        this.detailDialog = false;
        this.pageLoading = false;
      }
    },
    async getAllDataToApprove() {
      this.pageLoading = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
          getAllPostsToApprove(tg_id:"${this.$store.state.user.tg_id}"){
                 post_id
                 tg_id
                 title
                  short_description
                  short_image
                  detail_description
                  detail_image
                  company_address
  
                 deadline_date
                  application_format
                  isdetail
                  isdirect
                 apply_link
                  isregistered
                  posted_date
  
                  iscv
                  islinkedin
                  iszerf
                 sex
                 age_above_bellow
                 isthesame
                 isapproved
                 isrejected
                language
                message_id
                company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                 is_update_request
                 bank_slip
                 getEmployer{
                     user_id
                     tg_id
                     tg_username
                 }
                getApplications{
                        application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted

                        getEmployees{
                          user_id
                      tg_id
                      tg_username
                      first_name
                      middle_name
                      last_name
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                        }

                }
  
  
             }
        }
        `,
          },
        });

        this.allPosts = result.data.data.getAllPostsToApprove;
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.pageLoading = false;
    },

    async deletePost(post) {
      const acceptConfirm = this.confirmResult;
      if (acceptConfirm) {
        this.pageLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,
            data: {
              query: `mutation{
                deleteJob(
                  post_id:${parseInt(post.post_id)}
                  message_id:"${post.message_id}"       

                  ){
                 statusMessage
              }
              }
              `,
            },
          });
          if (
            result.data.data.deleteJob[0].statusMessage.toString() == "true"
          ) {
            this.getAllDataToApprove();
          } else
            this.showAlert(
              this.$store.state.language == "English"
                ? "Something went wrong, please try again"
                : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
            );
        } catch (err) {
          this.showAlert(
            this.$store.state.language == "English"
              ? err.message
              : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      }
    },
    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },
  created() {
    this.getAllDataToApprove();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}

input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#imageCamera {
  background-color: #272a2f;
  font-size: 16px;
}

.v-radio .v-icon {
  color: red !important;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
</style>
