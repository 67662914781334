<template>
  <div v-if="$store.state.userHeaders != ''">
    <v-app-bar app id="navBar" dark flat height="55px">
      <v-layout row wrap style="margin-left: -20px">
        <v-flex xs2 class="text-center pl-2">
          <img height="50px" contain src="../assets/logo2.png" />
        </v-flex>

        <v-flex xs10 style="font-size: 16px; font-weight: bold">
          <h3 style="padding-top: 6px">
            {{
              $store.state.language == "English"
                ? "ADDIS ABABA JOBS"
                : "አዲስ አበባ ስራዎች"
            }}
          </h3>
        </v-flex>
      </v-layout>

      <v-btn
        style="font-size: 11px; margin-top: -15px"
        text
        small
        id="btBtns"
        @click="
          if ($store.state.language == 'አማርኛ') {
            $store.dispatch('changeLanguage', 'English');
          } else $store.dispatch('changeLanguage', 'አማርኛ');
        "
        class="text-capitalize pr-0"
        :ripple="false"
      >
        <span
          >{{ $store.state.language == "አማርኛ" ? "አማርኛ" : "English" }}
          <v-icon style="font-size: 26px" class="grey--text"
            >expand_more</v-icon
          ></span
        >
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  // computed: {
  //   mobile() {
  //     return this.$vuetify.breakpoint.mdAndDown;
  //   },
  // },
  // watch: {
  //   group() {
  //     this.drawer = false;
  //   },
  // },
};
</script>
<style scoped>
#navBar {
  background-color: #000000;
}

#btBtns:hover,
#btBtns:active,
#btBtns:focus,
#btBtns.v-btn--active {
  background-color: #000000 !important;
  box-shadow: none;
}
#btBtns.v-btn--active {
  background-color: #000000 !important;
}

#btBtns::before,
#btBtns::after {
  background-color: transparent !important;
}
</style>
