var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('navBar'),_c('v-main',{attrs:{"id":"body"}},[_c('router-view')],1),(_vm.$store.state.userHeaders != '')?_c('v-footer',{staticClass:"pl-0 pb-2 pt-2",staticStyle:{"border-top":"1px solid #000000"},attrs:{"app":"","id":"footer"}},[_c('v-layout',{staticClass:"pa-2 pl-4 pr-1 text-center",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'home')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'home');
            _vm.$router
              .push({
                name: 'home',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"white--text mb-1"},[_vm._v(" home ")]),_vm._v(" "),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("home")]):_c('span',[_vm._v("መነሻ")])],1)],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'home');
            _vm.$router
              .push({
                name: 'home',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"grey--text text--darken-1 mb-1"},[_vm._v(" home ")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("home")]):_c('span',[_vm._v("መነሻ")])],1)],1)],1),_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'jobs')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'jobs');
            _vm.$router
              .push({
                name: 'myjobs',
              })
              .catch((err) => {});}}},[_c('v-flex',{staticClass:"pt-4",attrs:{"xs12":""}},[_c('img',{attrs:{"src":require("./assets/bag.png"),"height":"30px","width":"35px","alt":"L","contain":""}}),(_vm.$store.state.language == 'English')?_c('p',{staticStyle:{"margin-top":"-4px"}},[_vm._v(" My Jobs ")]):_c('p',{staticStyle:{"margin-top":"-4px"}},[_vm._v("ሥራዎቼ")])])],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'jobs');
            _vm.$router
              .push({
                name: 'myjobs',
              })
              .catch((err) => {});}}},[_c('v-flex',{staticClass:"pt-4",attrs:{"xs12":""}},[_c('img',{attrs:{"src":require("./assets/bag.png"),"height":"30px","width":"35px","alt":"L","contain":""}}),(_vm.$store.state.language == 'English')?_c('p',{staticStyle:{"margin-top":"-4px"}},[_vm._v(" My Jobs ")]):_c('p',{staticStyle:{"margin-top":"-4px"}},[_vm._v("ሥራዎቼ")])])],1)],1),_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'Careers')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'Careers');
            _vm.$router
              .push({
                name: 'career',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"white--text mb-1"},[_vm._v(" signal_cellular_alt")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("Careers")]):_c('span',[_vm._v("ሙያዎች")])],1)],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'Careers');
            _vm.$router
              .push({
                name: 'career',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"grey--text text--darken-1 mb-1"},[_vm._v(" signal_cellular_alt ")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("Careers")]):_c('span',[_vm._v("ሙያዎች")])],1)],1)],1),_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'notification')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'notification');
            _vm.$router
              .push({
                name: 'alert',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"white--text mb-1"},[_vm._v(" notifications ")]),_vm._v(" "),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("Alerts")]):_c('span',[_vm._v("ማሳወቂያ")])],1)],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'notification');
            _vm.$router
              .push({
                name: 'alert',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"grey--text text--darken-1 mb-1"},[_vm._v(" notifications ")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("Alerts")]):_c('span',[_vm._v("ማሳወቂያ")])],1)],1)],1),_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'contact')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'contact');
            _vm.$router
              .push({
                name: 'about',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"white--text mb-1"},[_vm._v(" info ")]),_vm._v(" "),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("About")]):_c('span',[_vm._v("ስለኛ")])],1)],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'contact');
            _vm.$router
              .push({
                name: 'about',
              })
              .catch((err) => {});}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"grey--text text--darken-1 mb-1"},[_vm._v(" info ")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("About")]):_c('span',[_vm._v("ስለኛ")])],1)],1)],1),_c('v-flex',{attrs:{"xs2":""}},[(_vm.$store.state.whichBtn == 'profile')?_c('v-btn',{staticClass:"text-capitalize white--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px","background-color":"#15161a"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'profile');
            _vm.$store.dispatch('changeeditProfileDialog', true);}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"white--text mb-1"},[_vm._v(" person ")]),_vm._v(" "),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("profile")]):_c('span',[_vm._v("መለያ")])],1)],1):_c('v-btn',{staticClass:"text-capitalize grey--text pt-6 pb-7 mr-1",staticStyle:{"border-radius":"8px","padding-left":"3px","padding-right":"3px","font-size":"10px"},attrs:{"id":"btBtns","ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('changeBtn', 'profile');
            _vm.$store.dispatch('changeeditProfileDialog', true);}}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-icon',{staticClass:"grey--text text--darken-1 mb-1"},[_vm._v(" person ")]),_c('br'),(_vm.$store.state.language == 'English')?_c('span',[_vm._v("profile")]):_c('span',[_vm._v("መለያ")])],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }