<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- box-shadow: 0px -20px 0px #4f3606; -->
  <v-card-text class="pa-0 ma-0">
    <v-card-text class="pa-0 ma-0" style="pointer-events: none; z-index: 10">
      <v-img width="100%" cover src="../assets/light new 2-01.png"></v-img>
    </v-card-text>

    <v-layout
      row
      wrap
      class="pb-3"
      style="margin-left: 3%; margin-right: 2%; margin-top: -20%; z-index: 20"
    >
      <v-flex xs1 class="text-right">
        <a @click="$store.dispatch('changeeditProfileDialog', true)">
          <v-img
            height="30"
            style="border-radius: 5px"
            width="60"
            contain
            v-if="$store.state.user.user_pic != ''"
            alt="Avatar"
            :src="$store.state.user.user_pic"
          ></v-img>
          <v-icon v-else class="white--text">person</v-icon>
        </a>
      </v-flex>

      <v-flex xs5 class="pt-1 pl-2">
        <h4 class="white--text">
          {{ $store.state.user.tg_username }}
        </h4>
      </v-flex>

      <v-flex xs6 class="text-right pr-4 pt-1">
        <a @click="gotoTg">
          <span
            v-if="$store.state.language == 'English'"
            style="color: #2c99ff"
          >
            <v-icon
              style="font-size: 17px; margin-bottom: 1px"
              class="grey--text"
              >arrow_back_ios_new</v-icon
            >
            Back to Channel
          </span>
          <span style="color: #2c99ff" v-else>
            <v-icon
              style="font-size: 17px; margin-bottom: 1px"
              class="grey--text"
              >arrow_back_ios_new</v-icon
            >
            ወደ ቻናል ይመለሱ</span
          >
        </a>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="$store.state.editProfileDialog"
      fullscreen
      persistent
    >
      <v-card style="background-color: #000000" class="white--text">
        <v-card-title>
          {{
            $store.state.language == "English" ? "Edit Profile" : "ፕሮፋይል ያስተካክሉ"
          }}
          <v-spacer v-if="$store.state.user.tg_id == '6160879304'"></v-spacer>
          <v-btn
            v-if="$store.state.user.tg_id == '6160879304'"
            @click="
              $router
                .push({
                  name: 'approve',
                })
                .catch((err) => {});

              $store.dispatch('changeeditProfileDialog', false);
            "
            small
            text
          >
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            dark
            color="pink"
            text
            @click="
              $store.dispatch('changeeditProfileDialog', false);
              $store.dispatch('changeBtn', 'home');
            "
          >
            {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
          </v-btn>
        </v-card-title>

        <v-card-text class="pb-15 pl-5">
          <v-layout row wrap>
            <v-flex xs12 class="text-center mt-4">
              <a>
                <v-avatar size="80px" id="userAvt">
                  <v-img
                    height="80"
                    width="80"
                    contain
                    v-if="$store.state.user.user_pic != ''"
                    alt="Avatar"
                    :src="$store.state.user.user_pic"
                  ></v-img>
                  <v-icon large v-else class="white--text">person</v-icon>
                </v-avatar>
              </a>
              <br />
              <p class="headline white--text pl-3 mt-1 text-capitalize">
                {{ $store.state.user.tg_username }}
              </p>
            </v-flex>
            <v-flex xs12 class="pl-2 pr-2">
              <input
                v-model="firstName"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'First Name'
                    : 'የመጀመሪያ ስም'
                "
              />
              <br />

              <input
                v-model="middleName"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English' ? 'Middle Name' : 'የአባት ስም'
                "
              />
              <br />

              <input
                v-model="lastName"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English' ? 'Last Name' : 'የአያት ስም'
                "
              />

              <br />

              <input
                v-model="phoneNumber"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'Phone Number'
                    : 'ስልክ ቁጥር'
                "
              />

              <input
                v-model="age"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English' ? 'Age' : 'ዕድሜ'
                "
              />

              <input
                v-model="address"
                class="pa-2 pl-3 mt-3"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'Specific Address'
                    : 'ዕድሜ'
                "
              />
            </v-flex>

            <v-flex class="pl-2 pr-2" xs12>
              <v-radio-group
                v-model="sex"
                row
                class="white--text pa-2 pl-4 pb-0 pt-4 pr-0"
                style="
                  font-size: 18px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
              >
                <span class="mr-2">{{
                  $store.state.language == "English" ? "Gender: " : "ጾታ: "
                }}</span>

                <v-radio
                  base-color="white"
                  value="Male"
                  color="white"
                  style="border-color: white; color: white"
                  class="custom-radio"
                >
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English" ? "Male" : "ወንድ"
                    }}</span>
                  </template>
                </v-radio>
                <v-radio value="Female" color="white">
                  <template v-slot:label>
                    <span class="white--text">{{
                      $store.state.language == "English" ? "Female" : "ሴት"
                    }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-flex>

            <v-flex xs12 class="pl-2 pr-2 mt-2">
              <v-select
                v-model="selectedEducationLevel"
                class="white--text pa-2 pl-0 pb-4 pt-4 pr-0"
                style="
                  font-size: 18px;
                  border-radius: 5px;
                  background-color: #272a2f;
                "
                :items="levelEducation"
                density="compact"
                :hide-details="true"
                color="blue"
              >
                <template v-slot:label>
                  <span class="white--text pl-2">{{
                    $store.state.language == "English"
                      ? "Choose level of Education"
                      : "የትምህርት ደረጃ"
                  }}</span>
                </template>

                <template v-slot:selection="{ item }">
                  <span class="white--text pl-2">{{ item }}</span>
                </template>
              </v-select>
            </v-flex>

            <v-flex xs12 class="pl-2 pr-2 mt-2">
              <input
                v-model="selectedStudyField"
                class="pa-2 pl-3 mt-1"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'Field of study, skill or Any title'
                    : 'የጥናት መስክ'
                "
              />
            </v-flex>

            <v-flex xs12 class="pl-2 pr-2">
              <v-card
                style="background-color: #272a2f"
                elevation="0"
                light
                @dragend="OnDragEnter"
                @dragleave="OnDragLeave"
                @dragover.prevent
                @drop="OnDrop"
                class="text-center mt-3 pb-2"
              >
                <input
                  type="file"
                  accept=".pdf"
                  ref="selectCv"
                  style="display: none"
                  @change="handleFileChange"
                />

                <div
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5 text-center"
                  @click="simulateInputfileCV"
                >
                  <v-icon large centered class="mt-4 white--text"
                    >file_upload</v-icon
                  >
                  <br />

                  <p
                    class="text-center white--text mt-1"
                    v-if="pdfUploadStatus == ''"
                  >
                    <span v-if="$store.state.user.cv == ''">{{
                      $store.state.language == "English"
                        ? "Attach Your CV"
                        : "CV ያያይዙ"
                    }}</span>

                    <span v-else>{{
                      $store.state.language == "English"
                        ? "Your CV already attached. Tap here to change"
                        : "ሲቪዎ አስቀድሞ ተያይዟል \n ለመቀየር ነካ ያድርጉ"
                    }}</span>
                  </p>

                  <p class="text-center white--text mt-1" v-else>
                    {{ pdfUploadStatus }}
                  </p>
                </div>
              </v-card>
            </v-flex>

            <v-flex xs12 class="pl-2 pr-2">
              <textarea
                :rules="[(v) => !!v || 'Tell us']"
                required
                class="pa-2 pl-3 mt-3"
                v-model="coverLetter"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'Tell us about your self: Education, Skills, Experience, Projects accomplished etc.'
                    : 'ስለራስዎ ይንገሩን፡ ትምህርት፣ ችሎታዎች፣ ልምድ፣ ፕሮጀክቶች ወዘተ።'
                "
                rows="5"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  background-color: #272a2f;
                "
              ></textarea>

              <input
                v-model="link"
                class="pa-2 pl-3 pt-4 pb-4 mt-1"
                style="
                  font-size: 16px;
                  border-radius: 5px;
                  color: white;
                  background-color: #272a2f;
                "
                type="text"
                :placeholder="
                  $store.state.language == 'English'
                    ? 'Portfolio, Linkedin or GitHub Link'
                    : 'ፖርትፎሊዮ፣ ሊንክዲን ወይም GitHub ሊንክ'
                "
              />
            </v-flex>
            <v-flex xs12 class="text-center pb-15">
              <v-btn
                @click="saveChanges"
                :loading="saveChangesLoading"
                class="pl-15 pr-15 mt-6 white--text"
                text
                style="
                  background-image: linear-gradient(#023679, #023679);

                  border-radius: 10px;
                  font-size: 18px;
                "
              >
                <span class="text-capitalize">{{
                  $store.state.language == "English"
                    ? "Save Changes"
                    : "ለውጦችን ያስቀምጡ"
                }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <AlertDialog
      :message="alertMessage"
      :show="showAlertDialog"
      @close="showAlertDialog = false"
    />
  </v-card-text>
</template>

<script>
import axios from "axios";
import AlertDialog from "./AlertDialog.vue";

export default {
  components: {
    AlertDialog,
  },
  data: () => ({
    showAlertDialog: false,
    alertMessage: "",

    editProfileDialog: false,
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    sex: "",
    age: "",
    address: "",
    coverLetter: "",
    link: "",
    saveChangesLoading: false,
    group: null,
    selectedEducationLevel: "",
    selectedStudyField: "",
    levelEducation: [
      "8+ (Above 8th-grade)",
      "10+ (Above 10th-grade)",
      "12+ (Completed 12th-grade)",
      "TVET",
      "Diploma",
      "Bachelor's Degree",
      "Master's Degree",
    ],

    studyField: [
      "Foo",
      "Bar",
      "Fizz",
      "Buzz",
      "Foo2",
      "Bar1",
      "Fizz4",
      "Buzz6",
      "Foo7",
      "Bar9",
      "Fizz9",
      "Buzz0",
      "Foou",
      "Barr",
      "Fizze",
      "Buzze",
    ],
    pdfUploadStatus: "",
    pdf: [],
    domainUrl: "https://kinu.abimabusiness.com",
    cvUrl: "",
  }),

  methods: {
    showAlert(message) {
      this.alertMessage = message;
      this.showAlertDialog = true;
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();

      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
    },

    OnDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;

      Array.from(files).forEach((file) => this.addImage(file));
    },
    simulateInputfileCV() {
      this.$refs.selectCv.click();
    },

    async handleFileChange(event) {
      this.pdf = event.target.files[0];
      this.pdfUploadStatus =
        this.$store.state.language == "English" ? "Uploading..." : "በመጫን ላይ...";

      const formData = new FormData();
      formData.append("pdf", this.pdf);
      this.cvUrl = "";
      try {
        const response = await axios.post(
          `${this.domainUrl}/uploadpdf`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.cvUrl = this.domainUrl + response.data.filePath;
      } catch (error) {
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        return;
      }

      if (this.cvUrl != "") {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.userURL,

            data: {
              query: `mutation{
                attachCv(
                  user_id:${parseInt(this.$store.state.user.user_id)}
                  cvurl:"${this.cvUrl}"


                  ){
                 statusMessage
              }

              }
              `,
            },
          });

          if (result.data.data.attachCv[0].statusMessage.toString() == "true") {
            this.getAccountInfo();
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploaded Successfully!."
                : "በተሳካ ሁኔታ ተጭኗል!";
          } else
            this.pdfUploadStatus =
              this.$store.state.language == "English"
                ? "Uploading Error, Please try again"
                : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        } catch (err) {
          this.pdfUploadStatus =
            this.$store.state.language == "English"
              ? "Uploading Error, Please try again"
              : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
        }
      } else
        this.pdfUploadStatus =
          this.$store.state.language == "English"
            ? "Uploading Error, Please try again"
            : "ስህተት ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ";
    },
    gotoTg() {
      window.location.href = "https://t.me/addis_ababa_jobs";
    },

    async getAccountInfo() {
      try {
        var userLoginResult = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
               userLogin(
               tg_id:"${this.$store.state.user.tg_id}"
               user_pic: "${this.$store.state.user.user_pic}"
               tg_username: "${this.$store.state.user.tg_username}"
               
               ){
                     user_id
                      tg_id
                      first_name
                      middle_name
                      last_name
                      user_pic
                      sex
                      birth_date
                      address
                      phone_number
                      education
                      study_field
                      cover_letter
                      cv
                      link
                      tg_username
                      statusMessage
                      getActiveUserCount{
                      count_result
                      }

                   }
              }
              `,
          },
        });

        if (userLoginResult.data.data.userLogin.length > 0) {
          this.$store.dispatch(
            "loginUser",
            userLoginResult.data.data.userLogin[0]
          );
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
    },

    async saveChanges() {
      this.saveChangesLoading = true;
      try {
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        axios.defaults.headers.get["Accept"] = "application/json;charset=UTF-8";
        var result = await axios({
          method: "POST",
          url: this.$store.state.userURL,
          data: {
            query: `
                 mutation {
                  saveUserChanges(            
                    user_id:${parseInt(this.$store.state.user.user_id)}
                    cover_letter:"${this.coverLetter
                      .toString()
                      .trim()
                      .replace(/\n/g, "\\n")}"
                    first_name: "${this.firstName}"
                    middle_name: "${this.middleName}"
                    last_name: "${this.lastName}"

                    sex: "${this.sex}"
                    birth_date: "${this.age}"
                    address: "${this.address}"
                    
                    phone_number:"${this.phoneNumber}"
                    education:"${this.selectedEducationLevel}"
                    study_field:"${this.selectedStudyField}"             
                    link:"${this.link}"                 

                    ){
                        statusMessage
                    }
                    }
              `,
          },
        });
        if (
          result.data.data.saveUserChanges[0].statusMessage.toString() == "true"
        ) {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Submitted Successfully"
              : "በተሳካ ሁኔታ ተጠናቋል።"
          );
          await this.getAccountInfo();

          this.$store.dispatch("changeeditProfileDialog", false);
          this.$store.dispatch("changeBtn", "home");
        } else {
          this.showAlert(
            this.$store.state.language == "English"
              ? "Something went wrong, please try again"
              : "የሆነ ችግር ተፈጥሯል፣ እባክዎ እንደገና ይሞክሩ"
          );
        }
      } catch (err) {
        this.showAlert(
          this.$store.state.language == "English"
            ? err.message
            : "የኢንተርኔት ችግር አለ፣ እባክዎ እንደገና ይሞክሩ"
        );
      }
      this.saveChangesLoading = false;
    },
  },
  created() {
    if (this.$store.state.user != "") {
      this.firstName = this.$store.state.user.first_name;
      this.middleName = this.$store.state.user.middle_name;
      this.lastName = this.$store.state.user.last_name;
      this.sex = this.$store.state.user.sex;

      this.phoneNumber = this.$store.state.user.phone_number;
      this.age = this.$store.state.user.birth_date;
      this.address = this.$store.state.user.address;

      this.selectedEducationLevel = this.$store.state.user.education;
      this.selectedStudyField = this.$store.state.user.study_field;
      this.coverLetter = this.$store.state.user.cover_letter;

      this.link = this.$store.state.user.link;
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#userAvt {
  background-color: #e96910;
}

input {
  width: 100%;
  color: white;
}

input::placeholder {
  color: white;
  opacity: 1;
}

textarea {
  width: 100%;
  color: white;
}

textarea::placeholder {
  color: white;
  opacity: 1;
}

#light-border {
  /* background-image: linear-gradient(to bottom, rgb(2, 54, 121), #000000); */
  /* background-color: rgb(2, 54, 121); */
}
</style>
