import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/app";
import "firebase/storage";

import moment from "moment";
import VueHtmlToPaper from "vue-html-to-paper";

// Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.config.productionTip = false;

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBOSRlN0JXNEmzUEb3QqfGpN1dd1BBTOuE",
  authDomain: "newjobs-1f7f5.firebaseapp.com",
  projectId: "newjobs-1f7f5",
  storageBucket: "newjobs-1f7f5.appspot.com",
  messagingSenderId: "461183345087",
  appId: "1:461183345087:web:e0bc549b10aaa2cc9e410a",
  measurementId: "G-DGREQX8D62",
};

firebase.initializeApp(firebaseConfig);

// Make Firebase Storage available globally
const storage = firebase.storage();
Vue.prototype.$storage = storage;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
