<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <profileHeader v-if="$store.state.user != '' && !pageLoading" />
    <div
      v-if="$store.state.user != ''"
      class="mt-1 container"
      style="font-size: 15px"
    >
      <div class="text-center mt-15" v-if="pageLoading">
        <v-progress-circular
          :size="70"
          :width="2"
          color="#023679"
          indeterminate
        ></v-progress-circular>
      </div>

      <div id="homePage" v-if="!pageLoading" class="pr-1 pt-2">
        <div style="margin-top: 0px">
          <h4 class="text-center white--text pb-5">
            {{
              $store.state.language == "English"
                ? `You have applied ${allApplications.length} ${
                    allApplications.length == 1 ? " Job" : " Jobs"
                  }`
                : `${allApplications.length} ${
                    allApplications.length == 1 ? " ስራ" : " ስራዎችን"
                  } አመልክተዋል`
            }}
          </h4>
          <br />

          <v-layout
            row
            wrap
            class="white--text"
            v-for="(post, i) in allApplications"
            :key="i"
          >
            <v-flex xs7 class="pl-2 pr-1">
              <span
                ><strong>{{
                  post.getJobs[0].title.replace("@@cont@@", ",")
                }}</strong>
              </span>
              <br />

              <span class="truncate-text grey--text">{{
                post.getJobs[0].detail_description
              }}</span>
            </v-flex>

            <v-flex xs5 class="pr-1 text-right pt-2">
              <span v-if="post.isaccepted == 2" class="pink--text">
                {{
                  $store.state.language == "English" ? "Rejected" : "ውድቅ"
                }}</span
              >

              <span v-if="post.isaccepted == 0" class="orange--text">
                {{ $store.state.language == "English" ? "Pending" : "በመጠባበቅ" }}
              </span>

              <span v-if="post.isaccepted == 1" class="green--text">
                {{
                  $store.state.language == "English" ? "Accepted" : "ተመርጠዋል"
                }}</span
              >
              <v-btn
                x-small
                text
                class="ml-2"
                @click="
                  selectedDetail = post;
                  detailDialog = true;
                "
              >
                <v-icon class="grey--text">visibility</v-icon></v-btn
              >
            </v-flex>

            <v-flex xs12 class="mt-2 mb-2">
              <v-divider style="border-color: #272a2f"></v-divider>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog
          transition="dialog-bottom-transition"
          v-model="detailDialog"
          fullscreen
          persistent
          v-if="selectedDetail != ''"
        >
          <v-card style="background-color: #000000" class="white--text">
            <v-card-title>
              {{ $store.state.language == "English" ? "Detail" : "ዝርዝር" }}
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                text
                @click="
                  detailDialog = false;
                  selectedDetail = '';
                "
              >
                {{ $store.state.language == "English" ? "Close" : "ዝጋ" }}
              </v-btn>
            </v-card-title>

            <v-card-text class="pb-15">
              <v-form ref="form" lazy-validation>
                <v-layout row wrap class="pa-1 mt-1 white--text">
                  <v-flex
                    xs12
                    v-for="(job, i) in selectedDetail.getJobs[0].title.split(
                      '@@cont@@'
                    )"
                    :key="i"
                  >
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Company Name"
                            : "የድርጅት ስም"
                        }}:
                      </strong>
                      {{ selectedDetail.getJobs[0].company_name }}</span
                    >

                    <br v-if="i == 0" />
                    <span v-if="i == 0"
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Work Location"
                            : "የስራ ቦታ"
                        }}:
                      </strong>
                      {{ selectedDetail.getJobs[0].company_address }}</span
                    >
                    <br v-if="i == 0" />
                    <br v-if="i == 0" />
                    <span
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Title"
                            : "የስራው መደብ"
                        }}:
                      </strong>
                      {{ job }}</span
                    >
                    <br />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          '' &&
                        selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Applicants Needed"
                            : "ተፈላጊ ጾታ"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].sex.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          '' &&
                        selectedDetail.getJobs[0].sex.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Vacancies"
                            : "ብዛት"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].vacancies.split("@@cont@@")[i]
                      }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].vacancies.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                          '' &&
                        selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English" ? "Salary" : "ደሞዝ"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].salary.split("@@cont@@")[i]
                      }}</span
                    >

                    <br
                      v-if="
                        selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                          '' &&
                        selectedDetail.getJobs[0].salary.split('@@cont@@')[i] !=
                          'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].experience.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].experience.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Experience Level"
                            : "የስራ ልምድ"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].experience.split("@@cont@@")[
                          i
                        ]
                      }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.getJobs[0].experience.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].experience.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Education Qualification"
                            : "የትምህርት ደረጃ"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].education.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].education.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                    />
                    <span
                      v-if="
                        selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                      ><strong
                        >{{
                          $store.state.language == "English"
                            ? "Job Type"
                            : "የቅጥር አይነት"
                        }}:
                      </strong>
                      {{
                        selectedDetail.getJobs[0].job_type.split("@@cont@@")[i]
                      }}</span
                    >
                    <br
                      v-if="
                        selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != '' &&
                        selectedDetail.getJobs[0].job_type.split('@@cont@@')[
                          i
                        ] != 'undefined'
                      "
                    />

                    <div
                      class="mt-4"
                      v-if="
                        i ==
                        selectedDetail.getJobs[0].title.split('@@cont@@')
                          .length -
                          1
                      "
                    >
                      <span
                        v-if="
                          new Date(
                            selectedDetail.getJobs[0].deadline_date
                          ).getTime() +
                            24 * 60 * 60 * 1000 >
                          Date.now()
                        "
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        {{ getDeadline }}</span
                      >

                      <span v-else class="pink--text"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Deadline"
                              : "ግዜው የሚያበቃበት"
                          }}:
                        </strong>
                        ( {{ getDeadline }}){{
                          $store.state.language == "English"
                            ? ", Expired"
                            : "፣ ጊዜው አልፎበታል።"
                        }}</span
                      >

                      <v-img
                        v-if="selectedDetail.getJobs[0].short_image != ''"
                        class="ml-0 mr-0 mt-2 mb-3"
                        width="100%"
                        height="150"
                        :src="selectedDetail.getJobs[0].short_image"
                        contain
                      ></v-img>

                      <span style="white-space: pre-line"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Description"
                              : "ዝርዝር መግለጫ"
                          }}:
                        </strong>
                        {{ selectedDetail.getJobs[0].detail_description }}</span
                      >

                      <br />

                      <br />
                      <span style="white-space: pre-line"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Your Application Letter"
                              : "የእርስዎ ማመልከቻ"
                          }}: <br />
                        </strong>
                        {{ selectedDetail.cover_letter }}</span
                      >
                      <br />
                      <span
                        style="white-space: pre-line"
                        v-if="selectedDetail.sector != ''"
                        ><strong
                          >{{
                            $store.state.language == "English"
                              ? "Your selected sector is"
                              : "የመረጡት ዘርፍ"
                          }}:
                        </strong>
                        {{ selectedDetail.sector }}</span
                      >
                    </div>
                    <br />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import profileHeader from "./profileHeader.vue";

export default {
  components: {
    profileHeader,
  },
  data: () => ({
    pageLoading: false,
    allApplications: [],
    detailDialog: false,
    selectedDetail: [],
  }),

  computed: {
    getDeadline() {
      return this.formatDate(this.selectedDetail.getJobs[0].deadline_date);
    },
  },

  methods: {
    async getApplyData() {
      this.pageLoading = true;

      try {
        let resultapp = await axios({
          method: "POST",
          url: this.$store.state.userURL,

          data: {
            query: `{
        getAllApplications(user_id:${parseInt(this.$store.state.user.user_id)}){
                       application_id
                        post_id
                        user_id
                        cover_letter
                        sector
                        linkedin
                        applied_date
                        rank
                        isaccepted
                        getJobs{
                       post_id
               tg_id
               title
                short_description
                short_image
                detail_description
                detail_image
                company_address

               deadline_date
                application_format
                isdetail
                isdirect
               apply_link
                isregistered
                posted_date

                iscv
                islinkedin
                iszerf
               sex
               age_above_bellow
               isthesame
               isapproved
               isrejected
                language
                message_id
                  company_name
                 vacancies
                 salary
                 experience
                 education
                 job_type
                        }


           }
      }
      `,
          },
        });

        this.allApplications = resultapp.data.data.getAllApplications;
      } catch (err) {}
      this.pageLoading = false;
    },

    formatDate(dateString) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // Create a new Date object from the date string
      const date = new Date(dateString);

      // Ensure the date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }

      // Extract the month, day, and year
      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();

      // Format the day with a suffix
      let suffix = "th";
      if (day % 10 === 1 && day !== 11) {
        suffix = "st";
      } else if (day % 10 === 2 && day !== 12) {
        suffix = "nd";
      } else if (day % 10 === 3 && day !== 13) {
        suffix = "rd";
      }

      // Return the formatted date string
      return `${month} ${day}${suffix}, ${year}`;
    },
  },
  created() {
    this.getApplyData();
  },
};
</script>
<style scoped>
#homePage {
  max-width: 800px;
  margin: auto;
  line-height: 1.5;
}
.truncate-text {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

.studyText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 1; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}
.letterText {
  display: -webkit-box; /* Necessary for -webkit-line-clamp to work */
  -webkit-box-orient: vertical; /* Specifies the box orientation */
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  overflow: hidden; /* Hides the overflow text */
  text-overflow: ellipsis; /* Adds ellipsis (...) after the truncated text */
  max-height: 3em; /* Adjust based on the line height */
  line-height: 1.5em; /* Adjust the line height */
}

#userAvt {
  background-color: #e96910;
}
</style>
